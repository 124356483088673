import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";
import { XCircleIcon, XIcon } from "@heroicons/react/outline";

function Alert({ className, name, error }) {
  const [show, setShow] = React.useState(false);
  const message = React.useMemo(() => {
    return error[name];
  }, [name, error]);
  React.useEffect(() => {
    setShow(!!message);
  }, [message, setShow]);
  if (!show) return null;
  return (
    <div className={cn("rounded-md bg-red-50 p-4", className)}>
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">{message}</h3>
          {/* <div className="mt-2 text-sm text-red-700">
            <ul className="list-disc pl-5 space-y-1">
              <li>Your password must be at least 8 characters</li>
              <li>Your password must include at least one pro wrestling finishing move</li>
            </ul>
          </div> */}
        </div>
        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button
              type="button"
              className="inline-flex bg-red-50 rounded-md p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-red-600"
              onClick={() => setShow(false)}
            >
              <span className="sr-only">Dismiss</span>
              <XIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

Alert.defaultProps = {
  className: "",
};

Alert.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  error: PropTypes.instanceOf(Object).isRequired,
};

export default Alert;
