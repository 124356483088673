import { useEffect, useCallback } from "react";
import { req, useApiGet, useApiLoading } from "react-reqq-lite";
import * as actions from "./actions";
import * as c from "./constants";

export const useWithdrawList = (params) => {
  const isLoading = useApiLoading(c.WITHDRAW_LIST, "get");
  const data = useApiGet(c.WITHDRAW_LIST, []);
  const pager = useApiGet(c.WITHDRAW_PAGER, {});
  useEffect(() => {
    actions.listWithdraw(params);
  }, [params]);
  return [isLoading, data, pager];
};

export const useWithdrawSelected = () => {
  const isLoading = useApiLoading(c.WITHDRAW_ITEM, "get");
  const data = useApiGet(c.WITHDRAW_ITEM, {});
  return [isLoading, data];
};

export const useSelectWithdraw = (id) => {
  useEffect(() => {
    if (!id) {
      req.set(c.WITHDRAW_ITEM, null);
      return;
    }
    actions.selectWithdraw(id);
  }, [id]);
};

export const useCreateWithdraw = () => {
  const isLoading = useApiLoading(c.WITHDRAW_ITEM, "post");
  const submit = useCallback((payload, onSuccess) => {
    actions.createWithdraw(payload, onSuccess);
  }, []);
  return [isLoading, submit];
};

export const useChangeStatus = () => {
  const isLoading = useApiLoading(c.WITHDRAW_ITEM, "put");
  const submit = useCallback((id, payload, onSuccess) => {
    actions.changeStatus(id, payload, onSuccess);
  }, []);
  return [isLoading, submit];
};

export const useRemoveWithdraw = () => {
  const isLoading = useApiLoading(c.WITHDRAW_ITEM, "remove");
  const submit = useCallback((id, onSuccess) => {
    actions.removeWithdraw(id, onSuccess);
  }, []);
  return [isLoading, submit];
};

export const useUserList = (params) => {
  const isLoading = useApiLoading(c.USER_LIST, "get");
  const data = useApiGet(c.USER_LIST, []);
  useEffect(() => {
    actions.listUser(params);
  }, [params]);
  return [isLoading, data];
};
