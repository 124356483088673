import { req } from "react-reqq-lite";
import { toastSuccess } from "react-geek-toast";
import { transformList } from "./transformers";
import * as c from "./constants";

export const listGameManagers = (params) => {
  req.get({
    key: c.GAME_MANAGER_LIST,
    url: "/api/superAdmin/gameManager",
    params: params,
    transform: (res) => (res.data || []).map(transformList),
    onSuccess: (res) => {
      req.set(c.GAME_MANAGER_PAGER, res?.response?.meta?.pagination || {});
      req.set(c.GAME_MANAGER_FILTER, params);
    },
  });
};

export const selectGameManager = (id) => {
  req.get({
    key: c.GAME_MANAGER_ITEM,
    url: `/api/superAdmin/gameManager/${id}`,
    transform: (res) => transformList(res.data),
  });
};

export const createGameManager = (payload, onSuccess) => {
  req.post({
    key: c.GAME_MANAGER_ITEM,
    url: "/api/superAdmin/gameManager",
    payload,
    onSuccess: (res, state) => {
      toastSuccess("Created!");
      setTimeout(() => {
        listGameManagers(state[c.GAME_MANAGER_FILTER]);
      }, 1000);
      if (onSuccess) onSuccess(transformList(res?.response?.data));
    },
  });
};

export const updateGameManager = (id, payload, onSuccess) => {
  req.put({
    key: c.GAME_MANAGER_ITEM,
    url: `/api/superAdmin/gameManager/${id}`,
    payload,
    onSuccess: (res, state) => {
      toastSuccess("Updated!");
      setTimeout(() => {
        listGameManagers(state[c.GAME_MANAGER_FILTER]);
      }, 1000);
      selectGameManager(id);
      if (onSuccess) onSuccess(transformList(res?.response?.data));
    },
  });
};

export const updateGameManagerEmail = (id, payload, onSuccess) => {
  req.put({
    key: c.GAME_MANAGER_ITEM,
    url: `/api/superAdmin/gameManager/${id}`,
    payload,
    onSuccess: (res, state) => {
      toastSuccess("Updated!");
      setTimeout(() => {
        listGameManagers(state[c.GAME_MANAGER_FILTER]);
      }, 1000);
      selectGameManager(id);
      if (onSuccess) onSuccess(transformList(res?.response?.data));
    },
  });
};

export const removeGameManager = (id, onSuccess) => {
  req.remove({
    key: c.GAME_MANAGER_ITEM,
    url: `/api/superAdmin/gameManager/${id}`,
    onSuccess: (res, state) => {
      toastSuccess("Removed!");
      setTimeout(() => {
        listGameManagers(state[c.GAME_MANAGER_FILTER]);
      }, 1000);
      req.set(c.GAME_MANAGER_ITEM, null);
      if (onSuccess) onSuccess();
    },
  });
};

export const changeStatus = (id, payload, onSuccess) => {
  req.put({
    key: c.GAME_MANAGER_ITEM,
    url: `/api/superAdmin/gameManager/${id}/change_status`,
    payload,
    onSuccess: (res, state) => {
      toastSuccess("Updated!");
      setTimeout(() => {
        listGameManagers(state[c.GAME_MANAGER_FILTER]);
      }, 1000);
      selectGameManager(id);
      if (onSuccess) onSuccess(transformList(res?.response?.data));
    },
  });
};
