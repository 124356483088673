import React from "react";
// import PropTypes from 'prop-types';
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import AdminDetail from "./AdminDetail";

function AdminRoutes() {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}`} component={AdminDetail} />
      <Redirect to={`${match.url}`} />
    </Switch>
  );
}

AdminRoutes.propTypes = {};

export default AdminRoutes;
