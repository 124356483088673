import React from "react";
import PropTypes from "prop-types";
import List from "partial/components/List";
import { useMasterAgentList, useSelectMasterAgent } from "../hooks";
import MasterAgentListFilter from "./MasterAgentListFilter";
import Pagination from "partial/components/Pagination";

function MasterAgentList({ selected, onSelect }) {
  const [filter, setFilter] = React.useState({
    email: "",
    page: 1,
  });
  const [isLoading, list, pager] = useMasterAgentList(filter);

  React.useEffect(() => {
    if (window.innerWidth <= 1024) return;
    if (!selected && list[0]) onSelect(list[0]);
  }, [selected, list, onSelect]);
  useSelectMasterAgent(selected?.id);

  return (
    <>
      <div className="px-6 pt-6 pb-4">
        <h2 className="text-lg font-medium text-gray-900">Master Agents</h2>
        <p className="mt-1 text-sm text-gray-600">
          Total of {pager?.total || 0} user(s)
        </p>
        <MasterAgentListFilter onSubmit={setFilter} value={filter} />
      </div>
      <div className="relative flex-grow">
        <div className="absolute h-full w-full top-0 left-0 overflow-auto">
          <List
            label="Master Agent"
            data={list}
            selected={selected}
            onSelect={onSelect}
            isLoading={isLoading}
            // subTitleKey="email"
            // renderBadge={renderBadge}
          />
        </div>
      </div>
      <Pagination onChange={setFilter} value={pager} />
    </>
  );
}

MasterAgentList.defaultProps = {
  selected: null,
};

MasterAgentList.propTypes = {
  selected: PropTypes.instanceOf(Object),
  onSelect: PropTypes.func.isRequired,
};

export default MasterAgentList;
