import cn from "classnames";
import Cookie from "js-cookie";
import { Link } from "react-router-dom";
import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import ThumbIcon from "./ThumbIcon";

const userNavigation = [
  { name: "Profile", href: "/profile" },
  { name: "Logout", href: "/logout" },
];
function HeaderProfile() {
  const profile = JSON.parse(Cookie.get("_profile")) || {};

  const fullName =
    profile.first_name.toUpperCase() + " " + profile.last_name.toUpperCase();

  return (
    <Menu as="div" className="ml-3 relative">
      {({ open }) => (
        <>
          <div>
            <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
              <span className="sr-only">Open user menu</span>
              <ThumbIcon className="h-8 w-8 rounded-full" alt={fullName} />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
            show={open}
          >
            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-64 rounded-md shadow-primary-xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none pb-4 pt-2">
              <div className="flex px-3 py-2 space-x-2 border-b border-gray-100 text-gray-500">
                Signed in as
              </div>
              <div className="flex px-3 py-2 space-x-2 border-b border-gray-100 items-center">
                <ThumbIcon className="h-12 w-12 rounded-full" alt={fullName} />
                <div>
                  <div className="">{fullName}</div>
                </div>
              </div>
              {userNavigation.map((item) => (
                <Menu.Item key={item.name}>
                  {({ active }) => (
                    <Link
                      to={item.href}
                      className={cn(
                        active ? "bg-gray-100" : "",
                        "block px-4 py-2 text-sm text-gray-700"
                      )}
                    >
                      {item.name}
                    </Link>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
}

HeaderProfile.propTypes = {};

export default HeaderProfile;
