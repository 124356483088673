import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

function RoomContent({ data }) {
  const renderBadge = React.useCallback(
    (row) => (
      <>
        {row === 1 && <span className="badge badge-success">Active</span>}
        {row === 0 && <span className="badge badge-gray">Inactive</span>}
      </>
    ),
    []
  );

  return (
    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
      <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">Room Name</dt>
        <dd className="mt-1 text-base text-gray-900 font-semibold">
          {_.get(data, "attributes.name")}
        </dd>
      </div>

      <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">Handle Full Name</dt>
        <dd className="mt-1 text-base text-gray-900 font-semibold">
          {_.get(data, "attributes.handler_full_name")}
        </dd>
      </div>

      <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">Mininum Bet</dt>
        <dd className="mt-1 text-base text-gray-900 font-semibold">
          {_.get(data, "attributes.minimum_amount")}
        </dd>
      </div>

      <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">Maximum Bet</dt>
        <dd className="mt-1 text-base text-gray-900 font-semibold">
          {_.get(data, "attributes.maximum_amount")}
        </dd>
      </div>

      <div className="sm:col-span-3">
        <dt className="text-sm font-medium text-gray-500">Status</dt>
        <dd className="mt-1 text-sm text-gray-900">
          {renderBadge(_.get(data, "attributes.is_active"))}
        </dd>
      </div>
    </dl>
  );
}

RoomContent.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default RoomContent;
