import { useEffect, useCallback } from "react";
import { req, useApiGet, useApiLoading } from "react-reqq-lite";
import * as actions from "./actions";
import * as c from "./constants";

export const useSuperAdminList = (params) => {
  const isLoading = useApiLoading(c.SUPER_ADMIN_LIST, "get");
  const data = useApiGet(c.SUPER_ADMIN_LIST, []);
  const pager = useApiGet(c.SUPER_ADMIN_PAGER, {});
  useEffect(() => {
    actions.listSuperAdmins(params);
  }, [params]);
  return [isLoading, data, pager];
};

export const useSuperAdminSelected = () => {
  const isLoading = useApiLoading(c.SUPER_ADMIN_ITEM, "get");
  const data = useApiGet(c.SUPER_ADMIN_ITEM, {});
  return [isLoading, data];
};

export const useSelectSuperAdmin = (id) => {
  useEffect(() => {
    if (!id) {
      req.set(c.SUPER_ADMIN_ITEM, null);
      return;
    }
    actions.selectSuperAdmin(id);
  }, [id]);
};

export const useCreateSuperAdmin = () => {
  const isLoading = useApiLoading(c.SUPER_ADMIN_ITEM, "post");
  const submit = useCallback((payload, onSuccess) => {
    actions.createSuperAdmin(payload, onSuccess);
  }, []);
  return [isLoading, submit];
};

export const useUpdateSuperAdmin = () => {
  const isLoading = useApiLoading(c.SUPER_ADMIN_ITEM, "put");
  const submit = useCallback((id, payload, onSuccess) => {
    actions.updateSuperAdmin(id, payload, onSuccess);
  }, []);
  return [isLoading, submit];
};

export const useUpdateSuperAdminEmail = () => {
  const isLoading = useApiLoading(c.SUPER_ADMIN_ITEM, "put");
  const submit = useCallback((id, payload, onSuccess) => {
    actions.updateSuperAdminEmail(id, payload, onSuccess);
  }, []);
  return [isLoading, submit];
};

export const useChangeStatus = () => {
  const isLoading = useApiLoading(c.SUPER_ADMIN_ITEM, "put");
  const submit = useCallback((id, payload, onSuccess) => {
    actions.changeStatus(id, payload, onSuccess);
  }, []);
  return [isLoading, submit];
};

export const useRemoveSuperAdmin = () => {
  const isLoading = useApiLoading(c.SUPER_ADMIN_ITEM, "remove");
  const submit = useCallback((id, onSuccess) => {
    actions.removeSuperAdmin(id, onSuccess);
  }, []);
  return [isLoading, submit];
};
