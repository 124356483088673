import React, { useEffect, useState } from "react";
import Table from "partial/components/Table";
import Pagination from "partial/components/Pagination";
import { useGetWallet, useTransactionList } from "../hooks";
import { get } from "lodash";

function TransactionContainer() {
  const [filter, setFilter] = React.useState({
    page: 1,
  });
  const [isLoading, list, pager] = useTransactionList(filter);

  const [, walletData] = useGetWallet();

  const [arrayData, setFormArrayData] = useState(walletData.data || {});

  useEffect(() => {
    setFormArrayData(walletData.data);
  }, [walletData.data]);

  const tableFormat = React.useMemo(() => {
    return [
      {
        label: "Transaction",
        key: "type",
        id: "type",
        sortKey: "type",
      },
      {
        label: "Amount",
        key: "amount",
        id: "amount",
        sortKey: "amount",
      },
      {
        label: "Date",
        key: "date",
        id: "date",
        sortKey: "date",
      },
    ];
  }, []);
  return (
    <div className="flex-grow flex flex-col">
      <div className="px-4 pt-5">
        Wallet: {get(arrayData, "attributes.balance")}
      </div>
      <div className="flex flex-col flex-grow bg-white">
        <Table
          data={list}
          format={tableFormat}
          isLoading={isLoading}
          // onSort={setFilter}
          // sort={filter.sort}
        />
      </div>
      <Pagination onChange={setFilter} value={pager} />
    </div>
  );
}

export default TransactionContainer;
