import { req } from "react-reqq-lite";
import { toastSuccess } from "react-geek-toast";
import { transformList, transformRoom } from "./transformers";
import * as c from "./constants";

export const getCurrentRoom = () => {
  req.get({
    key: c.HANDLER_CURRENT_ROOM,
    url: `/api/handler/current_room`,
    transform: (res) => transformRoom(res.data),
  });
};

export const listHandler = (params, roomId) => {
  req.get({
    key: c.HANDLER_LIST,
    url: `/api/handler/session/${roomId}/list`,
    params: params,
    transform: (res) => (res.data || []).map(transformList),
    onSuccess: (res) => {
      req.set(c.HANDLER_PAGER, res?.response?.meta?.pagination || {});
      req.set(c.HANDLER_FILTER, params);
    },
  });
};

export const getLiveGame = (params, roomId) => {
  req.get({
    key: c.HANDLER_LIVE_GAME,
    url: `/api/handler/session/live`,
    params: params,
    // transform: (res) => (res.data || []).map(transformList),
    onSuccess: (res) => {
      // req.set(c.HANDLER_PAGER, res?.response?.meta?.pagination || {});
      // req.set(c.HANDLER_FILTER, params);
    },
  });
};

export const selectHandler = (roomId, id) => {
  req.get({
    key: c.HANDLER_ITEM,
    url: `/api/handler/session/${roomId}/sessions/${id}`,
    transform: (res) => transformList(res.data),
  });
};

export const createHandler = (payload, roomId, onSuccess) => {
  req.post({
    key: c.HANDLER_ITEM,
    url: `/api/handler/session/${roomId}/start`,
    payload,
    onSuccess: (res, state) => {
      setTimeout(() => {
        listHandler("", roomId);
      }, 1000);
      toastSuccess("Created!");
      if (onSuccess) onSuccess(transformList(res?.response?.data));
    },
  });
};

export const updateHandler = (id, roomId, payload, onSuccess) => {
  req.put({
    key: c.HANDLER_ITEM,
    url: `/api/handler/session/${roomId}/sessions/${id}`,
    payload,
    onSuccess: (res, state) => {
      setTimeout(() => {
        listHandler("", roomId);
      }, 1000);
      toastSuccess("Updated!");
      selectHandler(roomId, res?.response?.data.id);
      if (onSuccess) onSuccess(transformList(res?.response?.data));
    },
  });
};

export const changeState = (roomId, sessionId, onSuccess) => {
  req.put({
    key: c.HANDLER_CHANGE_STATE,
    url: `/api/handler/session/${roomId}/${sessionId}/change_state`,
    onSuccess: (res, state) => {
      setTimeout(() => {
        listHandler("", roomId);
      }, 1000);
      toastSuccess("Session changed!");
      if (onSuccess) onSuccess();
    },
  });
};

export const changeBet = (roomId, sessionId, payload, onSuccess) => {
  req.put({
    key: c.HANDLER_CHANGE_BET,
    url: `/api/handler/session/${roomId}/${sessionId}/betting_status`,
    payload,
    onSuccess: (res, state) => {
      setTimeout(() => {
        listHandler("", roomId);
      }, 1000);
      toastSuccess("Betting status changed!");
      if (onSuccess) onSuccess();
    },
  });
};

export const setWinningHorse = (roomId, sessionId, payload, onSuccess) => {
  req.put({
    key: c.SET_WINNING_HORSE,
    url: `/api/handler/session/${roomId}/${sessionId}/set_winning_card`,
    payload,
    onSuccess: (res, state) => {
      setTimeout(() => {
        listHandler("", roomId);
      }, 1000);
      if (onSuccess) onSuccess();
    },
  });
};

export const scratchNormal = (roomId, sessionId, payload, onSuccess) => {
  req.put({
    key: c.SCRATCH_NORMAL,
    url: `/api/handler/session/${roomId}/${sessionId}/scratch`,
    payload,
    onSuccess: (res, state) => {
      setTimeout(() => {
        listHandler("", roomId);
      }, 1000);
      if (onSuccess) onSuccess();
    },
  });
};

export const scratchExotic = (roomId, sessionId, payload, onSuccess) => {
  req.put({
    key: c.SCRATCH_EXOTIC,
    url: `/api/handler/session/${roomId}/${sessionId}/scratch_exo`,
    payload,
    onSuccess: (res, state) => {
      setTimeout(() => {
        listHandler("", roomId);
      }, 1000);
      if (onSuccess) onSuccess();
    },
  });
};
