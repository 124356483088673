import React, { useState } from "react";
import Alert from "partial/components/Alert";
import FormInput from "partial/form/FormInputWithIcon";
import Button from "partial/components/Button";
import { useApiLoading } from "react-reqq-lite";
import Form, { ErrorLabel } from "partial/form/Form";
import { HiOutlineMail } from "react-icons/hi";
import logo from "../../../assets/images/logo.png";
import bgIndex from "../../../assets/images/index-bg.jpg";
import * as actions from "../actions";
import * as c from "../constants";
import pkg from "../../../../package.json";

function Login() {
  const [form, setForm] = React.useState({
    email: "",
    password: "",
  });
  const [hidden, setHidden] = useState(true);
  const isLoading = useApiLoading(c.LOGIN, "post");
  const handleSubmit = () => {
    actions.login(form);
  };
  // const handleForgotPassword = (e) => {
  //   e.preventDefault();
  //   showDrawer({
  //     title: "Forgot Password",
  //     align: "justify-center",
  //     className: "modal-sm",
  //     content: (onClose) => (
  //       <ForgotPasswordModal email={form.email} onClose={onClose} />
  //     ),
  //   });
  // };
  // const handleOnClick = (e) => {
  //   e.preventDefault();
  //   history.push("/create-parent");
  // };
  const error = {
    // email: "Already taken",
    // password: 'Must be atleast 8 characters',
    // message: 'Invalid Credentials',
  };
  return (
    <Form onSubmit={handleSubmit} error={error}>
      <div className="flex h-full relative items-center justify-center">
        <div className="fixed inset-0 z-0">
          <img className="object-cover w-full h-full" src={bgIndex} alt="" />
        </div>
        <div className="px-3 w-full sm:max-w-4xl">
          <div className="w-full sm:max-w-lg rounded-3xl shadow-primary-2xl overflow-hidden relative z-10 mx-auto">
            <div className="bg-white w-full md:max-w-md lg:max-w-full md:mx-auto p-6 lg:p-16 xl:p-12 flex items-center justify-center shadow-primary-3xl">
              <div className="h-100 w-full">
                <div className="max-w-sm mx-auto">
                  <img
                    className="h-48 w-auto mx-auto"
                    src={logo}
                    alt="Karera Logo"
                  />
                  <h1 className="text-lg md:text-xl font-bold leading-tight mt-12 mb-4 text-center">
                    To continue, log in to Karera.
                  </h1>
                  {/* <form onSubmit={handleSubmit(handleLogin)} className="space-y-3"> */}
                  <div className="mb-3">
                    <FormInput
                      icon={HiOutlineMail}
                      name="email"
                      onChange={setForm}
                      value={form.email}
                      label="Email Address"
                      type="email"
                      error={error.email}
                      required
                    />
                    <ErrorLabel name="email" />
                  </div>
                  <div className="mb-3">
                    <FormInput
                      name="password"
                      onChange={setForm}
                      value={form.password}
                      label="Password"
                      type={hidden ? "password" : "text"}
                      error={error.password}
                      required
                      withIcon={true}
                      setHidden={setHidden}
                      hidden={hidden}
                    />
                    <ErrorLabel name="password" />
                  </div>
                  <Alert className="my-2" name="message" error={error} />
                  <Button
                    size="xl"
                    primary
                    type="submit"
                    className="w-full"
                    disabled={isLoading}
                  >
                    Login
                  </Button>
                  {/* <div className="mt-4 space-x-2 flex items-center justify-between">
                    <div>
                      <div className="flex items-center">
                        <input
                          id="remember-me"
                          name="remember-me"
                          type="checkbox"
                          className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
                        />
                        <label
                          htmlFor="remember-me"
                          className="ml-2 block text-sm text-gray-600"
                        >
                          Remember me
                        </label>
                      </div>
                    </div>
                    <div className="text-sm">
                      <div>
                        <a href="/" onClick={handleForgotPassword}>
                          Forgot Password
                        </a>
                      </div>
                    </div>
                  </div> */}
                  {/* </form> */}
                  {/* <hr className="my-6 border-gray-200 w-full" />
                  <div className="text-center">
                    <button onClick={handleOnClick} className="btn-link">
                      Create an account
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-xm font-light mt-10 text-center text-gray-900">
        © 2022 Karera Version {pkg.version}
      </div>
    </Form>
  );
}

Login.propTypes = {};

export default Login;
