import React from "react";
// import React from 'react';
// import PropTypes from 'prop-types';
import SideList from "partial/wrappers/SideList";
// import PageNotFound from "partial/containers/PageNotFound";
import NavTab, { NavTabItem } from "partial/components/NavTab";
import { useRouteMatch, Switch, Route, Redirect } from "react-router-dom";
import HandlerContent from "../components/HandlerContent";
import HandlerList from "../components/HandlerList";
import { useGetCurrentRoom, useHandlerSelected } from "../hooks";
import HandlerComponent from "../components/HandlerComponent";

function HandlerProfile() {
  const renderList = React.useCallback(
    (selected, onSelect) => (
      <HandlerList onSelect={onSelect} selected={selected} />
    ),
    []
  );
  const match = useRouteMatch();
  const [, data] = useHandlerSelected();

  return (
    <SideList renderList={renderList}>
      <div className="bg-white">
        <div className="border-b border-gray-200">
          <NavTab>
            <NavTabItem to={`${match.url}/details`} label="Summary" />
            <NavTabItem to={`${match.url}/session-details`} label="Session" />
          </NavTab>
        </div>
        {data.id ? (
          <div className="px-0 pt-4 pb-8 sm:px-6 lg:px-8">
            <Switch>
              <Route path={`${match.path}/details`}>
                <HandlerContent data={data} />
              </Route>
              <Route path={`${match.path}/session-details`}>
                <HandlerComponent data={data} />
              </Route>
              <Redirect to={`${match.url}/details`} />
            </Switch>
          </div>
        ) : null}
      </div>
    </SideList>
  );
}

HandlerProfile.propTypes = {};

export default HandlerProfile;
