import { useApiGet, useApiLoading } from "react-reqq-lite";
import { useCallback, useEffect } from "react";
import { LIST, PAGER, CONVERT_TO_WALLET } from "./constants";
import { listCommission, convertToWallet } from "./actions";

export const useCommissionList = (params) => {
  const isLoading = useApiLoading(LIST, "get");
  const meta = useApiGet(PAGER, {});
  const list = useApiGet(LIST, []);
  useEffect(() => {
    listCommission(params);
  }, [params]);
  return [isLoading, list, meta];
};

export const useConvertToWallet = () => {
  const isLoading = useApiLoading(CONVERT_TO_WALLET, "post");
  const submit = useCallback((payload, onSuccess) => {
    convertToWallet(payload, onSuccess);
  }, []);
  return [isLoading, submit];
};
