import { useApiGet, useApiLoading } from "react-reqq-lite";
import { useEffect } from "react";
import { LIST, PAGER } from "./constants";
import {
  listCommission,
} from "./actions";

export const useCommissionList = (params) => {
  const isLoading = useApiLoading(LIST, "get");
  const meta = useApiGet(PAGER, {});
  const list = useApiGet(LIST, []);
  useEffect(() => {
    listCommission(params);
  }, [params]);
  return [isLoading, list, meta];
};