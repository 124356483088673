import React from "react";
import PropTypes from "prop-types";
import { useChangeStatus } from "../hooks";
import { FiEdit3 } from "react-icons/fi";
import { showAlert } from "partial/components/Modal";

function SuperMasterAgentContent({ data }) {
  const renderBadge = React.useCallback(
    (row) => (
      <>
        {row === true && <span className="badge badge-success">Active</span>}
        {row === false && <span className="badge badge-gray">Inactive</span>}
      </>
    ),
    []
  );

  const [, changeStatus] = useChangeStatus();

  const handleChangeStatus = (e) => {
    e.preventDefault();
    showAlert({
      title: "Update Status",
      yesLabel: "Update Status",
      onYes: (closeAlert) => {
        closeAlert();
        changeStatus(data.id);
      },
    });
  };

  return (
    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
      <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">First Name</dt>
        <dd className="mt-1 text-base text-gray-900 font-semibold">
          {data.first_name}
        </dd>
      </div>

      <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">Last Name</dt>
        <dd className="mt-1 text-base text-gray-900 font-semibold">
          {data.last_name}
        </dd>
      </div>

      <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">Contact Number</dt>
        <dd className="mt-1 text-base text-gray-900 font-semibold">
          {data.phone_number}
        </dd>
      </div>

      <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">Email Address</dt>
        <dd className="mt-1 text-base text-gray-900 font-semibold">
          {data.email}
        </dd>
      </div>

      <div className="sm:col-span-3">
        <dt className="text-sm font-medium text-gray-500">Status</dt>
        <dd className="mt-1 text-sm text-gray-900">
          <a
            className="group space-x-2 inline-flex"
            href="/"
            onClick={handleChangeStatus}
          >
            {renderBadge(data.status)}
            <div className="flex items-center space-x">
              <FiEdit3 className="h-4 w-4 text-gray-500 inline-block" />
              <span className="opacity-0 text-gray-500 no-underline group-hover:opacity-100 font-semibold">
                Update Status
              </span>
            </div>
          </a>
        </dd>
      </div>
    </dl>
  );
}

SuperMasterAgentContent.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default SuperMasterAgentContent;
