import React, { useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import HeaderProfile from "partial/components/HeaderProfile";
import { HiOutlineMenuAlt2 } from "react-icons/hi";

import ModuleWrapper, {
  ModuleLabel,
  ModuleRoute,
} from "partial/wrappers/ModuleWrapper";

import Logout from "modules/auth/containers/Logout";
import PageNotFound from "partial/containers/PageNotFound";
import Profile from "partial/containers/Profile";
import SuperMasterAgentSidebar from "modules/super-master-agent/partial/SuperMasterAgentSidebar";
import TransactionContainer from "modules/super-master-agent/transactions/containers/TransactionContainer";
import WithdrawRoutes from "modules/super-master-agent/withdraw/containers/WithdrawRoutes";
import WithdrawRequestRoutes from "modules/super-master-agent/withdraw-requests/containers/WithdrawRequestRoutes";
import CommissionContainer from "modules/super-master-agent/commissions/containers/CommissionContainer";
import SuperMasterAgentMasterAgentRoutes from "modules/super-master-agent/master-agents/containers/SuperMasterAgentMasterAgentRoutes";

function SuperMasterAgentRoutes() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  return (
    <ModuleWrapper>
      <div className="h-screen flex overflow-hidden bg-gray-100">
        <SuperMasterAgentSidebar
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
        <div className="flex flex-col w-0 flex-1 overflow-hidden">
          <div className="relative z-10 flex-shrink-0 flex h-16 bg-white shadow">
            <button
              className="px-4 border-r sm:border-gray-200 border-gray-700 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500 md:hidden"
              onClick={() => setSidebarOpen(true)}
              type="button"
            >
              <span className="sr-only">Open sidebar</span>
              <HiOutlineMenuAlt2 className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="flex-1 px-4 flex justify-between">
              <div className="flex-1 flex items-center relative">
                <div className="absolute w-full line-clamp-1">
                  <h1 className="text-2xl font-semibold text-gray-900 truncate">
                    <ModuleLabel />
                  </h1>
                </div>
              </div>
              <div className="ml-4 flex items-center md:ml-6">
                {/* Profile dropdown */}
                <HeaderProfile />
              </div>
            </div>
          </div>
          <main className="flex-1 relative overflow-y-auto focus:outline-none flex flex-col">
            <Switch>
              <ModuleRoute
                title="Master Agents"
                path="/super-master-agent/master-agents"
                component={SuperMasterAgentMasterAgentRoutes}
              />
              <ModuleRoute
                title="Transactions"
                path="/super-master-agent/transactions"
                component={TransactionContainer}
              />
              <ModuleRoute
                title="Withdraw"
                path="/super-master-agent/withdraw"
                component={WithdrawRoutes}
              />
              <ModuleRoute
                title="Withdraw Requests"
                path="/super-master-agent/withdraw-requests"
                component={WithdrawRequestRoutes}
              />
              <ModuleRoute
                title="View Commissions"
                path="/super-master-agent/commissions"
                component={CommissionContainer}
              />
              <ModuleRoute
                title="Profile"
                path="/profile"
                component={Profile}
              />
              <Route path="/logout" component={Logout} />
              <Route exact path="/">
                <Redirect to="/super-master-agent/master-agents" />
              </Route>
              <Route component={PageNotFound} />
            </Switch>
          </main>
        </div>
      </div>
    </ModuleWrapper>
  );
}

export default SuperMasterAgentRoutes;
