import React from "react";
import PropTypes from "prop-types";
import Form from "partial/form/Form";
import FormInput from "partial/form/FormInput";
import Button from "partial/components/Button";
import { showDrawer } from "partial/components/Modal";
import { transformList } from "../transformers";
import { useConvertToWallet } from "../hooks";

export const openCommissionModal = (title, data, onSuccess) => {
  showDrawer({
    size: "xs",
    title,
    content: (onClose) => (
      <CommissionModal data={data} onClose={onClose} onSuccess={onSuccess} />
    ),
  });
};

function CommissionModal({ data, onClose }) {
  const [form, setForm] = React.useState({
    amount: 0,
  });
  const [isConvertToWallet, convertToWallet] = useConvertToWallet();
  const handleSubmit = () => {
    convertToWallet(form, (newData) => {
      onClose();
    });
  };

  return (
    <Form className="divide-y divide-gray-200" onSubmit={handleSubmit}>
      <div>
        <div className="mb-3">
          <FormInput
            name="amount"
            onChange={setForm}
            value={form.amount}
            label="Amount"
            required
            type="number"
          />
        </div>
      </div>
      <div className="pt-5">
        <div className="flex justify-end">
          <Button type="submit" primary size="lg" disabled={isConvertToWallet}>
            Save
          </Button>
        </div>
      </div>
    </Form>
  );
}

CommissionModal.defaultProps = {
  data: transformList(),
  onSuccess: false,
};

CommissionModal.propTypes = {
  data: PropTypes.instanceOf(Object),
  onSuccess: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onClose: PropTypes.func.isRequired,
};

export default CommissionModal;
