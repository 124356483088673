import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  HiUser,
  HiOutlineTrash,
  HiOutlinePencil,
  HiOutlineChevronDown,
} from "react-icons/hi";
import cn from "classnames";
// import DropdownState, {
//   DropdownStateItem,
// } from 'partial/components/DropdownState';
import Button from "partial/components/Button";
import ThumbIcon from "partial/components/ThumbIcon";
import { Menu, Transition } from "@headlessui/react";
import { showAlert } from "partial/components/Modal";
import { useRemoveMasterAgent } from "../hooks";
import { openMasterAgentModal } from "../modals/MasterAgentModal";
import _ from "lodash";

function MasterAgentHeader({ isLoading, data }) {
  const handleOpenEdit = () => {
    openMasterAgentModal("Edit Master Agent", data);
  };
  const [isRemoving, remove] = useRemoveMasterAgent();
  const handleOpenDelete = () => {
    showAlert({
      title: "Delete",
      yesLabel: "Delete",
      onYes: (closeAlert) => {
        closeAlert();
        remove(data.id);
      },
    });
  };
  return (
    <div className="relative z-10 mb-4">
      <div>
        <div
          className={cn(
            "bg-gradient-to-br from-gray-200 to-gray-400 h-28 w-full object-contain object-center bg-center bg-cover lg:h-32"
          )}
          style={{
            backgroundImage: data?.banner ? `url(${data?.banner})` : null,
          }}
        />
      </div>
      <div className="max-w-4xl mx-auto px-4 sm:px-6 md:flex md:items-start md:justify-between md:space-x-5 lg:max-w-full lg:px-8 -mt-24 sm:-mt-20">
        <div className="flex items-center sm:items-start space-x-5">
          <div className="flex-shrink-0">
            <div className="relative">
              <ThumbIcon
                className="h-20 w-20 sm:h-28 sm:w-28 rounded-2xl p-1"
                url={data?.thumbnail_url}
                alt={data?.name}
                icon={data?.name ? null : HiUser}
                iconClassName="h-12 w-12"
              />
              <span
                className="absolute inset-0  rounded-full"
                aria-hidden="true"
              />
            </div>
          </div>
          <div className="mt-0 sm:mt-1">
            <h1 className="text-xl sm:text-2xl font-bold text-gray-900 line-clamp-1">
              {data?.name}
            </h1>
            <p className="text-xs sm:text-sm font-medium text-gray-500">
              {data?.mobile_number}
            </p>
          </div>
        </div>
        <div className="mt-8 sm:mt-2 sm:mb-4 sm:mx-8 mx-1">
          <span className="relative z-0 inline-flex shadow-sm rounded-md sm:shadow-none sm:space-x-3">
            <span className="inline-flex sm:shadow-sm">
              {!_.isEmpty(data) && (
                <>
                  <Button
                    disabled={isLoading}
                    size="default"
                    default
                    type=""
                    icon={HiOutlinePencil}
                    className="w-full rounded-r-none"
                    onClick={handleOpenEdit}
                  >
                    <span>Edit</span>
                  </Button>
                  <Button
                    disabled={isLoading || isRemoving}
                    size="default"
                    default
                    type=""
                    icon={HiOutlineTrash}
                    className="w-full rounded-l-none -ml-px"
                    onClick={handleOpenDelete}
                  >
                    <span>Delete</span>
                  </Button>
                </>
              )}
            </span>

            <Menu
              as="span"
              className="-ml-px relative block sm:shadow-sm lg:hidden"
            >
              <div>
                <Menu.Button className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-base font-medium text-gray-900 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-600 focus:border-blue-600 sm:rounded-md sm:px-3">
                  <span className="sr-only sm:hidden">More</span>
                  <span className="hidden sm:inline">More</span>
                  <HiOutlineChevronDown
                    className="h-5 w-5 text-gray-400 sm:ml-2 sm:-mr-1"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>

              <Transition
                show={false}
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="/"
                          className={cn(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block sm:hidden px-4 py-2 text-sm"
                          )}
                        >
                          Note
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="/"
                          className={cn(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm"
                          )}
                        >
                          Archive
                        </a>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </span>
        </div>
      </div>
    </div>
  );
}

MasterAgentHeader.defaultProps = {
  isLoading: false,
};

MasterAgentHeader.propTypes = {
  isLoading: PropTypes.bool,
  data: PropTypes.instanceOf(Object).isRequired,
};

export default MasterAgentHeader;
