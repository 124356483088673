import { useEffect, useCallback } from "react";
import { req, useApiGet, useApiLoading } from "react-reqq-lite";
import * as actions from "./actions";
import * as c from "./constants";

export const useHandlerList = (params) => {
  const isLoading = useApiLoading(c.HANDLER_LIST, "get");
  const data = useApiGet(c.HANDLER_LIST, []);
  const pager = useApiGet(c.HANDLER_PAGER, {});
  useEffect(() => {
    actions.listHandlers(params);
  }, [params]);
  return [isLoading, data, pager];
};

export const useHandlerSelected = () => {
  const isLoading = useApiLoading(c.HANDLER_ITEM, "get");
  const data = useApiGet(c.HANDLER_ITEM, {});
  return [isLoading, data];
};

export const useSelectHandler = (id) => {
  useEffect(() => {
    if (!id) {
      req.set(c.HANDLER_ITEM, null);
      return;
    }
    actions.selectHandler(id);
  }, [id]);
};

export const useCreateHandler = () => {
  const isLoading = useApiLoading(c.HANDLER_ITEM, "post");
  const submit = useCallback((payload, onSuccess) => {
    actions.createHandler(payload, onSuccess);
  }, []);
  return [isLoading, submit];
};

export const useUpdateHandler = () => {
  const isLoading = useApiLoading(c.HANDLER_ITEM, "put");
  const submit = useCallback((id, payload, onSuccess) => {
    actions.updateHandler(id, payload, onSuccess);
  }, []);
  return [isLoading, submit];
};

export const useUpdateHandlerEmail = () => {
  const isLoading = useApiLoading(c.HANDLER_ITEM, "put");
  const submit = useCallback((id, payload, onSuccess) => {
    actions.updateHandlerEmail(id, payload, onSuccess);
  }, []);
  return [isLoading, submit];
};

export const useChangeStatus = () => {
  const isLoading = useApiLoading(c.HANDLER_ITEM, "put");
  const submit = useCallback((id, payload, onSuccess) => {
    actions.changeStatus(id, payload, onSuccess);
  }, []);
  return [isLoading, submit];
};

export const useRemoveHandler = () => {
  const isLoading = useApiLoading(c.HANDLER_ITEM, "remove");
  const submit = useCallback((id, onSuccess) => {
    actions.removeHandler(id, onSuccess);
  }, []);
  return [isLoading, submit];
};
