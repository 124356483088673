import { req } from "react-reqq-lite";
import { toastSuccess } from "react-geek-toast";
import { transformList } from "./transformers";
import * as c from "./constants";

export const listSuperAdmins = (params) => {
  req.get({
    key: c.SUPER_ADMIN_LIST,
    url: "/api/superAdmin/users",
    params: params,
    transform: (res) => (res.data || []).map(transformList),
    onSuccess: (res) => {
      req.set(c.SUPER_ADMIN_PAGER, res?.response?.meta?.pagination || {});
      req.set(c.SUPER_ADMIN_FILTER, params);
    },
  });
};

export const selectSuperAdmin = (id) => {
  req.get({
    key: c.SUPER_ADMIN_ITEM,
    url: `/api/superAdmin/users/${id}`,
    transform: (res) => transformList(res.data),
  });
};

export const createSuperAdmin = (payload, onSuccess) => {
  req.post({
    key: c.SUPER_ADMIN_ITEM,
    url: "/api/superAdmin/users",
    payload,
    onSuccess: (res, state) => {
      toastSuccess("Created!");
      setTimeout(() => {
        listSuperAdmins(state[c.SUPER_ADMIN_FILTER]);
      }, 1000);
      if (onSuccess) onSuccess(transformList(res?.response?.data));
    },
  });
};

export const updateSuperAdmin = (id, payload, onSuccess) => {
  req.put({
    key: c.SUPER_ADMIN_ITEM,
    url: `/api/superAdmin/users/${id}`,
    payload,
    onSuccess: (res, state) => {
      toastSuccess("Updated!");
      setTimeout(() => {
        listSuperAdmins(state[c.SUPER_ADMIN_FILTER]);
      }, 1000);
      selectSuperAdmin(id);
      if (onSuccess) onSuccess(transformList(res?.response?.data));
    },
  });
};

export const updateSuperAdminEmail = (id, payload, onSuccess) => {
  req.put({
    key: c.SUPER_ADMIN_ITEM,
    url: `/api/superAdmin/users/${id}`,
    payload,
    onSuccess: (res, state) => {
      toastSuccess("Updated!");
      setTimeout(() => {
        listSuperAdmins(state[c.SUPER_ADMIN_FILTER]);
      }, 1000);
      selectSuperAdmin(id);
      if (onSuccess) onSuccess(transformList(res?.response?.data));
    },
  });
};

export const removeSuperAdmin = (id, onSuccess) => {
  req.remove({
    key: c.SUPER_ADMIN_ITEM,
    url: `/api/superAdmin/users/${id}`,
    onSuccess: (res, state) => {
      toastSuccess("Removed!");
      setTimeout(() => {
        listSuperAdmins(state[c.SUPER_ADMIN_FILTER]);
      }, 1000);
      req.set(c.SUPER_ADMIN_ITEM, null);
      if (onSuccess) onSuccess();
    },
  });
};

export const changeStatus = (id, payload, onSuccess) => {
  req.put({
    key: c.SUPER_ADMIN_ITEM,
    url: `/api/superAdmin/users/${id}/change_status`,
    payload,
    onSuccess: (res, state) => {
      toastSuccess("Updated!");
      setTimeout(() => {
        listSuperAdmins(state[c.SUPER_ADMIN_FILTER]);
      }, 1000);
      selectSuperAdmin(id);
      if (onSuccess) onSuccess(transformList(res?.response?.data));
    },
  });
};
