import React from "react";
import PropTypes from "prop-types";
import { showDrawer } from "partial/components/Modal";
import { transformList } from "../transformers";
import { formatDate } from "helper";

export const openViewDetailsModal = (title, data, onSuccess) => {
  showDrawer({
    size: "xs",
    title,
    content: (onClose) => (
      <ViewDetailsModal data={data} onClose={onClose} onSuccess={onSuccess} />
    ),
  });
};

function ViewDetailsModal({ data, onClose, onSuccess }) {
  console.log(data);
  return (
    <>
      <div className="col-12">
        <dt className="text-sm font-medium text-gray-500">Transaction Type</dt>
        <dd className="mt-1 text-base text-gray-900 font-semibold">
          {data.type}
        </dd>
      </div>

      <div className="col-12">
        <dt className="text-sm font-medium text-gray-500">Bet Type</dt>
        <dd className="mt-1 text-base text-gray-900 font-semibold">
          {data.bet_type}
        </dd>
      </div>

      <div className="col-12">
        <dt className="text-sm font-medium text-gray-500">Bet</dt>
        <dd className="mt-1 text-base text-gray-900 font-semibold">
          {data.bet}
        </dd>
      </div>

      <div className="col-12">
        <dt className="text-sm font-medium text-gray-500">Winning Horse</dt>
        <dd className="mt-1 text-base text-gray-900 font-semibold">
          {data.winning_horse}
        </dd>
      </div>

      <div className="col-12">
        <dt className="text-sm font-medium text-gray-500">Winning Amount</dt>
        <dd className="mt-1 text-base text-gray-900 font-semibold">
          {data.amount}
        </dd>
      </div>

      <div className="col-12">
        <dt className="text-sm font-medium text-gray-500">Date</dt>
        <dd className="mt-1 text-base text-gray-900 font-semibold">
          {formatDate(data.date, "MMM DD, YYYY hh:mm A")}
        </dd>
      </div>
    </>
  );
}

ViewDetailsModal.defaultProps = {
  data: transformList(),
  onSuccess: false,
};

ViewDetailsModal.propTypes = {
  data: PropTypes.instanceOf(Object),
  onSuccess: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onClose: PropTypes.func.isRequired,
};

export default ViewDetailsModal;
