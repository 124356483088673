import { req } from "react-reqq-lite";
import { toastSuccess } from "react-geek-toast";
import { transformList } from "./transformers";
import * as c from "./constants";

export const listWithdrawRequests = (params) => {
  req.get({
    key: c.WITHDRAW_REQUEST_LIST,
    url: "/api/superMasterAgent/cashouts",
    params: params,
    transform: (res) => (res.data || []).map(transformList),
    onSuccess: (res) => {
      req.set(c.WITHDRAW_REQUEST_PAGER, res?.response?.meta?.pagination || {});
      req.set(c.WITHDRAW_REQUEST_FILTER, params);
    },
  });
};

export const selectWithdrawRequest = (id) => {
  req.get({
    key: c.WITHDRAW_REQUEST_ITEM,
    url: `/api/superMasterAgent/cashouts/${id}`,
    transform: (res) => transformList(res.data),
  });
};

export const createWithdrawRequest = (payload, onSuccess) => {
  req.post({
    key: c.WITHDRAW_REQUEST_ITEM,
    url: "/api/superMasterAgent/cashouts",
    payload,
    onSuccess: (res, state) => {
      toastSuccess("Created!");
      setTimeout(() => {
        listWithdrawRequests(state[c.WITHDRAW_REQUEST_FILTER]);
      }, 1000);
      if (onSuccess) onSuccess(transformList(res?.response?.data));
    },
  });
};

export const updateWithdrawRequest = (id, payload, onSuccess) => {
  req.put({
    key: c.WITHDRAW_REQUEST_ITEM,
    url: `/api/superMasterAgent/cashouts/${id}`,
    payload,
    onSuccess: (res, state) => {
      toastSuccess("Updated!");
      setTimeout(() => {
        listWithdrawRequests(state[c.WITHDRAW_REQUEST_FILTER]);
      }, 1000);
      selectWithdrawRequest(id);
      if (onSuccess) onSuccess(transformList(res?.response?.data));
    },
  });
};

export const updateWithdrawRequestEmail = (id, payload, onSuccess) => {
  req.put({
    key: c.WITHDRAW_REQUEST_ITEM,
    url: `/api/superMasterAgent/cashouts/${id}`,
    payload,
    onSuccess: (res, state) => {
      toastSuccess("Updated!");
      setTimeout(() => {
        listWithdrawRequests(state[c.WITHDRAW_REQUEST_FILTER]);
      }, 1000);
      selectWithdrawRequest(id);
      if (onSuccess) onSuccess(transformList(res?.response?.data));
    },
  });
};

export const removeWithdrawRequest = (id, onSuccess) => {
  req.remove({
    key: c.WITHDRAW_REQUEST_ITEM,
    url: `/api/superMasterAgent/cashouts/${id}`,
    onSuccess: (res, state) => {
      toastSuccess("Removed!");
      setTimeout(() => {
        listWithdrawRequests(state[c.WITHDRAW_REQUEST_FILTER]);
      }, 1000);
      req.set(c.WITHDRAW_REQUEST_ITEM, null);
      if (onSuccess) onSuccess();
    },
  });
};

export const approveCashout = (id, payload, onSuccess) => {
  req.put({
    key: c.WITHDRAW_REQUEST_ITEM,
    url: `/api/superMasterAgent/cashouts/${id}/approve`,
    payload,
    onSuccess: (res, state) => {
      toastSuccess("Updated!");
      setTimeout(() => {
        listWithdrawRequests(state[c.WITHDRAW_REQUEST_FILTER]);
      }, 1000);
      selectWithdrawRequest(id);
      if (onSuccess) onSuccess(transformList(res?.response?.data));
    },
  });
};
