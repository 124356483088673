import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import AgentProfile from "./AgentProfile";

function MaAgentRoutes() {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}`} component={AgentProfile} />
      <Redirect to={`${match.url}`} />
    </Switch>
  );
}

MaAgentRoutes.propTypes = {};

export default MaAgentRoutes;
