import React from "react";
// import PropTypes from 'prop-types';
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import WithdrawRequestDetail from "./WithdrawRequestDetail";

function WithdrawRequestRoutes() {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}`} component={WithdrawRequestDetail} />
      <Redirect to={`${match.url}`} />
    </Switch>
  );
}

WithdrawRequestRoutes.propTypes = {};

export default WithdrawRequestRoutes;
