import React from "react";
import PropTypes from "prop-types";
import Form from "partial/form/Form";
import FormInput from "partial/form/FormInput";
import Button from "partial/components/Button";
import { useMasterAgentAddCredit, useMasterAgentAddDebit } from "../hooks";

function MasterAgentWalletModal({ onClose, type, playerId }) {
  const [form, setForm] = React.useState({
    amount: 0,
  });
  const [isAddCredit, addCredit] = useMasterAgentAddCredit();
  const [isAddDebit, addDebit] = useMasterAgentAddDebit();
  const handleSubmit = () => {
    if (type === "CREDIT") {
      addCredit(playerId, form, (newData) => {
        onClose();
      });
      return;
    }
    if (type === "DEBIT") {
      addDebit(playerId, form, (newData) => {
        onClose();
      });
      return;
    }
  };

  return (
    <Form className="divide-y divide-gray-200" onSubmit={handleSubmit}>
      <div>
        <div className="mb-3">
          <FormInput
            name="amount"
            onChange={setForm}
            value={form.amount}
            label="Amount"
            required
            type="number"
          />
        </div>
      </div>
      <div className="pt-5">
        <div className="flex justify-end">
          <Button
            type="submit"
            primary
            size="lg"
            disabled={isAddCredit || isAddDebit}
          >
            Save
          </Button>
        </div>
      </div>
    </Form>
  );
}

MasterAgentWalletModal.defaultProps = {
  playerId: "",
  onSuccess: false,
  type: "",
};

MasterAgentWalletModal.propTypes = {
  playerId: PropTypes.string,
  type: PropTypes.string,
  onSuccess: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onClose: PropTypes.func.isRequired,
};

export default MasterAgentWalletModal;
