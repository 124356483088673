import React, { useState } from "react";
import { usePlayerRoomList } from "../hooks";
import { toastError } from "react-geek-toast";
import _ from "lodash";
import { HiArrowRight } from "react-icons/hi";
// import PlayerRoomDetail from "./PlayerRoomDetail";
import { useHistory } from "react-router-dom";

function PlayerRoomList() {
  const [, data] = usePlayerRoomList();
  const [playerWallet, setPlayerWallet] = useState({});
  // const [selectedRoom, setSelectedRoom] = useState({});
  // const [showGame, setShowGame] = useState(false);

  const history = useHistory();

  React.useEffect(() => {
    setPlayerWallet(_.get(playerWallet, "data.attributes.balance"));
  }, [playerWallet]);

  const handleOnSelectRoom = (item) => (e) => {
    e.preventDefault();
    if (playerWallet < _.get(data, "maximum_amount")) {
      toastError("You don't have enough balance.");
    } else {
      // setSelectedRoom(item);
      // setShowGame(true);
      history.push(`/player/room/game-room/${item.id}`);
    }
  };

  // const backToRoom = () => {
  //   // e.preventDefault();
  //   setSelectedRoom({});
  //   setShowGame(false);
  // };

  // React.useEffect(() => {
  //   if (_.isEmpty(selectedRoom)) {
  //     setSelectedRoom({});
  //     setShowGame(false);
  //   }
  // }, []);

  return (
    <>
      {/* {showGame === false && ( */}
      <div className="max-w-6xl w-full mx-auto my-5">
        {data.map((row) => (
          <div
            key={row.id}
            className="border border-gray-600 bg-gray-800 hover:bg-gray-700 text-white rounded-md m-2 transition"
          >
            <div>
              <button
                onClick={handleOnSelectRoom(row)}
                type="button"
                className="p-3 w-full group"
              >
                <div className="grid sm:grid-cols-3 grid-cols-1 gap-3 sm:divide-x divide-x-0 divide-gray-600">
                  <div className="font-bold text-2xl text-secondary-500 text-center sm:text-left">
                    {row.name}
                  </div>
                  <div className="grid grid-cols-2 gap-3 divide-x divide-gray-600">
                    <div className="flex items-center justify-center text-base space-x-2">
                      <div>Max Bet:</div>
                      <div className="flex items-center space-x-1">
                        <span className="text-secondary-500 font-semibold">
                          {row.maximum_amount}
                        </span>
                        <span className="text-gray-500 text-sm">PHP</span>
                      </div>
                    </div>
                    <div className="flex items-center justify-center text-base space-x-2">
                      <span>Min Bet:</span>
                      <span className="flex items-center space-x-1">
                        <span className="text-secondary-500 font-semibold">
                          {row.minimum_amount}
                        </span>
                        <span className="text-gray-500 text-sm">PHP</span>
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center justify-end">
                    <HiArrowRight className="w-4 h-4 flex-shrink-0 group-hover:text-secondary-500 transition -translate-x-3 group-hover:translate-x-0" />
                  </div>
                </div>
              </button>
            </div>
          </div>
        ))}
      </div>
      {/* )} */}
      {/* {showGame === true && (
        <> */}
      {/* <div className="flex flex-col space-x-0 space-y-2 sm:space-y-0 sm:flex-row items-center sm:px-4 px-2 sm:pt-4 pt-2 sm:pb-0 pb-2 sm:space-x-5 justify-between bg-gray-900 border-b sm:border-none border-gray-600">
            <div className="border-b sm:border-none pt-2 sm:pt-0 border-gray-700 sm:w-auto w-full pb-2 sm:pb-0">
              <Button
                primary
                className="mr-auto sm:mr-0"
                onClick={backToRoom()}
                icon={HiChevronLeft}
                type="button"
                size="sm"
              >
                Back to Rooms
              </Button>
            </div>
          </div> */}
      {/* <PlayerRoomDetail
            sessionInfo={selectedRoom}
            backToRoom={backToRoom}
          />
        </>
      )} */}
    </>
  );
}

PlayerRoomList.propTypes = {};

export default PlayerRoomList;
