import React, { useEffect, useState } from "react";
import PropTypes, { array } from "prop-types";
import Form from "partial/form/Form";
import FormInput from "partial/form/FormInput";
import Button from "partial/components/Button";
import { showDrawer } from "partial/components/Modal";
import { useCreateWithdraw, useUserList } from "../hooks";
import { transformList } from "../transformers";
import FormSelect from "partial/form/FormSelect";
import _ from "lodash";

export const openWithdrawModal = (title, data, onSuccess) => {
  showDrawer({
    size: "xs",
    title,
    content: (onClose) => (
      <WithdrawModal data={data} onClose={onClose} onSuccess={onSuccess} />
    ),
  });
};

function WithdrawModal({ data, onClose, onSuccess }) {
  const [form, setForm] = React.useState({});
  const [isCreate, create] = useCreateWithdraw();
  const handleSubmit = () => {
    create(form, (newData) => {
      onClose();
      // onSuccess(newData);
    });
    return;
  };

  const [, userList] = useUserList();

  const [cashOutToOptions, setFormArrayData] = useState([]);

  let finalVal = '';

  useEffect(() => {
    finalVal = Object.keys(userList).map((x, index) => ({
      value: userList[x],
      label: x,
    }));
    setFormArrayData(finalVal);
  }, [userList]);

  return (
    <Form className="divide-y divide-gray-200" onSubmit={handleSubmit}>
      <div>
        <div className="mb-3">
          <FormSelect
            name="cash_out_to_user_id"
            onChange={setForm}
            value={_.get(form, "cash_out_to_user_id") || ""}
            label="Cash Out To"
            required
            placeholder="Cash Out To"
            options={cashOutToOptions}
          />
        </div>
        <div className="mb-3">
          <FormInput
            name="amount"
            onChange={setForm}
            value={_.get(form, "amount") || 0}
            label="Amount"
            required
            type="number"
          />
        </div>
      </div>
      <div className="pt-5">
        <div className="flex justify-end">
          <Button type="submit" primary size="lg" disabled={isCreate}>
            Save
          </Button>
        </div>
      </div>
    </Form>
  );
}

WithdrawModal.defaultProps = {
  data: transformList(),
  onSuccess: false,
};

WithdrawModal.propTypes = {
  data: PropTypes.instanceOf(Object),
  onSuccess: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onClose: PropTypes.func.isRequired,
};

export default WithdrawModal;
