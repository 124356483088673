import React from "react";
import PropTypes from "prop-types";
import { useDebouceEffect } from "partial/form/hooks";

function AdminListFilter({ onSubmit, value }) {
  const [form, setForm] = React.useState(value);
  useDebouceEffect(form.email, (email) => {
    onSubmit((state) => ({
      ...state,
      email,
    }));
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit((state) => ({
      ...state,
      ...form,
    }));
  };

  const handleOnChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <form className="mt-6 flex space-x-4" onSubmit={handleSubmit}>
      <div className="flex-1 min-w-0 search-wrapper">
        <label htmlFor="search" className="sr-only">
          Search
        </label>
        <input
          name="email"
          onChange={handleOnChange}
          value={form.email}
          placeholder="Search..."
        />
      </div>
    </form>
  );
}

AdminListFilter.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Object).isRequired,
};

export default AdminListFilter;
