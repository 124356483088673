import { req } from "react-reqq-lite";
import { toastSuccess } from "react-geek-toast";
import { transformList } from "./transformers";
import * as c from "./constants";

export const listRooms = (params) => {
  req.get({
    key: c.ROOM_LIST,
    url: "/api/superAdmin/rooms",
    params: params,
    transform: (res) => (res.data || []).map(transformList),
    onSuccess: (res) => {
      req.set(c.ROOM_PAGER, res?.response?.meta?.pagination || {});
      req.set(c.ROOM_FILTER, params);
    },
  });
};

export const selectRoom = (id) => {
  req.get({
    key: c.ROOM_ITEM,
    url: `/api/superAdmin/rooms/${id}`,
    onSuccess: (res) => {
      req.set(c.ROOM_ITEM, res?.response?.data || {});
    },
  });
};

export const createRoom = (payload, onSuccess) => {
  req.post({
    key: c.ROOM_ITEM,
    url: "/api/superAdmin/rooms",
    payload,
    onSuccess: (res, state) => {
      toastSuccess("Created!");
      setTimeout(() => {
        listRooms(state[c.ROOM_FILTER]);
      }, 1000);
      if (onSuccess) onSuccess(transformList(res?.response?.data));
    },
  });
};

export const updateRoom = (id, payload, onSuccess) => {
  req.put({
    key: c.ROOM_ITEM,
    url: `/api/superAdmin/rooms/${id}`,
    payload,
    onSuccess: (res, state) => {
      toastSuccess("Updated!");
      setTimeout(() => {
        listRooms(state[c.ROOM_FILTER]);
      }, 1000);
      selectRoom(id);
      if (onSuccess) onSuccess(transformList(res?.response?.data));
    },
  });
};

export const updateRoomEmail = (id, payload, onSuccess) => {
  req.put({
    key: c.ROOM_ITEM,
    url: `/api/superAdmin/rooms/${id}`,
    payload,
    onSuccess: (res, state) => {
      toastSuccess("Updated!");
      setTimeout(() => {
        listRooms(state[c.ROOM_FILTER]);
      }, 1000);
      selectRoom(id);
      if (onSuccess) onSuccess(transformList(res?.response?.data));
    },
  });
};

export const removeRoom = (id, onSuccess) => {
  req.remove({
    key: c.ROOM_ITEM,
    url: `/api/superAdmin/rooms/${id}`,
    onSuccess: (res, state) => {
      toastSuccess("Removed!");
      setTimeout(() => {
        listRooms(state[c.ROOM_FILTER]);
      }, 1000);
      req.set(c.ROOM_ITEM, null);
      if (onSuccess) onSuccess();
    },
  });
};

export const changeStatus = (id, payload, onSuccess) => {
  req.put({
    key: c.ROOM_ITEM,
    url: `/api/superAdmin/rooms/${id}/change_status`,
    payload,
    onSuccess: (res, state) => {
      toastSuccess("Updated!");
      setTimeout(() => {
        listRooms(state[c.ROOM_FILTER]);
      }, 1000);
      selectRoom(id);
      if (onSuccess) onSuccess(transformList(res?.response?.data));
    },
  });
};
