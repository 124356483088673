import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/outline";

function SideList({ children, renderList }) {
  const location = useLocation();
  const [selected, setSelected] = React.useState(location?.state?.selected);
  const handleClearSelected = (e) => {
    e.preventDefault();
    setSelected(null);
  };
  const hasSelected = !!selected;
  return (
    <div className="flex-1 relative z-0 flex overflow-hidden bg-white">
      <main
        className={cn(
          "flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last rounded-bl-xl",
          hasSelected ? "block" : "hidden xl:block"
        )}
      >
        <nav
          className="bg-white flex items-start px-4 py-5 md:py-3 sm:px-6 lg:px-8 sm:hidden"
          aria-label="Breadcrumb"
        >
          <a
            href="/"
            className="inline-flex items-center space-x-3 text-base font-medium text-gray-900"
            onClick={handleClearSelected}
          >
            <ChevronLeftIcon
              className="-ml-2 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            <span>Back to List</span>
          </a>
        </nav>
        {children}
      </main>
      <aside
        className={cn(
          "sm:order-first sm:flex sm:flex-col flex-shrink-0 sm:w-96 border-r border-gray-200 bg-white",
          hasSelected ? "hidden" : "flex w-full min-h-full flex-col"
        )}
      >
        {renderList(selected, setSelected)}
      </aside>
    </div>
  );
}

SideList.propTypes = {
  renderList: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

export default SideList;
