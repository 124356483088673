export const LIST = "AGENT/list";
export const PAGER = "AGENT/pager";
export const ITEM = "AGENT/item";
export const FILTER = "AGENT/filter";

export const AGENT_WALLET_LIST = "AGENT/wallet_list";
export const AGENT_WALLET_PAGER = "AGENT/wallet_pager";

export const AGENT_PLAYER_LIST = "PLAYER/player_list";
export const AGENT_PLAYER_PAGER = "PLAYER/player_pager";

export const AGENT_PLAYER_WALLET_LIST = "AGENT/player_wallet_list";
export const AGENT_PLAYER_WALLET_PAGER = "AGENT/player_wallet_pager";

export const AGENT_PLAYER_CASHOUT_LIST = "PLAYER/player_cashout_list";
export const AGENT_PLAYER_CASHOUT_PAGER = "PLAYER/player_cashout_pager";
export const APPROVE_CASHOUT = "PLAYER/approve_cashout";

export const ADD_CREDIT_FUND_AGENT = "AGENT/add_credit_agent";
export const ADD_DEBIT_FUND_AGENT = "AGENT/add_debit_agent";

export const ADD_CREDIT_FUND_PLAYER = "PLAYER/add_credit_player";
export const ADD_DEBIT_FUND_PLAYER = "PLAYER/add_debit_player";

export const CHANGE_STATUS_AGENT = "AGENT/change_status_agent";
