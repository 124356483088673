import React from "react";
import PropTypes from "prop-types";

function WithdrawContent({ data }) {
  const addCommas = (num) =>
    num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return (
    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
      <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">Cash Out To</dt>
        <dd className="mt-1 text-base text-gray-900 font-semibold">
          {data.name}
        </dd>
      </div>

      <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">Email Address</dt>
        <dd className="mt-1 text-base text-gray-900 font-semibold">
          {data.email}
        </dd>
      </div>

      <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">Amount</dt>
        <dd className="mt-1 text-base text-gray-900 font-semibold">
          {addCommas(data.amount)}
        </dd>
      </div>

      <div className="sm:col-span-3">
        <dt className="text-sm font-medium text-gray-500">Status</dt>
        <dd className="mt-1 text-sm text-gray-900">{data.status}</dd>
      </div>
    </dl>
  );
}

WithdrawContent.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default WithdrawContent;
