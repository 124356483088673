import { useApiGet, useApiLoading } from "react-reqq-lite";
import { useCallback, useEffect } from "react";
import { ITEM } from "./constants";
import {
  selectProfile,
  updateProfile,
  updatePassword
} from "./actions";

export const useProfileSelected = () => {
  const isLoading = useApiLoading(ITEM, "get");
  const data = useApiGet(ITEM, {});
  useEffect(() => {
    selectProfile();
  }, []);
  return [isLoading, data];
};

export const useUpdateProfile = () => {
  const isLoading = useApiLoading(ITEM, "put");
  const submit = useCallback((id, payload, onSuccess) => {
    updateProfile(id, payload, onSuccess);
  }, []);
  return [isLoading, submit];
};

export const useUpdatePassword = () => {
  const isLoading = useApiLoading(ITEM, "put");
  const submit = useCallback((id, payload, onSuccess) => {
    updatePassword(id, payload, onSuccess);
  }, []);
  return [isLoading, submit];
};
