import React from "react";
import PropTypes from "prop-types";

export const FormContext = React.createContext();

export const ErrorLabel = ({ name }) => {
  const { error } = React.useContext(FormContext);
  if (!error[name]) return null;
  return (
    <p className="mt-2 text-sm text-red-600" id={name}>
      {error[name]}
    </p>
  );
};

ErrorLabel.propTypes = {
  name: PropTypes.string.isRequired,
};

function Form({ onSubmit, children, error, ...props }) {
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit();
  };
  return (
    <FormContext.Provider value={{ error }}>
      <form onSubmit={handleSubmit} {...props}>
        {children}
      </form>
    </FormContext.Provider>
  );
}

Form.defaultProps = {
  error: {},
};

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  error: PropTypes.instanceOf(Object),
};

export default Form;
