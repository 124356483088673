import React from "react";
// import PropTypes from 'prop-types';
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import HandlerProfile from "./HandlerProfile";

function HandlerPageRoutes() {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}`} component={HandlerProfile} />
      <Redirect to={`${match.url}`} />
    </Switch>
  );
}

HandlerPageRoutes.propTypes = {};

export default HandlerPageRoutes;
