import React from "react";
import PropTypes from "prop-types";
import NavLink from "partial/components/NavLink";

export const NavTabItem = ({ to, label, ...props }) => (
  <NavLink
    to={to}
    className="inline-flex items-center px-5 py-2 text-base font-semibold rounded-lg leading-relaxed"
    inactiveClassName="text-gray-500 hover:text-gray-700"
    activeClassName="text-primary-500 bg-white"
    {...props}
  >
    {label}
  </NavLink>
);

NavTabItem.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

function NavTab({ children }) {
  return <div className="bg-white my-2 flex items-center mx-2">{children}</div>;
}

NavTab.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
};

export default NavTab;
