import React from "react";
// import bg from "assets/images/background.png";
// import logo from "assets/images/logo-horizontal.svg";
import Login from "./Login";

function Landing() {
  return (
    <div
      className="h-full flex flex-col bg-no-repeat bg-center bg-cover bg-gray-100"
      // style={{ backgroundImage: `url(${bg})` }}
    >
      <div className="w-full container m-auto relative">
        <div className="m-auto w-full p-10 rounded-none sm:rounded-3xl bg-white shadow-primary-xl relative z-10">
          {/* <img src={logo} className="h-14 mx-auto mb-5" alt="App" /> */}
          <div className="text-center mb-10">
            <div className="text-2xl text-gray-900 font-semibold mb-0 text-center">
              Sign In
            </div>
          </div>
          <Login />
        </div>
        <div className="absolute left-10 right-10 z-0" style={{ bottom: -20 }}>
          <div className="bg-white w-full p-10 rounded-3xl shadow-3xl" />
        </div>
      </div>
    </div>
  );
}

Landing.propTypes = {};

export default Landing;
