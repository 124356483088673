import React from "react";
import PropTypes from "prop-types";
import Form from "partial/form/Form";
import Button from "partial/components/Button";
import { showDrawer } from "partial/components/Modal";
import ReactInputMask from "react-input-mask";
import { useUpdatePassword, useUpdateProfile } from "./hooks";
import { transformList } from "./transformers";
import FormInputPassword from "partial/form/FormInputPassword";
import FormInput from "partial/form/FormInput";

export const openEditProfileModal = (title, data, type, onSuccess) => {
  showDrawer({
    size: "xs",
    title,
    content: (onClose) => (
      <EditProfileModal
        data={data}
        onClose={onClose}
        onSuccess={onSuccess}
        type={type}
      />
    ),
  });
};

function EditProfileModal({ data, onClose, type, onSuccess }) {
  const [form, setForm] = React.useState(data);
  const [isUpdate, update] = useUpdateProfile();
  const [isUpdatePw, updatePassword] = useUpdatePassword();
  const handleSubmit = () => {
    if (type === "profile") {
      update(data?.id, form, onClose);
    }
    if (type === "password") {
      updatePassword(data?.id, form, onClose);
    }
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };
  return (
    <Form className="divide-y divide-gray-200" onSubmit={handleSubmit}>
      <div>
        {type === "profile" && (
          <>
            <div className="mb-3">
              <FormInput
                name="first_name"
                onChange={setForm}
                value={form.first_name}
                label="First Name"
                required
              />
            </div>
            <div className="mb-3">
              <FormInput
                name="last_name"
                onChange={setForm}
                value={form.last_name}
                label="Last Name"
                required
              />
            </div>
            <div className="mb-3">
              <div className="relative border rounded-md px-3 py-2 shadow-sm focus-within:ring-1 bg-white border-gray-300 focus-within:ring-primary-600 focus-within:border-primary-600">
                <label
                  htmlFor="phone"
                  className="block text-xs font-medium text-gray-900"
                >
                  Mobile Number
                </label>
                <ReactInputMask
                  required
                  type="text"
                  mask="09999999999"
                  maskChar=""
                  className="block w-full p-0 border-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-base focus:outline-none font-semibold"
                  name="phone_number"
                  value={form.phone_number}
                  onChange={handleOnChange}
                  placeholder="0xxxxxxxxxx"
                />
              </div>
            </div>
          </>
        )}

        {type === "password" && (
          <>
            <div className="mb-3">
              <FormInputPassword
                name="current_password"
                onChange={setForm}
                value={form.current_password}
                label="Current Password"
                required
                withIcon={true}
              />
            </div>
            <div className="mb-3">
              <FormInputPassword
                name="password"
                onChange={setForm}
                value={form.password}
                label="Password"
                required
                withIcon={true}
              />
            </div>
            <div className="mb-3">
              <FormInputPassword
                name="password_confirmation"
                onChange={setForm}
                value={form.password_confirmation}
                label="Confirm Password"
                required
                withIcon={true}
              />
            </div>
          </>
        )}
      </div>
      <div className="pt-5">
        <div className="flex justify-end">
          <Button
            type="submit"
            primary
            size="lg"
            disabled={type === "profile" ? isUpdate : isUpdatePw}
          >
            Save
          </Button>
        </div>
      </div>
    </Form>
  );
}

EditProfileModal.defaultProps = {
  data: transformList(),
  onSuccess: false,
  type: "",
};

EditProfileModal.propTypes = {
  data: PropTypes.instanceOf(Object),
  onSuccess: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onClose: PropTypes.func.isRequired,
  type: PropTypes.string,
};

export default EditProfileModal;
