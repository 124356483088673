import { req } from "react-reqq-lite";
import { toastSuccess } from "react-geek-toast";
import { transformMasterAgent } from "./transformers";
import * as c from "./constants";

export const listMasterAgents = (params) => {
  req.get({
    key: c.MASTER_AGENT_LIST,
    url: "/api/superAdmin/masterAgents",
    params: params,
    transform: (res) => (res.data || []).map(transformMasterAgent),
    onSuccess: (res) => {
      req.set(c.MASTER_AGENT_PAGER, res?.response?.meta?.pagination || {});
      req.set(c.MASTER_AGENT_FILTER, params);
    },
  });
};

export const selectMasterAgent = (id) => {
  req.get({
    key: c.MASTER_AGENT_ITEM,
    url: `/api/superAdmin/masterAgents/${id}`,
    transform: (res) => transformMasterAgent(res.data),
  });
};

export const createMasterAgent = (payload, onSuccess) => {
  req.post({
    key: c.MASTER_AGENT_ITEM,
    url: "/api/superAdmin/masterAgents",
    payload,
    onSuccess: (res, state) => {
      toastSuccess("Created!");
      setTimeout(() => {
        listMasterAgents(state[c.MASTER_AGENT_FILTER]);
      }, 1000);
      if (onSuccess) onSuccess(transformMasterAgent(res?.response?.data));
    },
  });
};

export const updateMasterAgent = (id, payload, onSuccess) => {
  req.put({
    key: c.MASTER_AGENT_ITEM,
    url: `/api/superAdmin/masterAgents/${id}`,
    payload,
    onSuccess: (res, state) => {
      toastSuccess("Updated!");
      setTimeout(() => {
        listMasterAgents(state[c.MASTER_AGENT_FILTER]);
      }, 1000);
      selectMasterAgent(id);
      if (onSuccess) onSuccess(transformMasterAgent(res?.response?.data));
    },
  });
};

export const updateMasterAgentEmail = (id, payload, onSuccess) => {
  req.put({
    key: c.MASTER_AGENT_ITEM,
    url: `/api/superAdmin/masterAgents/${id}`,
    payload,
    onSuccess: (res, state) => {
      toastSuccess("Updated!");
      setTimeout(() => {
        listMasterAgents(state[c.MASTER_AGENT_FILTER]);
      }, 1000);
      selectMasterAgent(id);
      if (onSuccess) onSuccess(transformMasterAgent(res?.response?.data));
    },
  });
};

export const removeMasterAgent = (id, onSuccess) => {
  req.remove({
    key: c.MASTER_AGENT_ITEM,
    url: `/api/superAdmin/masterAgents/${id}`,
    onSuccess: (res, state) => {
      toastSuccess("Removed!");
      setTimeout(() => {
        listMasterAgents(state[c.MASTER_AGENT_FILTER]);
      }, 1000);
      req.set(c.MASTER_AGENT_ITEM, null);
      if (onSuccess) onSuccess();
    },
  });
};

export const changeStatus = (id, payload, onSuccess) => {
  req.put({
    key: c.MASTER_AGENT_ITEM,
    url: `/api/superAdmin/masterAgents/${id}/change_status`,
    payload,
    onSuccess: (res, state) => {
      toastSuccess("Updated!");
      setTimeout(() => {
        listMasterAgents(state[c.MASTER_AGENT_FILTER]);
      }, 1000);
      selectMasterAgent(id);
      if (onSuccess) onSuccess(transformMasterAgent(res?.response?.data));
    },
  });
};
