import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

function FormInlineInput({ icon: Icon, name, onChange, value, ...props }) {
  const handleChange = ({ target }) => {
    onChange((state) => ({
      ...state,
      [name]: target.value,
    }));
  };
  return (
    <div className="relative rounded-md shadow-sm">
      {Icon && (
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <Icon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
      )}
      <input
        type="text"
        className={cn(
          "form-input focus:ring-primary-500 focus:border-primary-500 block py-3 w-full sm:text-sm border-gray-300 rounded-md",
          "pl-10" // with icon
        )}
        onChange={handleChange}
        value={value}
        {...props}
      />
    </div>
  );
}

FormInlineInput.defaultProps = {
  icon: false,
};

FormInlineInput.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.bool]),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default FormInlineInput;
