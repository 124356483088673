import React from "react";
import PropTypes from "prop-types";
import Form from "partial/form/Form";
import Button from "partial/components/Button";
import { showDrawer } from "partial/components/Modal";
import { useUpdateMasterAgent } from "../hooks";
import { transformList } from "../transformers";
import FormSelect from "partial/form/FormSelect";
import _ from "lodash";

export const openMasterAgentModal = (title, data, onSuccess) => {
  showDrawer({
    size: "xs",
    title,
    content: (onClose) => (
      <MasterAgentModal data={data} onClose={onClose} onSuccess={onSuccess} />
    ),
  });
};

function MasterAgentModal({ data, onClose }) {
  const [form, setForm] = React.useState(data);
  const [isUpdate, update] = useUpdateMasterAgent();
  const handleSubmit = () => {
    update(data?.id, form, onClose);
  };

  const [percentageOptions, setPercentageOptions] = React.useState([]);

  React.useEffect(() => {
    if (+_.get(data, 'attributes.percentage_config.percentage') === 12 || _.get(data, 'attributes.percentage_config.percentage') === null ||
      _.get(data, 'attributes.percentage_config.percentage') === undefined) {
        setPercentageOptions([
          { label: "Select Percentage", value: "" },
          { label: "1", value: "1" },
          { label: "1.5", value: "1.5" },
          { label: "2", value: "2" },
          { label: "2.5", value: "2.5" },
          { label: "3", value: "3" },
          { label: "3.5", value: "3.5" },
          { label: "4", value: "4" },
          { label: "4.5", value: "4.5" },
          { label: "5", value: "5" },
          { label: "5.5", value: "5.5" },
          { label: "6", value: "6" },
          { label: "6.5", value: "6.5" },
          { label: "7", value: "7" },
          { label: "7.5", value: "7.5" },
          { label: "8", value: "8" },
          { label: "8.5", value: "8.5" },
          { label: "9", value: "9" },
          { label: "9.5", value: "9.5" },
          { label: "10", value: "10" },
          { label: "10.5", value: "10.5" },
          { label: "11", value: "11" },
          { label: "11.5", value: "11.5" },
          { label: "12", value: "12" }
        ])
      }
  
      if (+_.get(data, 'attributes.percentage_config.percentage') === 11) {
        setPercentageOptions([
          { label: "Select Percentage", value: "" },
          { label: "1", value: "1" },
          { label: "1.5", value: "1.5" },
          { label: "2", value: "2" },
          { label: "2.5", value: "2.5" },
          { label: "3", value: "3" },
          { label: "3.5", value: "3.5" },
          { label: "4", value: "4" },
          { label: "4.5", value: "4.5" },
          { label: "5", value: "5" },
          { label: "5.5", value: "5.5" },
          { label: "6", value: "6" },
          { label: "6.5", value: "6.5" },
          { label: "7", value: "7" },
          { label: "7.5", value: "7.5" },
          { label: "8", value: "8" },
          { label: "8.5", value: "8.5" },
          { label: "9", value: "9" },
          { label: "9.5", value: "9.5" },
          { label: "10", value: "10" },
          { label: "10.5", value: "10.5" },
          { label: "11", value: "11" }
        ])
      }
  
      if (+_.get(data, 'attributes.percentage_config.percentage') === 11.5) {
        setPercentageOptions([
          { label: "Select Percentage", value: "" },
          { label: "1", value: "1" },
          { label: "1.5", value: "1.5" },
          { label: "2", value: "2" },
          { label: "2.5", value: "2.5" },
          { label: "3", value: "3" },
          { label: "3.5", value: "3.5" },
          { label: "4", value: "4" },
          { label: "4.5", value: "4.5" },
          { label: "5", value: "5" },
          { label: "5.5", value: "5.5" },
          { label: "6", value: "6" },
          { label: "6.5", value: "6.5" },
          { label: "7", value: "7" },
          { label: "7.5", value: "7.5" },
          { label: "8", value: "8" },
          { label: "8.5", value: "8.5" },
          { label: "9", value: "9" },
          { label: "9.5", value: "9.5" },
          { label: "10", value: "10" },
          { label: "10.5", value: "10.5" },
          { label: "11", value: "11" },
          { label: "11.5", value: "11.5" }
        ])
      }
  
      if (+_.get(data, 'attributes.percentage_config.percentage') === 10) {
        setPercentageOptions([
          { label: "Select Percentage", value: "" },
          { label: "1", value: "1" },
          { label: "1.5", value: "1.5" },
          { label: "2", value: "2" },
          { label: "2.5", value: "2.5" },
          { label: "3", value: "3" },
          { label: "3.5", value: "3.5" },
          { label: "4", value: "4" },
          { label: "4.5", value: "4.5" },
          { label: "5", value: "5" },
          { label: "5.5", value: "5.5" },
          { label: "6", value: "6" },
          { label: "6.5", value: "6.5" },
          { label: "7", value: "7" },
          { label: "7.5", value: "7.5" },
          { label: "8", value: "8" },
          { label: "8.5", value: "8.5" },
          { label: "9", value: "9" },
          { label: "9.5", value: "9.5" },
          { label: "10", value: "10" }
        ])
      }
  
      if (+_.get(data, 'attributes.percentage_config.percentage') === 10.5) {
        setPercentageOptions([
          { label: "Select Percentage", value: "" },
          { label: "1", value: "1" },
          { label: "1.5", value: "1.5" },
          { label: "2", value: "2" },
          { label: "2.5", value: "2.5" },
          { label: "3", value: "3" },
          { label: "3.5", value: "3.5" },
          { label: "4", value: "4" },
          { label: "4.5", value: "4.5" },
          { label: "5", value: "5" },
          { label: "5.5", value: "5.5" },
          { label: "6", value: "6" },
          { label: "6.5", value: "6.5" },
          { label: "7", value: "7" },
          { label: "7.5", value: "7.5" },
          { label: "8", value: "8" },
          { label: "8.5", value: "8.5" },
          { label: "9", value: "9" },
          { label: "9.5", value: "9.5" },
          { label: "10", value: "10" },
          { label: "10.5", value: "10.5" }
        ])
      }
  
      if (+_.get(data, 'attributes.percentage_config.percentage') === 9) {
        setPercentageOptions([
          { label: "Select Percentage", value: "" },
          { label: "1", value: "1" },
          { label: "1.5", value: "1.5" },
          { label: "2", value: "2" },
          { label: "2.5", value: "2.5" },
          { label: "3", value: "3" },
          { label: "3.5", value: "3.5" },
          { label: "4", value: "4" },
          { label: "4.5", value: "4.5" },
          { label: "5", value: "5" },
          { label: "5.5", value: "5.5" },
          { label: "6", value: "6" },
          { label: "6.5", value: "6.5" },
          { label: "7", value: "7" },
          { label: "7.5", value: "7.5" },
          { label: "8", value: "8" },
          { label: "8.5", value: "8.5" },
          { label: "9", value: "9" }
        ])
      }
  
      if (+_.get(data, 'attributes.percentage_config.percentage') === 9.5) {
        setPercentageOptions([
          { label: "Select Percentage", value: "" },
          { label: "1", value: "1" },
          { label: "1.5", value: "1.5" },
          { label: "2", value: "2" },
          { label: "2.5", value: "2.5" },
          { label: "3", value: "3" },
          { label: "3.5", value: "3.5" },
          { label: "4", value: "4" },
          { label: "4.5", value: "4.5" },
          { label: "5", value: "5" },
          { label: "5.5", value: "5.5" },
          { label: "6", value: "6" },
          { label: "6.5", value: "6.5" },
          { label: "7", value: "7" },
          { label: "7.5", value: "7.5" },
          { label: "8", value: "8" },
          { label: "8.5", value: "8.5" },
          { label: "9", value: "9" },
          { label: "9.5", value: "9.5" }
        ])
      }
  
      if (+_.get(data, 'attributes.percentage_config.percentage') === 8) {
        setPercentageOptions([
          { label: "Select Percentage", value: "" },
          { label: "1", value: "1" },
          { label: "1.5", value: "1.5" },
          { label: "2", value: "2" },
          { label: "2.5", value: "2.5" },
          { label: "3", value: "3" },
          { label: "3.5", value: "3.5" },
          { label: "4", value: "4" },
          { label: "4.5", value: "4.5" },
          { label: "5", value: "5" },
          { label: "5.5", value: "5.5" },
          { label: "6", value: "6" },
          { label: "6.5", value: "6.5" },
          { label: "7", value: "7" },
          { label: "7.5", value: "7.5" },
          { label: "8", value: "8" }
        ])
      }
  
      if (+_.get(data, 'attributes.percentage_config.percentage') === 8.5) {
        setPercentageOptions([
          { label: "Select Percentage", value: "" },
          { label: "1", value: "1" },
          { label: "1.5", value: "1.5" },
          { label: "2", value: "2" },
          { label: "2.5", value: "2.5" },
          { label: "3", value: "3" },
          { label: "3.5", value: "3.5" },
          { label: "4", value: "4" },
          { label: "4.5", value: "4.5" },
          { label: "5", value: "5" },
          { label: "5.5", value: "5.5" },
          { label: "6", value: "6" },
          { label: "6.5", value: "6.5" },
          { label: "7", value: "7" },
          { label: "7.5", value: "7.5" },
          { label: "8", value: "8" },
          { label: "8.5", value: "8.5" }
        ])
      }
  
      if (+_.get(data, 'attributes.percentage_config.percentage') === 7) {
        setPercentageOptions([
          { label: "Select Percentage", value: "" },
          { label: "1", value: "1" },
          { label: "1.5", value: "1.5" },
          { label: "2", value: "2" },
          { label: "2.5", value: "2.5" },
          { label: "3", value: "3" },
          { label: "3.5", value: "3.5" },
          { label: "4", value: "4" },
          { label: "4.5", value: "4.5" },
          { label: "5", value: "5" },
          { label: "5.5", value: "5.5" },
          { label: "6", value: "6" },
          { label: "6.5", value: "6.5" },
          { label: "7", value: "7" },
        ])
      }
  
      if (+_.get(data, 'attributes.percentage_config.percentage') === 7.5) {
        setPercentageOptions([
          { label: "Select Percentage", value: "" },
          { label: "1", value: "1" },
          { label: "1.5", value: "1.5" },
          { label: "2", value: "2" },
          { label: "2.5", value: "2.5" },
          { label: "3", value: "3" },
          { label: "3.5", value: "3.5" },
          { label: "4", value: "4" },
          { label: "4.5", value: "4.5" },
          { label: "5", value: "5" },
          { label: "5.5", value: "5.5" },
          { label: "6", value: "6" },
          { label: "6.5", value: "6.5" },
          { label: "7", value: "7" },
          { label: "7.5", value: "7.5" }
        ])
      }
  
      if (+_.get(data, 'attributes.percentage_config.percentage') === 6) {
        setPercentageOptions([
          { label: "Select Percentage", value: "" },
          { label: "1", value: "1" },
          { label: "1.5", value: "1.5" },
          { label: "2", value: "2" },
          { label: "2.5", value: "2.5" },
          { label: "3", value: "3" },
          { label: "3.5", value: "3.5" },
          { label: "4", value: "4" },
          { label: "4.5", value: "4.5" },
          { label: "5", value: "5" },
          { label: "5.5", value: "5.5" },
          { label: "6", value: "6" }
        ])
      }
  
      if (+_.get(data, 'attributes.percentage_config.percentage') === 6.5) {
        setPercentageOptions([
          { label: "Select Percentage", value: "" },
          { label: "1", value: "1" },
          { label: "1.5", value: "1.5" },
          { label: "2", value: "2" },
          { label: "2.5", value: "2.5" },
          { label: "3", value: "3" },
          { label: "3.5", value: "3.5" },
          { label: "4", value: "4" },
          { label: "4.5", value: "4.5" },
          { label: "5", value: "5" },
          { label: "5.5", value: "5.5" },
          { label: "6", value: "6" },
          { label: "6.5", value: "6.5" }
        ])
      }
  
      if (+_.get(data, 'attributes.percentage_config.percentage') === 5) {
        setPercentageOptions([
          { label: "Select Percentage", value: "" },
          { label: "1", value: "1" },
          { label: "1.5", value: "1.5" },
          { label: "2", value: "2" },
          { label: "2.5", value: "2.5" },
          { label: "3", value: "3" },
          { label: "3.5", value: "3.5" },
          { label: "4", value: "4" },
          { label: "4.5", value: "4.5" },
          { label: "5", value: "5" },
        ])
      }
  
      if (+_.get(data, 'attributes.percentage_config.percentage') === 5.5) {
        setPercentageOptions([
          { label: "Select Percentage", value: "" },
          { label: "1", value: "1" },
          { label: "1.5", value: "1.5" },
          { label: "2", value: "2" },
          { label: "2.5", value: "2.5" },
          { label: "3", value: "3" },
          { label: "3.5", value: "3.5" },
          { label: "4", value: "4" },
          { label: "4.5", value: "4.5" },
          { label: "5", value: "5" },
          { label: "5.5", value: "5.5" }
        ])
      }
  
      if (+_.get(data, 'attributes.percentage_config.percentage') === 4) {
        setPercentageOptions([
          { label: "Select Percentage", value: "" },
          { label: "1", value: "1" },
          { label: "1.5", value: "1.5" },
          { label: "2", value: "2" },
          { label: "2.5", value: "2.5" },
          { label: "3", value: "3" },
          { label: "3.5", value: "3.5" },
          { label: "4", value: "4" }
        ])
      }
  
      if (+_.get(data, 'attributes.percentage_config.percentage') === 4.5) {
        setPercentageOptions([
          { label: "Select Percentage", value: "" },
          { label: "1", value: "1" },
          { label: "1.5", value: "1.5" },
          { label: "2", value: "2" },
          { label: "2.5", value: "2.5" },
          { label: "3", value: "3" },
          { label: "3.5", value: "3.5" },
          { label: "4", value: "4" },
          { label: "4.5", value: "4.5" }
        ])
      }
  
      if (+_.get(data, 'attributes.percentage_config.percentage') === 3) {
        setPercentageOptions([
          { label: "Select Percentage", value: "" },
          { label: "1", value: "1" },
          { label: "1.5", value: "1.5" },
          { label: "2", value: "2" },
          { label: "2.5", value: "2.5" },
          { label: "3", value: "3" }
        ])
      }
  
      if (+_.get(data, 'attributes.percentage_config.percentage') === 3.5) {
        setPercentageOptions([
          { label: "Select Percentage", value: "" },
          { label: "1", value: "1" },
          { label: "1.5", value: "1.5" },
          { label: "2", value: "2" },
          { label: "2.5", value: "2.5" },
          { label: "3", value: "3" },
          { label: "3.5", value: "3.5" }
        ])
      }
  
      if (+_.get(data, 'attributes.percentage_config.percentage') === 2) {
        setPercentageOptions([
          { label: "Select Percentage", value: "" },
          { label: "1", value: "1" },
          { label: "1.5", value: "1.5" },
          { label: "2", value: "2" }
        ])
      }
  
      if (+_.get(data, 'attributes.percentage_config.percentage') === 2.5) {
        setPercentageOptions([
          { label: "Select Percentage", value: "" },
          { label: "1", value: "1" },
          { label: "1.5", value: "1.5" },
          { label: "2", value: "2" },
          { label: "2.5", value: "2.5" }
        ])
      }
  
      if (+_.get(data, 'attributes.percentage_config.percentage') === 1) {
        setPercentageOptions([
          { label: "Select Percentage", value: "" },
          { label: "1", value: "1" },
        ])
      }
  
      if (+_.get(data, 'attributes.percentage_config.percentage') === 1.5) {
        setPercentageOptions([
          { label: "Select Percentage", value: "" },
          { label: "1", value: "1" },
          { label: "1.5", value: "1.5" },
        ])
      }
  }, []);

  return (
    <Form className="divide-y divide-gray-200" onSubmit={handleSubmit}>
      <div>
        <div className="mb-3">
          <FormSelect
            name="percentage"
            label="Percentage"
            id="percentage"
            onChange={setForm}
            options={percentageOptions}
            value={_.get(form, "percentage") || ""}
            required
          />
        </div>
      </div>
      <div className="pt-5">
        <div className="flex justify-end">
          <Button type="submit" primary size="lg" disabled={isUpdate}>
            Update
          </Button>
        </div>
      </div>
    </Form>
  );
}

MasterAgentModal.defaultProps = {
  data: transformList(),
  onSuccess: false,
};

MasterAgentModal.propTypes = {
  data: PropTypes.instanceOf(Object),
  onSuccess: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onClose: PropTypes.func.isRequired,
};

export default MasterAgentModal;
