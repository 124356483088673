import React from "react";
// import React from 'react';
// import PropTypes from 'prop-types';
import SideList from "partial/wrappers/SideList";
import NavTab, { NavTabItem } from "partial/components/NavTab";
import { useRouteMatch, Switch, Route, Redirect } from "react-router-dom";
import SuperAdminAgentHeader from "../components/SuperAdminAgentHeader";
import SuperAdminAgentContent from "../components/SuperAdminAgentContent";
import SuperAdminAgentList from "../components/SuperAdminAgentList";
import { useSuperAdminAgentSelected } from "../hooks";

function SuperAdminAgentDetail() {
  const renderList = React.useCallback(
    (selected, onSelect) => (
      <SuperAdminAgentList onSelect={onSelect} selected={selected} />
    ),
    []
  );
  const match = useRouteMatch();
  const [isLoading, data] = useSuperAdminAgentSelected();

  return (
    <>
      <SideList renderList={renderList}>
        <div className="bg-white">
          <SuperAdminAgentHeader data={data} isLoading={isLoading} />
          <div className="border-b border-gray-200">
            <NavTab>
              <NavTabItem to={`${match.url}/details`} label="Details" />
            </NavTab>
          </div>
          {data.id ? (
            <div className="px-4 pt-4 pb-8 sm:px-6 lg:px-8">
              <Switch>
                <Route path={`${match.path}/details`}>
                  <SuperAdminAgentContent data={data} />
                </Route>
                <Redirect to={`${match.url}/details`} />
              </Switch>
            </div>
          ) : null}
        </div>
      </SideList>
    </>
  );
}

SuperAdminAgentDetail.propTypes = {};

export default SuperAdminAgentDetail;
