import React from "react";
// import PropTypes from 'prop-types';
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import SuperAdminDetail from "./SuperAdminDetail";

function SuperAdminPageRoutes() {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}`} component={SuperAdminDetail} />
      <Redirect to={`${match.url}`} />
    </Switch>
  );
}

SuperAdminPageRoutes.propTypes = {};

export default SuperAdminPageRoutes;
