import React from "react";
// import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

function PageNotFound() {
  return (
    <div className="py-6">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        {/* Replace with your content */}
        <div className="py-4">
          <div className="border-4 border-dashed border-gray-700 rounded-lg h-96 flex">
            <div className="m-auto text-center">
              <div className="text-gray-500">Page not Found</div>
              <div>
                <Link className="underline text-gray-500" to="/">
                  Go Back Home
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* /End replace */}
      </div>
    </div>
  );
}

PageNotFound.propTypes = {};

export default PageNotFound;
