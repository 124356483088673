import { req } from "react-reqq-lite";
import { toastSuccess } from "react-geek-toast";
import { ITEM } from "./constants";
import { transformList } from "./transformers";


export const selectProfile = () => {
  req.get({
    key: ITEM,
    url: `/api/my/profile`,
    onSuccess: (res, state) => {
      transformList(res?.response?.data?.profile);
    },
  });
};

export const updateProfile = (id, payload, onSuccess) => {
  req.put({
    key: ITEM,
    url: `/api/my/profile`,
    payload,
    onSuccess: (res, state) => {
      toastSuccess("Updated!");
      selectProfile();
      if (onSuccess) onSuccess(transformList(res?.response?.data));
    },
  });
};

export const updatePassword = (id, payload, onSuccess) => {
  req.put({
    key: ITEM,
    url: `/api/my/profile/change_password`,
    payload,
    onSuccess: (res, state) => {
      toastSuccess("Updated!");
      selectProfile();
      if (onSuccess) onSuccess(transformList(res?.response?.data));
    },
  });
};