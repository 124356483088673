import { useEffect, useCallback } from "react";
import { req, useApiGet, useApiLoading } from "react-reqq-lite";
import * as actions from "./actions";
import * as c from "./constants";

export const useGameManagerList = (params) => {
  const isLoading = useApiLoading(c.GAME_MANAGER_LIST, "get");
  const data = useApiGet(c.GAME_MANAGER_LIST, []);
  const pager = useApiGet(c.GAME_MANAGER_PAGER, {});
  useEffect(() => {
    actions.listGameManagers(params);
  }, [params]);
  return [isLoading, data, pager];
};

export const useGameManagerSelected = () => {
  const isLoading = useApiLoading(c.GAME_MANAGER_ITEM, "get");
  const data = useApiGet(c.GAME_MANAGER_ITEM, {});
  return [isLoading, data];
};

export const useSelectGameManager = (id) => {
  useEffect(() => {
    if (!id) {
      req.set(c.GAME_MANAGER_ITEM, null);
      return;
    }
    actions.selectGameManager(id);
  }, [id]);
};

export const useCreateGameManager = () => {
  const isLoading = useApiLoading(c.GAME_MANAGER_ITEM, "post");
  const submit = useCallback((payload, onSuccess) => {
    actions.createGameManager(payload, onSuccess);
  }, []);
  return [isLoading, submit];
};

export const useUpdateGameManager = () => {
  const isLoading = useApiLoading(c.GAME_MANAGER_ITEM, "put");
  const submit = useCallback((id, payload, onSuccess) => {
    actions.updateGameManager(id, payload, onSuccess);
  }, []);
  return [isLoading, submit];
};

export const useUpdateGameManagerEmail = () => {
  const isLoading = useApiLoading(c.GAME_MANAGER_ITEM, "put");
  const submit = useCallback((id, payload, onSuccess) => {
    actions.updateGameManagerEmail(id, payload, onSuccess);
  }, []);
  return [isLoading, submit];
};

export const useChangeStatus = () => {
  const isLoading = useApiLoading(c.GAME_MANAGER_ITEM, "put");
  const submit = useCallback((id, payload, onSuccess) => {
    actions.changeStatus(id, payload, onSuccess);
  }, []);
  return [isLoading, submit];
};

export const useRemoveGameManager = () => {
  const isLoading = useApiLoading(c.GAME_MANAGER_ITEM, "remove");
  const submit = useCallback((id, onSuccess) => {
    actions.removeGameManager(id, onSuccess);
  }, []);
  return [isLoading, submit];
};
