import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import PlayerProfile from "./PlayerProfile";

function AdminPlayerRoutes() {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}`} component={PlayerProfile} />
      <Redirect to={`${match.url}`} />
    </Switch>
  );
}

AdminPlayerRoutes.propTypes = {};

export default AdminPlayerRoutes;
