import cn from "classnames";
import store from "setup/store";
import PropTypes from "prop-types";
import { render } from "react-dom";
import history from "setup/history";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { HiOutlineDownload } from "react-icons/hi";
import React, { Fragment } from "react";
import { Router } from "react-router-dom";
import { Provider, useApiLoading } from "react-reqq-lite";
import { XIcon } from "@heroicons/react/outline";
import { Dialog, Transition } from "@headlessui/react";
import Button from "./Button";

const SIZE = {
  xs: "max-w-sm",
  sm: "max-w-lg",
  default: "max-w-2xl",
  lg: "max-w-4xl",
  xl: "max-w-6xl",
};

const DrawerModal = ({ options, onClose }) => {
  const [open, setOpen] = React.useState(false);
  React.useEffect(() => {
    setOpen(true);
  }, [setOpen]);
  const handleClose = () => {
    setOpen(false);
  };
  const sizeClassName = React.useMemo(
    () => SIZE[options.size] || SIZE.default,
    [options]
  );
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        open
        className="fixed inset-0 overflow-hidden z-40"
        onClose={() => {
          if (options.closeOutside) handleClose();
        }}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={onClose}
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className={cn("relative w-screen", sizeClassName)}>
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-500"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-500"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 sm:left-0 right-0 -ml-4 pt-4 pr-2 flex sm:-ml-16 sm:pr-4">
                    <button
                      className={cn(
                        "rounded-full bg-gray-900 text-white hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-white transition sm:bg-opacity-0 bg-opacity-50 hover:bg-opacity-100 hover:bg-white p-3 z-50",
                        {
                          "opacity-0 pointer-events-none": !options.closeButton,
                        }
                      )}
                      onClick={handleClose}
                      type="button"
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                  {options.title && (
                    <div className="px-4 sm:px-6">
                      <Dialog.Title className="text-lg font-medium text-gray-900">
                        {options.title}
                      </Dialog.Title>
                    </div>
                  )}
                  <div className="mt-6 relative flex-1 px-4 sm:px-6">
                    {options.content(handleClose)}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

DrawerModal.propTypes = {
  options: PropTypes.instanceOf(Object).isRequired,
  onClose: PropTypes.func.isRequired,
};

export const ModalMarker = () => {
  return <div id="modal-root" />;
};

export const closeModal = () => {
  const root = document.getElementById("modal-root");
  render(<ModalMarker />, root);
};

export const showDrawer = (options) => {
  const opt = {
    content: "Modal Content",
    closeButton: true,
    closeOutside: false,
    size: "default",
    title: "",
    ...options,
  };
  const root = document.getElementById("modal-root");
  const handleClose = () => {
    render(<ModalMarker />, root);
  };
  if (!root) {
    alert("Modal root not found!"); // eslint-disable-line
    return;
  }
  render(
    <Provider store={store}>
      <Router history={history}>
        <DrawerModal options={opt} onClose={handleClose} />
      </Router>
    </Provider>,
    root
  );
};

const AlertModal = ({ options, onClose }) => {
  const isLoading = useApiLoading(
    options.isLoadingKey,
    options.isLoadingMethod,
    false
  );
  const Icon = options.icon;
  const [open, setOpen] = React.useState(false);
  const cancelButtonRef = React.useRef(null);
  React.useEffect(() => {
    setOpen(true);
  }, [setOpen]);
  const handleNo = () => {
    options.onNo(() => {
      setOpen(false);
    });
  };
  const handleYes = () => {
    options.onYes(() => {
      setOpen(false);
    });
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-y-auto z-50"
        initialFocus={cancelButtonRef}
        open
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={onClose}
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  {Icon && (
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-danger-100 sm:mx-0 sm:h-10 sm:w-10">
                      <Icon
                        className="h-6 w-6 text-danger-600"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      {options.title}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        {options.subTitle}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse space-x-">
                <Button
                  className="w-full sm:w-auto sm:ml-3"
                  danger
                  disabled={isLoading}
                  onClick={handleYes}
                >
                  {options.yesLabel}
                </Button>
                <Button
                  className="w-full sm:w-auto sm:ml-3 mt-3 sm:mt-0"
                  onClick={handleNo}
                  disabled={isLoading}
                  ref={cancelButtonRef}
                >
                  {options.noLabel}
                </Button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

AlertModal.propTypes = {
  options: PropTypes.instanceOf(Object).isRequired,
  onClose: PropTypes.func.isRequired,
};

export const AlertMarker = () => {
  return <div id="alert-root" />;
};

export const showAlert = (options) => {
  const opt = {
    icon: false,
    title: "Confirmation",
    subTitle: "Are you sure you want to proceed?",
    yesLabel: "Proceed",
    onYes: (close) => close(),
    onNo: (close) => close(),
    noLabel: "Cancel",
    color: "danger",
    ...options,
  };
  const root = document.getElementById("alert-root");
  const handleClose = () => {
    render(<AlertMarker />, root);
  };
  if (!root) {
    alert("Alert root not found!"); // eslint-disable-line
    return;
  }
  render(
    <Provider store={store}>
      <Router history={history}>
        <AlertModal options={opt} onClose={handleClose} />
      </Router>
    </Provider>,
    root
  );
};

const LightboxModal = ({ options, onClose }) => {
  const [open, setOpen] = React.useState(false);
  const cancelButtonRef = React.useRef(null);

  const [selected, setSelected] = React.useState({
    title: options.title,
    content: options.content,
    description: options.description,
  });

  const handlePrev = () => {
    const index = options.array.findIndex((x) => x.title === selected.title);
    const newValue =
      options.array[index - 1] || options.array[options.array.length - 1];
    setSelected(newValue);
  };
  const handleNext = () => {
    const index = options.array.findIndex((x) => x.title === selected.title);
    const newValue = options.array[index + 1] || options.array[0];
    setSelected(newValue);
  };

  React.useEffect(() => {
    setOpen(true);
  }, [setOpen]);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-y-auto z-50"
        initialFocus={cancelButtonRef}
        open
        onClose={handleClose}
      >
        <div className="min-h-screen pt-4 px-4 pb-20 text-center flex items-center justify-center sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={onClose}
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="absolute top-0 right-0 pt-2 pr-2 flex z-50">
            <button
              className="rounded-full text-white hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-white transition bg-opacity-0 hover:bg-opacity-100 hover:bg-white p-3"
              onClick={handleClose}
              type="button"
            >
              <span className="sr-only">Close</span>
              <XIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-gray-900 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-7xl w-full">
              {typeof options.onDownload === "function" ? (
                <div className="right-4 top-4 z-10 absolute">
                  <div className="relative flex flex-col group">
                    <button
                      type="submit"
                      className="mx-auto p-3 shadow-sm rounded-full text-white bg-gray-700 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 animate-scale"
                      onClick={() => options.onDownload(selected)}
                    >
                      <HiOutlineDownload className="h-6 w-6 full-current" />
                    </button>
                    <span className="text-white mt-2 text-xs opacity-0 group-hover:opacity-100 transition">
                      Download
                    </span>
                  </div>
                </div>
              ) : null}
              {Array.isArray(options.array) && options.array.length > 1 ? (
                <>
                  <button
                    className="-mt-10 top-1/2 left-0 opacity-50 hover:opacity-100 hover:bg-white bg-opacity-0 hover:bg-opacity-10 absolute z-10 p-3 rounded-r-xl animate-scale"
                    type="button"
                    onClick={handlePrev}
                  >
                    <BsChevronLeft className="w-10 h-10 text-white" />
                  </button>
                  <button
                    className="-mt-10 top-1/2 right-0 opacity-50 hover:opacity-100 hover:bg-white bg-opacity-0 hover:bg-opacity-10 absolute z-10 p-3 rounded-l-xl animate-scale"
                    type="button"
                    onClick={handleNext}
                  >
                    <BsChevronRight className="w-10 h-10 text-white" />
                  </button>
                </>
              ) : null}
              <div className="aspect-h-4 aspect-w-3 sm:aspect-w-3 sm:aspect-h-2">
                <img
                  className="object-scale-down"
                  src={selected.content}
                  alt={selected.title}
                />
              </div>
              <div className="text-white absolute bottom-0 left-0 w-full p-6 bg-gray-800">
                <div className="flex justify-between items-center">
                  <div className="truncate">
                    <div className="font-bold text-lg">{selected.title}</div>
                    <div>{selected.description}</div>
                  </div>
                  {Array.isArray(options.array) && options.array.length > 1 ? (
                    <div className="flex space-x-4 items-center">
                      {options.array.map((row) => {
                        const isSelected = selected.title === row.title;
                        return (
                          <div // eslint-disable-line
                            className={cn(
                              "hover:opacity-100 cursor-pointer transition",
                              {
                                "opacity-30": !isSelected,
                                "opacity-100": isSelected,
                              }
                            )}
                            key={row.title}
                            onClick={() => setSelected(row)}
                          >
                            <img
                              src={row.content}
                              className="w-10 h-10 rounded-md overflow-hidden object-cover pointer-events-none transition ease-in-out duration-300 transform group-hover:scale-110 scale-100"
                              alt=""
                            />
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

LightboxModal.propTypes = {
  options: PropTypes.instanceOf(Object).isRequired,
  onClose: PropTypes.func.isRequired,
};

export const LightboxMarker = () => {
  return <div id="lightbox-root" />;
};

export const showLightbox = (options) => {
  const opt = {
    title: "Image",
    description: "",
    content: "",
    onDownload: null,
    array: [],
    ...options,
  };
  const root = document.getElementById("lightbox-root");
  const handleClose = () => {
    render(<LightboxMarker />, root);
  };
  if (!root) {
    alert("Lightbox root not found!"); // eslint-disable-line
    return;
  }
  render(
    <Provider store={store}>
      <Router history={history}>
        <LightboxModal options={opt} onClose={handleClose} />
      </Router>
    </Provider>,
    root
  );
};
