import { useEffect, useCallback } from "react";
import { req, useApiGet, useApiLoading } from "react-reqq-lite";
import * as actions from "./actions";
import * as c from "./constants";

export const useGetCurrentRoom = () => {
  const isLoading = useApiLoading(c.HANDLER_CURRENT_ROOM, "get");
  const data = useApiGet(c.HANDLER_CURRENT_ROOM, {});
  useEffect(() => {
    actions.getCurrentRoom();
  }, []);
  return [isLoading, data];
};

export const useHandlerList = (params, roomId) => {
  const isLoading = useApiLoading(c.HANDLER_LIST, "get");
  const data = useApiGet(c.HANDLER_LIST, []);
  const pager = useApiGet(c.HANDLER_PAGER, {});
  useEffect(() => {
    actions.listHandler(params, roomId);
  }, [params, roomId]);
  return [isLoading, data, pager];
};

export const useHandlerLiveGame = (params) => {
  const isLoading = useApiLoading(c.HANDLER_LIVE_GAME, "get");
  const data = useApiGet(c.HANDLER_LIVE_GAME, []);
  useEffect(() => {
    actions.getLiveGame(params);
  }, [params]);
  return [isLoading, data];
};

export const useHandlerSelected = () => {
  const isLoading = useApiLoading(c.HANDLER_ITEM, "get");
  const data = useApiGet(c.HANDLER_ITEM, {});
  return [isLoading, data];
};

export const useSelectHandler = (roomId, id) => {
  useEffect(() => {
    if (!id) {
      req.set(c.HANDLER_ITEM, null);
      return;
    }
    actions.selectHandler(roomId, id);
  }, [id]);
};

export const useCreateHandler = () => {
  const isLoading = useApiLoading(c.HANDLER_ITEM, "post");
  const submit = useCallback((payload, roomId, onSuccess) => {
    actions.createHandler(payload, roomId, onSuccess);
  }, []);
  return [isLoading, submit];
};

export const useUpdateHandler = () => {
  const isLoading = useApiLoading(c.HANDLER_ITEM, "put");
  const submit = useCallback((id, roomId, payload, onSuccess) => {
    actions.updateHandler(id, roomId, payload, onSuccess);
  }, []);
  return [isLoading, submit];
};

export const useUpdateChangeState = () => {
  const isLoading = useApiLoading(c.HANDLER_CHANGE_STATE, "put");
  const submit = useCallback((sessionId, roomId, onSuccess) => {
    actions.changeState(sessionId, roomId, onSuccess);
  }, []);
  return [isLoading, submit];
};

export const useUpdateChangeBet = () => {
  const isLoading = useApiLoading(c.HANDLER_CHANGE_BET, "put");
  const submit = useCallback((sessionId, roomId, payload, onSuccess) => {
    actions.changeBet(sessionId, roomId, payload, onSuccess);
  }, []);
  return [isLoading, submit];
};

export const useSetWinningHorse = () => {
  const isLoading = useApiLoading(c.SET_WINNING_HORSE, "put");
  const submit = useCallback((sessionId, roomId, payload, onSuccess) => {
    actions.setWinningHorse(sessionId, roomId, payload, onSuccess);
  }, []);
  return [isLoading, submit];
};

export const useScratchNormal = () => {
  const isLoading = useApiLoading(c.SCRATCH_NORMAL, "put");
  const submit = useCallback((sessionId, roomId, payload, onSuccess) => {
    actions.scratchNormal(sessionId, roomId, payload, onSuccess);
  }, []);
  return [isLoading, submit];
};

export const useScratchExotic = () => {
  const isLoading = useApiLoading(c.SCRATCH_EXOTIC, "put");
  const submit = useCallback((sessionId, roomId, payload, onSuccess) => {
    actions.scratchExotic(sessionId, roomId, payload, onSuccess);
  }, []);
  return [isLoading, submit];
};
