export const transformList = (raw) => {
  return {
    id: `${raw?.id}`,
    name: `${raw?.attributes?.name || ""}`,
    full_name: raw?.attributes?.handler_full_name || "",
    maximum_amount: raw?.attributes?.maximum_amount || "",
    minimum_amount: raw?.attributes?.user?.minimum_amount || "",
    status: raw?.attributes?.is_active || "",
  };
};
