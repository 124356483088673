export const transformList = (raw) => {
  return {
    id: `${raw?.id}`,
    name: `${raw?.attributes?.cashout_from_user_profile?.first_name || ""} ${
      raw?.attributes?.cashout_from_user_profile?.last_name || ""
    }`,
    first_name: raw?.attributes?.cashout_from_user_profile?.first_name || "",
    last_name: raw?.attributes?.cashout_from_user_profile?.last_name || "",
    email: raw?.attributes?.cashout_from_user?.email || "",
    phone_number:
      raw?.attributes?.cashout_from_user_profile?.phone_number || "",
    amount: raw?.attributes?.amount || "",
    status: raw?.attributes?.status || "",
  };
};
