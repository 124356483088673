import { req } from "react-reqq-lite";
import { toastSuccess } from "react-geek-toast";
import {
  FILTER,
  ITEM,
  LIST,
  PAGER,
  ADD_CREDIT_FUND_PLAYER,
  ADD_DEBIT_FUND_PLAYER,
  PLAYER_WALLET_LIST,
  PLAYER_WALLET_PAGER,
} from "./constants";
import { transformList, transformWalletList } from "./transformers";

export const listPlayer = (params) => {
  req.get({
    key: LIST,
    url: `/api/admin/players`,
    params: params,
    transform: (res) => (res.data || []).map(transformList),
    onSuccess: (res) => {
      req.set(PAGER, res?.response?.meta?.pagination || {});
      req.set(FILTER, params);
    },
  });
};

export const selectPlayer = (id) => {
  req.get({
    key: ITEM,
    url: `/api/admin/players/${id}`,
    onSuccess: (res) => {
      req.set(ITEM, res?.response?.data || {});
    },
  });
};

export const listPlayerWallet = (adminId, params) => {
  req.get({
    key: PLAYER_WALLET_LIST,
    params: params,
    url: `/api/admin/players/${adminId}/transactions`,
    transform: (res) => (res?.data || []).map(transformWalletList),
    onSuccess: (res) => {
      req.set(PLAYER_WALLET_PAGER, res?.response?.meta?.pagination || {});
    },
  });
};

export const addCreditFundPlayer = (playerId, payload, onSuccess) => {
  req.post({
    key: ADD_CREDIT_FUND_PLAYER,
    url: `/api/admin/players/${playerId}/wallet/credit`,
    payload,
    onSuccess: (res, state) => {
      toastSuccess("Successfully Added Credit!");
      setTimeout(() => {
        listPlayerWallet(playerId);
      }, 1000);
      if (onSuccess) onSuccess(transformList(res?.response?.data));
    },
  });
};

export const addDebitFundPlayer = (playerId, payload, onSuccess) => {
  req.post({
    key: ADD_DEBIT_FUND_PLAYER,
    url: `/api/admin/players/${playerId}/wallet/debit`,
    payload,
    onSuccess: (res, state) => {
      toastSuccess("Successfully Added Debit!");
      setTimeout(() => {
        listPlayerWallet(playerId);
      }, 1000);
      if (onSuccess) onSuccess(transformList(res?.response?.data));
    },
  });
};
