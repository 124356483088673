import React, { Fragment } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { Menu, Transition } from "@headlessui/react";
import { DotsVerticalIcon } from "@heroicons/react/outline";

function CardMenu({ className, data, options }) {
  const handleClick = ({ onClick, ...rest }) => (e) => {
    e.preventDefault();
    if (onClick) onClick(rest, data);
  };
  return (
    <Menu as="div" className={cn("relative flex-shrink-0 pr-2", className)}>
      {({ open }) => (
        <>
          <Menu.Button className="w-7 h-7 bg-white inline-flex items-center justify-center text-gray-400 rounded-full bg-transparent hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
            <span className="sr-only">Open options</span>
            <DotsVerticalIcon className="w-5 h-5" aria-hidden="true" />
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
            show={open}
          >
            <Menu.Items
              static
              className="z-10 mx-3 absolute origin-top-right right-0 w-48 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none"
            >
              <div className="py-1">
                {options.map((item) => (
                  <Menu.Item key={item.id}>
                    {({ active }) => (
                      <a
                        href="/"
                        onClick={handleClick(item)}
                        className={cn(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block px-4 py-2 text-sm"
                        )}
                      >
                        {item.label}
                      </a>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
}

CardMenu.defaultProps = {
  data: {},
  className: "",
};

CardMenu.propTypes = {
  className: PropTypes.string,
  data: PropTypes.instanceOf(Object),
  options: PropTypes.instanceOf(Array).isRequired,
};

function Card({ label, actionOptions, renderAction, children }) {
  return (
    <div className="bg-white border rounded-lg">
      <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
        <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
          <div className="ml-4 mt-2 flex items-center">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {label}
            </h3>
            {Array.isArray(actionOptions) && actionOptions.length > 1 && (
              <CardMenu options={actionOptions} />
            )}
          </div>
          {renderAction && (
            <div className="ml-4 mt-2 flex-shrink-0">{renderAction}</div>
          )}
        </div>
      </div>
      {children}
    </div>
  );
}

Card.defaultProps = {
  actionOptions: null,
  renderAction: null,
  children: false,
};

Card.propTypes = {
  renderAction: PropTypes.instanceOf(Object),
  children: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.string,
    PropTypes.number,
  ]),
  actionOptions: PropTypes.oneOfType([
    PropTypes.instanceOf(Array),
    PropTypes.instanceOf(Object),
  ]),
  label: PropTypes.string.isRequired,
};

export { CardMenu };

export default Card;
