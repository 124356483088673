import React from "react";
import Cookie from "js-cookie";
import { req, useApiGet } from "react-reqq-lite";
import { showDrawer } from "partial/components/Modal";
import OverrideModal from "./modals/OverrideModal";
import * as c from "./constants";

export const useAuth = () => {
  const auth = useApiGet(c.AUTHENTICATION, {});
  React.useEffect(() => {
    const token = Cookie.get("_token");
    const userType = Cookie.get("_userType");
    const profile = Cookie.get("_profile");

    req.set(c.AUTHENTICATION, {
      isAuthenticated: !!token,
      userType: userType,
      profile: profile,
    });
  }, []);
  return [
    typeof auth.isAuthenticated === "boolean",
    auth.isAuthenticated
  ];
};

export const withOverride = (func) => {
  showDrawer({
    title: "Override",
    className: "modal-sm",
    content: (close) => <OverrideModal onSubmit={func} onClose={close} />,
  });
};
