import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

const SIZE = {
  xs: "px-2.5 py-1.5 text-xs rounded",
  sm: "px-3 py-2 text-sm leading-4 rounded-md",
  default: "px-4 py-2 text-sm rounded-md",
  lg: "px-4 py-2 text-base rounded-md",
  xl: "px-6 py-3 text-base rounded-md",
};

const ICON_SIZE = {
  xs: "-ml-0.5 h-3 w-3",
  sm: "-ml-1 h-4 w-4",
  default: "-ml-1 h-4 w-4",
  lg: "-ml-1 h-5 w-5",
  xl: "-ml-1 h-5 w-5",
};

const COLOR = {
  primary:
    "transition border-transparent text-white bg-gradient-to-br from-primary-400 to-primary-500 hover:bg-primary-400 focus:ring-primary-500 shadow-primary-lg",
  success:
    "transition border-transparent text-white bg-gradient-to-br from-green-400 to-green-500 hover:bg-green-400 focus:ring-green-500 shadow-green-lg",
  secondary:
    "transition border-transparent text-primary-500 bg-primary-50 hover:bg-primary-100 hover:text-primary-800 focus:ring-primary-500",
  danger:
    "transition border-transparent text-danger-700 bg-danger-50 hover:bg-danger-300 focus:ring-danger-500 shadow-danger-lg",
  default:
    "transition text-gray-700 bg-white hover:bg-gray-50 focus:ring-primary-500",
};

const Button = React.forwardRef(
  (
    {
      size,
      primary,
      success,
      secondary,
      danger,
      className,
      children,
      icon: Icon,
      ...props
    },
    ref
  ) => {
    const colorClass = React.useMemo(() => {
      if (primary) return COLOR.primary;
      if (success) return COLOR.success;
      if (secondary) return COLOR.secondary;
      if (danger) return COLOR.danger;
      return COLOR.default;
    }, [primary, success, secondary, danger]);
    return (
      <button
        ref={ref}
        className={cn(
          "items-center border font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 space-x-2 flex justify-center",
          "focus:z-10 relative",
          className,
          SIZE[size],
          colorClass
        )}
        type="button"
        {...props}
      >
        {Icon && (
          <Icon
            className={cn("opacity-80", ICON_SIZE[size])}
            aria-hidden="true"
          />
        )}
        {children && <span>{children}</span>}
      </button>
    );
  }
);

Button.defaultProps = {
  className: "",
  size: "default",
  primary: false,
  success: false,
  secondary: false,
  danger: false,
  icon: null,
  children: null,
};

Button.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(["xs", "sm", "default", "lg", "xl"]),
  primary: PropTypes.bool,
  success: PropTypes.bool,
  secondary: PropTypes.bool,
  danger: PropTypes.bool,
  icon: PropTypes.instanceOf(Object),
  children: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default Button;
