import React from "react";
import PropTypes from "prop-types";
import Form from "partial/form/Form";
import Button from "partial/components/Button";
import { showDrawer } from "partial/components/Modal";
import { useUpdateAgent } from "../hooks";
import { transformList } from "../transformers";
import FormSelect from "partial/form/FormSelect";
import _ from "lodash";
import Cookie from "js-cookie";

export const openAgentModal = (title, data, onSuccess) => {
  showDrawer({
    size: "xs",
    title,
    content: (onClose) => (
      <AgentModal data={data} onClose={onClose} onSuccess={onSuccess} />
    ),
  });
};

function AgentModal({ data, onClose, onSuccess }) {
  const [form, setForm] = React.useState(data);
  const [isUpdate, update] = useUpdateAgent();
  const handleSubmit = () => {
    update(data?.id, form, onClose);
  };

  const [percentageOptions, setPercentageOptions] = React.useState([]);
  const profile = JSON.parse(Cookie.get("_user")) || {};

  React.useEffect(() => {

    if (profile.percentage_config === null || profile.percentage_config === undefined) {
      setPercentageOptions([
        { label: "Select Percentage", value: "" },
        { label: "1", value: "1" },
        { label: "1.5", value: "1.5" },
        { label: "2", value: "2" },
        { label: "2.5", value: "2.5" },
        { label: "3", value: "3" },
        { label: "3.5", value: "3.5" },
        { label: "4", value: "4" },
        { label: "4.5", value: "4.5" },
        { label: "5", value: "5" },
        { label: "5.5", value: "5.5" },
        { label: "6", value: "6" },
        { label: "6.5", value: "6.5" },
        { label: "7", value: "7" },
        { label: "7.5", value: "7.5" },
        { label: "8", value: "8" },
        { label: "8.5", value: "8.5" },
        { label: "9", value: "9" },
        { label: "9.5", value: "9.5" },
        { label: "10", value: "10" },
      ])
    }

    if (profile.percentage_config !== null) {


      if (+profile.percentage_config.percentage === 10) {
        setPercentageOptions([
          { label: "Select Percentage", value: "" },
          { label: "1", value: "1" },
          { label: "1.5", value: "1.5" },
          { label: "2", value: "2" },
          { label: "2.5", value: "2.5" },
          { label: "3", value: "3" },
          { label: "3.5", value: "3.5" },
          { label: "4", value: "4" },
          { label: "4.5", value: "4.5" },
          { label: "5", value: "5" },
          { label: "5.5", value: "5.5" },
          { label: "6", value: "6" },
          { label: "6.5", value: "6.5" },
          { label: "7", value: "7" },
          { label: "7.5", value: "7.5" },
          { label: "8", value: "8" },
          { label: "8.5", value: "8.5" },
          { label: "9", value: "9" },
          { label: "9.5", value: "9.5" },
          { label: "10", value: "10" },
        ])
      }

      if (+profile.percentage_config.percentage === 9) {
        setPercentageOptions([
          { label: "Select Percentage", value: "" },
          { label: "1", value: "1" },
          { label: "1.5", value: "1.5" },
          { label: "2", value: "2" },
          { label: "2.5", value: "2.5" },
          { label: "3", value: "3" },
          { label: "3.5", value: "3.5" },
          { label: "4", value: "4" },
          { label: "4.5", value: "4.5" },
          { label: "5", value: "5" },
          { label: "5.5", value: "5.5" },
          { label: "6", value: "6" },
          { label: "6.5", value: "6.5" },
          { label: "7", value: "7" },
          { label: "7.5", value: "7.5" },
          { label: "8", value: "8" },
          { label: "8.5", value: "8.5" },
          { label: "9", value: "9" }
        ])
      }

      if (+profile.percentage_config.percentage === 9.5) {
        setPercentageOptions([
          { label: "Select Percentage", value: "" },
          { label: "1", value: "1" },
          { label: "1.5", value: "1.5" },
          { label: "2", value: "2" },
          { label: "2.5", value: "2.5" },
          { label: "3", value: "3" },
          { label: "3.5", value: "3.5" },
          { label: "4", value: "4" },
          { label: "4.5", value: "4.5" },
          { label: "5", value: "5" },
          { label: "5.5", value: "5.5" },
          { label: "6", value: "6" },
          { label: "6.5", value: "6.5" },
          { label: "7", value: "7" },
          { label: "7.5", value: "7.5" },
          { label: "8", value: "8" },
          { label: "8.5", value: "8.5" },
          { label: "9", value: "9" },
          { label: "9.5", value: "9.5" }
        ])
      }

      if (+profile.percentage_config.percentage === 8) {
        setPercentageOptions([
          { label: "Select Percentage", value: "" },
          { label: "1", value: "1" },
          { label: "1.5", value: "1.5" },
          { label: "2", value: "2" },
          { label: "2.5", value: "2.5" },
          { label: "3", value: "3" },
          { label: "3.5", value: "3.5" },
          { label: "4", value: "4" },
          { label: "4.5", value: "4.5" },
          { label: "5", value: "5" },
          { label: "5.5", value: "5.5" },
          { label: "6", value: "6" },
          { label: "6.5", value: "6.5" },
          { label: "7", value: "7" },
          { label: "7.5", value: "7.5" },
          { label: "8", value: "8" }
        ])
      }

      if (+profile.percentage_config.percentage === 8.5) {
        setPercentageOptions([
          { label: "Select Percentage", value: "" },
          { label: "1", value: "1" },
          { label: "1.5", value: "1.5" },
          { label: "2", value: "2" },
          { label: "2.5", value: "2.5" },
          { label: "3", value: "3" },
          { label: "3.5", value: "3.5" },
          { label: "4", value: "4" },
          { label: "4.5", value: "4.5" },
          { label: "5", value: "5" },
          { label: "5.5", value: "5.5" },
          { label: "6", value: "6" },
          { label: "6.5", value: "6.5" },
          { label: "7", value: "7" },
          { label: "7.5", value: "7.5" },
          { label: "8", value: "8" },
          { label: "8.5", value: "8.5" }
        ])
      }

      if (+profile.percentage_config.percentage === 7) {
        setPercentageOptions([
          { label: "Select Percentage", value: "" },
          { label: "1", value: "1" },
          { label: "1.5", value: "1.5" },
          { label: "2", value: "2" },
          { label: "2.5", value: "2.5" },
          { label: "3", value: "3" },
          { label: "3.5", value: "3.5" },
          { label: "4", value: "4" },
          { label: "4.5", value: "4.5" },
          { label: "5", value: "5" },
          { label: "5.5", value: "5.5" },
          { label: "6", value: "6" },
          { label: "6.5", value: "6.5" },
          { label: "7", value: "7" },
        ])
      }

      if (+profile.percentage_config.percentage === 7.5) {
        setPercentageOptions([
          { label: "Select Percentage", value: "" },
          { label: "1", value: "1" },
          { label: "1.5", value: "1.5" },
          { label: "2", value: "2" },
          { label: "2.5", value: "2.5" },
          { label: "3", value: "3" },
          { label: "3.5", value: "3.5" },
          { label: "4", value: "4" },
          { label: "4.5", value: "4.5" },
          { label: "5", value: "5" },
          { label: "5.5", value: "5.5" },
          { label: "6", value: "6" },
          { label: "6.5", value: "6.5" },
          { label: "7", value: "7" },
          { label: "7.5", value: "7.5" }
        ])
      }

      if (+profile.percentage_config.percentage === 6) {
        setPercentageOptions([
          { label: "Select Percentage", value: "" },
          { label: "1", value: "1" },
          { label: "1.5", value: "1.5" },
          { label: "2", value: "2" },
          { label: "2.5", value: "2.5" },
          { label: "3", value: "3" },
          { label: "3.5", value: "3.5" },
          { label: "4", value: "4" },
          { label: "4.5", value: "4.5" },
          { label: "5", value: "5" },
          { label: "5.5", value: "5.5" },
          { label: "6", value: "6" }
        ])
      }

      if (+profile.percentage_config.percentage === 6.5) {
        setPercentageOptions([
          { label: "Select Percentage", value: "" },
          { label: "1", value: "1" },
          { label: "1.5", value: "1.5" },
          { label: "2", value: "2" },
          { label: "2.5", value: "2.5" },
          { label: "3", value: "3" },
          { label: "3.5", value: "3.5" },
          { label: "4", value: "4" },
          { label: "4.5", value: "4.5" },
          { label: "5", value: "5" },
          { label: "5.5", value: "5.5" },
          { label: "6", value: "6" },
          { label: "6.5", value: "6.5" }
        ])
      }

      if (+profile.percentage_config.percentage === 5) {
        setPercentageOptions([
          { label: "Select Percentage", value: "" },
          { label: "1", value: "1" },
          { label: "1.5", value: "1.5" },
          { label: "2", value: "2" },
          { label: "2.5", value: "2.5" },
          { label: "3", value: "3" },
          { label: "3.5", value: "3.5" },
          { label: "4", value: "4" },
          { label: "4.5", value: "4.5" },
          { label: "5", value: "5" },
        ])
      }

      if (+profile.percentage_config.percentage === 5.5) {
        setPercentageOptions([
          { label: "Select Percentage", value: "" },
          { label: "1", value: "1" },
          { label: "1.5", value: "1.5" },
          { label: "2", value: "2" },
          { label: "2.5", value: "2.5" },
          { label: "3", value: "3" },
          { label: "3.5", value: "3.5" },
          { label: "4", value: "4" },
          { label: "4.5", value: "4.5" },
          { label: "5", value: "5" },
          { label: "5.5", value: "5.5" }
        ])
      }

      if (+profile.percentage_config.percentage === 4) {
        setPercentageOptions([
          { label: "Select Percentage", value: "" },
          { label: "1", value: "1" },
          { label: "1.5", value: "1.5" },
          { label: "2", value: "2" },
          { label: "2.5", value: "2.5" },
          { label: "3", value: "3" },
          { label: "3.5", value: "3.5" },
          { label: "4", value: "4" }
        ])
      }

      if (+profile.percentage_config.percentage === 4.5) {
        setPercentageOptions([
          { label: "Select Percentage", value: "" },
          { label: "1", value: "1" },
          { label: "1.5", value: "1.5" },
          { label: "2", value: "2" },
          { label: "2.5", value: "2.5" },
          { label: "3", value: "3" },
          { label: "3.5", value: "3.5" },
          { label: "4", value: "4" },
          { label: "4.5", value: "4.5" }
        ])
      }

      if (+profile.percentage_config.percentage === 3) {
        setPercentageOptions([
          { label: "Select Percentage", value: "" },
          { label: "1", value: "1" },
          { label: "1.5", value: "1.5" },
          { label: "2", value: "2" },
          { label: "2.5", value: "2.5" },
          { label: "3", value: "3" }
        ])
      }

      if (+profile.percentage_config.percentage === 3.5) {
        setPercentageOptions([
          { label: "Select Percentage", value: "" },
          { label: "1", value: "1" },
          { label: "1.5", value: "1.5" },
          { label: "2", value: "2" },
          { label: "2.5", value: "2.5" },
          { label: "3", value: "3" },
          { label: "3.5", value: "3.5" }
        ])
      }

      if (+profile.percentage_config.percentage === 2) {
        setPercentageOptions([
          { label: "Select Percentage", value: "" },
          { label: "1", value: "1" },
          { label: "1.5", value: "1.5" },
          { label: "2", value: "2" }
        ])
      }

      if (+profile.percentage_config.percentage === 2.5) {
        setPercentageOptions([
          { label: "Select Percentage", value: "" },
          { label: "1", value: "1" },
          { label: "1.5", value: "1.5" },
          { label: "2", value: "2" },
          { label: "2.5", value: "2.5" }
        ])
      }

      if (+profile.percentage_config.percentage === 1) {
        setPercentageOptions([
          { label: "Select Percentage", value: "" },
          { label: "1", value: "1" },
        ])
      }

      if (+profile.percentage_config.percentage === 1.5) {
        setPercentageOptions([
          { label: "Select Percentage", value: "" },
          { label: "1", value: "1" },
          { label: "1.5", value: "1.5" },
        ])
      }
    }
  }, []);

  return (
    <Form className="divide-y divide-gray-200" onSubmit={handleSubmit}>
      <div>
        <div className="mb-3">
          <FormSelect
            name="percentage"
            label="Percentage"
            id="percentage"
            onChange={setForm}
            options={percentageOptions}
            value={_.get(form, "percentage") || ""}
            required
          />
        </div>
      </div>
      <div className="pt-5">
        <div className="flex justify-end">
          <Button type="submit" primary size="lg" disabled={isUpdate}>
            Update
          </Button>
        </div>
      </div>
    </Form>
  );
}

AgentModal.defaultProps = {
  data: transformList(),
  onSuccess: false,
};

AgentModal.propTypes = {
  data: PropTypes.instanceOf(Object),
  onSuccess: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onClose: PropTypes.func.isRequired,
};

export default AgentModal;
