import React, { useState } from "react";
import Button from "partial/components/Button";
import { HiOutlineMinus, HiOutlinePlus } from "react-icons/hi";
import { usePlayerWalletList } from "modules/master-agent/agents/hooks";
import { openPlayerWalletModal } from "../modals/PlayerWalletModal";
import { RiWalletFill } from "react-icons/ri";
import _ from "lodash";
import Pagination from "partial/components/Pagination";

function PlayerWalletContainer({ agentId, playerId }) {
  const [balance, setBalance] = useState(0);
  const [filter, setFilter] = React.useState({
    page: 1,
  });
  const [, list, pager] = usePlayerWalletList(agentId, playerId, filter);

  React.useEffect(() => {
    if (list.length > 0) {
      setBalance(_.get(list[0], "attributes.balance"));
    }
  }, [list]);

  const handleAddCredit = () => (e) => {
    e.preventDefault();
    openPlayerWalletModal("Add Credit", agentId, playerId, "CREDIT");
  };

  const handleAddDebit = () => (e) => {
    e.preventDefault();
    openPlayerWalletModal("Add Debit", agentId, playerId, "DEBIT");
  };

  return (
    <div>
      <div className="mt-4 flex flex-col">
        <div className="flex items-center border px-4 py-3 bg-white shadow-sm mb-2">
          <div className="flex items-center space-x-2">
            <span className="bg-green-50 rounded-full p-3">
              <RiWalletFill className="w-6 h-6 flex-shrink-0 text-green-500" />
            </span>
            <div className="sm:flex-auto">
              <p className="text-sm text-gray-700">Balance</p>
              <p className="text-lg text-green-500 font-semibold flex items-center space-x-1">
                <span>{balance}</span>
                <span className="text-xs text-gray-400">PHP</span>
              </p>
            </div>
          </div>
        </div>
        <div className="space-x-2 text-gray-600 flex items-center mb-2">
          <Button type="button" primary onClick={handleAddCredit()}>
            <HiOutlinePlus className="w-3 h-3" />
          </Button>
          <Button type="button" danger onClick={handleAddDebit()}>
            <HiOutlineMinus className="w-3 h-3" />
          </Button>
        </div>
      </div>
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8 mt-2">
        <div className="flex items-center border px-4 py-3 bg-white shadow-sm mb-2">
          <div className="flex items-center space-x-2">
            <div className="sm:flex-auto">
              <p className="text-sm text-gray-700">Wallet History</p>
            </div>
          </div>
        </div>
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="overflow-hidden">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col">
                    <span className="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider uppercase flex items-center space-x-2">
                      Transaction
                    </span>
                  </th>
                  <th scope="col">
                    <span className="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider uppercase flex items-center space-x-2">
                      Amount
                    </span>
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">Action</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {list.map((row) => (
                  <tr key={row.id}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base font-medium text-gray-900 sm:pl-6">
                      {row.attributes.type}
                    </td>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base font-medium text-gray-900 sm:pl-6">
                      {row.attributes.amount}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Pagination onChange={setFilter} value={pager} />
    </div>
  );
}

export default PlayerWalletContainer;
