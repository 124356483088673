import React from "react";
import PropTypes from "prop-types";
import Form from "partial/form/Form";
import FormInput from "partial/form/FormInput";
import Button from "partial/components/Button";
import { showDrawer } from "partial/components/Modal";
import { useScratchExotic } from "../hooks";
import _ from "lodash";
import FormSelect from "partial/form/FormSelect";
import { BET_TYPES_EXOTIC } from "helper";

export const openScratchExoticModal = (title, roomId, sessionId, onSuccess) => {
  showDrawer({
    size: "xs",
    title,
    content: (onClose) => (
      <ScratchExoticModal
        onClose={onClose}
        onSuccess={onSuccess}
        roomId={roomId}
        sessionId={sessionId}
      />
    ),
  });
};

function ScratchExoticModal({ roomId, sessionId, onClose, onSuccess }) {
  const [form, setForm] = React.useState({
    scratch_horse: "",
    bet_type: "",
    new_horse: "",
    scratch_horse_round: "",
  });
  const [isUpdate, update] = useScratchExotic();
  const handleSubmit = () => {
    update(roomId, sessionId, form, onClose);
  };

  return (
    <Form className="divide-y divide-gray-200" onSubmit={handleSubmit}>
      <div className="mb-2">
        <FormSelect
          name="bet_type"
          label="Type of Bet"
          id="bet_type"
          onChange={setForm}
          options={BET_TYPES_EXOTIC}
          value={_.get(form, "bet_type") || ""}
          required
        />
      </div>
      <div className="mb-3">
        <FormInput
          type="number"
          name="scratch_horse"
          onChange={setForm}
          value={form.scratch_horse}
          label="Scratch Horse"
          required
        />
      </div>
      <div className="mb-3">
        <FormInput
          type="number"
          name="new_horse"
          onChange={setForm}
          value={form.new_horse}
          label="New Horse"
          required
        />
      </div>
      <div className="mb-3">
        <FormInput
          type="number"
          name="scratch_horse_round"
          onChange={setForm}
          value={form.scratch_horse_round}
          label="Scratch Horse Round"
          required
        />
      </div>
      <div className="pt-5">
        <div className="flex justify-end">
          <Button type="submit" primary size="lg" disabled={isUpdate}>
            Save
          </Button>
        </div>
      </div>
    </Form>
  );
}

ScratchExoticModal.defaultProps = {
  roomId: "",
  sessionId: "",
  onSuccess: false,
};

ScratchExoticModal.propTypes = {
  roomId: PropTypes.number,
  sessionId: PropTypes.string,
  onSuccess: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onClose: PropTypes.func.isRequired,
};

export default ScratchExoticModal;
