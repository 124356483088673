export const LIST = "MASTER_AGENT/list";
export const PAGER = "MASTER_AGENT/pager";
export const ITEM = "MASTER_AGENT/item";
export const FILTER = "MASTER_AGENT/filter";

export const MASTER_AGENT_WALLET_LIST = "MASTER_AGENT/wallet_list";
export const MASTER_AGENT_WALLET_PAGER = "MASTER_AGENT/wallet_pager";

export const ADD_CREDIT_FUND_MASTER_AGENT = "MASTER_AGENT/add_credit_agent";
export const ADD_DEBIT_FUND_MASTER_AGENT = "MASTER_AGENT/add_debit_agent";

export const CHANGE_STATUS_MASTER_AGENT = "MASTER_AGENT/change_status_agent";
