export const transformList = (raw) => ({
  id: raw?.id || "",
  name: `${raw?.attributes?.first_name || "Unknown"} ${
    raw?.attributes?.last_name || ""
  }`,
  first_name: raw?.attributes?.first_name || "",
  last_name: raw?.attributes?.last_name || "",
  phone_number: raw?.attributes?.phone_number || "",
  email: raw?.attributes?.user?.email || "",
  status: raw?.attributes?.user?.is_active || "",
});

export const transformPager = (raw) => ({
  current_page: raw?.current_page || 1,
  total_pages: raw?.total_pages || 1,
  total: raw?.total || 0,
});
