import React from "react";
import PropTypes from "prop-types";
import { useApproveCashout } from "../hooks";
import { showAlert } from "partial/components/Modal";
import { HiOutlineEye } from "react-icons/hi";
import Button from "partial/components/Button";

function WithdrawRequestContent({ data }) {
  const addCommas = (num) =>
    num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const [, approveCashout] = useApproveCashout();

  const handleOnApproveCashout = (row) => (e) => {
    e.preventDefault();
    showAlert({
      title: "Approve Cashout?",
      yesLabel: "Approve Cashout?",
      onYes: (closeAlert) => {
        closeAlert();
        approveCashout(row.id);
      },
    });
  };

  return (
    <>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto" />
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          {data.status === "PENDING" && (
            <Button
              type="button"
              default
              icon={HiOutlineEye}
              onClick={handleOnApproveCashout(data)}
            >
              <span>Approve Cashout</span>
            </Button>
          )}
        </div>
      </div>
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">First Name</dt>
          <dd className="mt-1 text-base text-gray-900 font-semibold">
            {data.first_name}
          </dd>
        </div>

        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Last Name</dt>
          <dd className="mt-1 text-base text-gray-900 font-semibold">
            {data.last_name}
          </dd>
        </div>

        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Contact Number</dt>
          <dd className="mt-1 text-base text-gray-900 font-semibold">
            {data.phone_number}
          </dd>
        </div>

        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Email Address</dt>
          <dd className="mt-1 text-base text-gray-900 font-semibold">
            {data.email}
          </dd>
        </div>

        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Amount</dt>
          <dd className="mt-1 text-base text-gray-900 font-semibold">
            {addCommas(data.amount)}
          </dd>
        </div>

        <div className="sm:col-span-3">
          <dt className="text-sm font-medium text-gray-500">Status</dt>
          <dd className="mt-1 text-sm text-gray-900">{data.status}</dd>
        </div>
      </dl>
    </>
  );
}

WithdrawRequestContent.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default WithdrawRequestContent;
