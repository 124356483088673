import React, { useState } from "react";
import Pagination from "partial/components/Pagination";
import { useCommissionList } from "../hooks";
import { RiWalletFill } from "react-icons/ri";

function CommissionContainer() {
  const [, list, pager] = useCommissionList();

  const addCommas = (num) =>
    num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const [balance, setBalance] = useState(0);

  React.useEffect(() => {
    if (list.length > 0) {
      setBalance(list[0].balance.replace(/\B(?=(\d{3})+(?!\d))/g, ","));
    }
  }, [list]);

  return (
    <div>
      <div className="flex items-center border px-4 py-3 bg-white shadow-sm">
        <div className="flex items-center space-x-2">
          <span className="bg-green-50 rounded-full p-3">
            <RiWalletFill className="w-6 h-6 flex-shrink-0 text-green-500" />
          </span>
          <div className="sm:flex-auto">
            <p className="text-sm text-gray-700">Master Wallet:</p>
            <p className="text-lg text-green-500 font-semibold flex items-center space-x-1">
              <span>{balance}</span>
              <span className="text-xs text-gray-400">PHP</span>
            </p>
          </div>
        </div>
      </div>
      <div className="m-4 bg-white p-4 rounded-lg shadow-sm flex flex-col">
        <h1 className="title-h2 mb-3">Commissions History</h1>
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col">
                      <span className="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider uppercase flex items-center space-x-2">
                        Transaction
                      </span>
                    </th>
                    <th scope="col">
                      <span className="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider uppercase flex items-center space-x-2">
                        Amount
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {list.map((row) => (
                    <tr key={row.type}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base font-medium text-gray-900 sm:pl-6">
                        {row.type}
                      </td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base font-medium text-gray-900 sm:pl-6">
                        {addCommas(row.amount)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <p className="m-4 mt-1 text-sm text-gray-600">
          Total of {pager?.total || 0} Commissions(s)
        </p>
        <Pagination value={pager} />
      </div>
    </div>
  );
}

export default CommissionContainer;
