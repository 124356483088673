import React from "react";
import FormInput from "partial/form/FormInputWithIcon";
import Button from "partial/components/Button";
import { useApiLoading } from "react-reqq-lite";
import history from "setup/history";
import Form from "partial/form/Form";
import ReactInputMask from "react-input-mask";
import logo from "../../../../assets/images/logo.png";
import bgRegister from "../../../../assets/images/register-bg.jpg";
import * as actions from "../actions";
import * as c from "../constants";
import {
  HiOutlineHome,
  HiOutlineMail,
  HiOutlineUserCircle,
} from "react-icons/hi";
import FormInputPassword from "partial/form/FormInputPassword";

function PlayerSignUpContainer() {
  const queryParams = new URLSearchParams(window.location.search);

  const referral_code = queryParams.get("referral_code");

  const [form, setForm] = React.useState({
    email: "",
    password: "",
    password_confirmation: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    referrer_code: referral_code,
    address: "",
  });

  const isLoading = useApiLoading(c.REGISTER, "post");
  const handleSubmit = () => {
    actions.register(form);
  };

  const error = {
    // email: 'Already taken',
    // password: 'Must be atleast 8 characters',
    // message: 'Invalid Credentials',
  };

  const handleChangeFields = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleOnClick = (e) => {
    e.preventDefault();
    history.push("/");
  };

  return (
    <Form onSubmit={handleSubmit} error={error}>
      <div className="min-h-screen flex relative">
        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 bg-white relative z-10">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img
                className="h-48 w-auto mx-auto"
                src={logo}
                alt="Karera Logo"
              />
              <h1 className="text-xl md:text-xl font-bold leading-tight mt-3">
                Register Player.
              </h1>
              <p className="text-base font-medium text-gray-700">
                Create an acount to as Player
              </p>
            </div>

            <div className="mt-8">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mt-2">
                <div className="mb-3">
                  <FormInput
                    name="last_name"
                    onChange={setForm}
                    value={form.last_name}
                    icon={HiOutlineUserCircle}
                    label="Last Name"
                    required
                  />
                </div>
                <div className="mb-3">
                  <FormInput
                    name="first_name"
                    onChange={setForm}
                    icon={HiOutlineUserCircle}
                    value={form.first_name}
                    label="First Name"
                    required
                  />
                </div>
              </div>
              <div className="mt-2">
                <div className="relative border rounded-md px-3 py-2 shadow-sm focus-within:ring-1 bg-white border-gray-300 focus-within:ring-primary-600 focus-within:border-primary-600">
                  <label
                    htmlFor="email"
                    className="block text-xs font-medium text-gray-900"
                  >
                    Mobile Number
                  </label>
                  <div>
                    <ReactInputMask
                      required
                      type="text"
                      mask="+639999999999"
                      maskChar=""
                      className="block w-full p-0 border-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-base focus:outline-none font-semibold"
                      name="phone_number"
                      value={form.phone_number}
                      onChange={handleChangeFields}
                      placeholder="+63xxxxxxxxxx"
                    />
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <FormInput
                  name="email"
                  type="email"
                  onChange={setForm}
                  icon={HiOutlineMail}
                  value={form.email}
                  label="Email"
                  required
                />
              </div>
              <div className="space-y-1 mt-3">
                <FormInputPassword
                  name="password"
                  onChange={setForm}
                  value={form.password}
                  label="Password"
                  required
                  withIcon={true}
                />
              </div>
              <div className="space-y-1 mt-3">
                <FormInputPassword
                  name="password_confirmation"
                  onChange={setForm}
                  value={form.password_confirmation}
                  label="Confirm Password"
                  required
                  withIcon={true}
                />
              </div>
              <div className="mt-3">
                <FormInput
                  name="address"
                  onChange={setForm}
                  icon={HiOutlineHome}
                  value={form.address}
                  label="Address"
                  required
                />
              </div>
              <div className="mt-3">
                <Button
                  disabled={isLoading}
                  onClick={handleSubmit}
                  size="xl"
                  primary
                  className="w-full"
                >
                  Register Now
                </Button>
              </div>
            </div>
            <div className="flex items-center justify-center space-x-1 mt-4">
              <span>Already have an account?</span>
              <button onClick={handleOnClick} className="btn-link">
                Sign in
              </button>
            </div>
          </div>
        </div>
        <div className="absolute inset-0">
          <img
            src={bgRegister}
            alt=""
            className="object-cover w-full h-full z-0"
          />
        </div>
      </div>
    </Form>
  );
}

PlayerSignUpContainer.propTypes = {};

export default PlayerSignUpContainer;
