import dayjs from "dayjs";
import { req } from "react-reqq-lite";
import { toastError } from "react-geek-toast";
import { useState, useEffect, useCallback } from "react";
import _, { each, isEmpty, omitBy, forOwn, get, debounce } from "lodash";

export const reqLoader = (constant, value, key = "get") => {
  req.set(`LOADING/${key}/${constant}`, value);
};

export const parseNumber = (str, default_value = false) => {
  const v = parseFloat(`${str}`.replace(/,/g, ""));
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(v)) return typeof default_value !== "boolean" ? default_value : str;
  return v;
};

export const formatNumber = (v, decimal = 2) => {
  try {
    const n = parseNumber(v);
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(n)) return v;
    return n.toLocaleString(undefined, {
      minimumFractionDigits: decimal,
      maximumFractionDigits: decimal,
    });
  } catch (err) {
    return v;
  }
};

export const formatDate = (date, format = "MM/DD/YYYY", defaultValue = "-") => {
  if (!date) return defaultValue;
  const d = new Date(date);
  if (d.toString() === "Invalid Date") return defaultValue;
  return dayjs(d).format(format);
};

export const removeEmpty = (obj) => omitBy(obj, (x) => isEmpty(`${x}`));

export const transformIncluded = (x, included) => {
  if (!included || isEmpty(included)) return x;
  const rowIncluded = {};
  forOwn(x.relationships, (v, k) => {
    rowIncluded[k] = Array.isArray(v.data)
      ? v.data.map(
          (z) =>
            included.find(
              (y) => y.type === get(z, "type") && y.id === get(z, "id")
            ) || {}
        )
      : included.find(
          (y) => y.type === get(v, "data.type") && y.id === get(v, "data.id")
        ) || {};
  });
  const { links, relationships, type, ...rest } = x;
  return { ...rest, included: rowIncluded };
};

export const getInitials = (str) => {
  const arr = (str || "SAFIFY").split(" ");
  if (arr.length > 1) {
    return `${arr[0][0]}${arr[1][0]}`.toUpperCase();
  }
  return `${arr[0][0]}${arr[0][1]}`.toUpperCase();
};

export const getInitialsThumb = (str) => {
  return `https://via.placeholder.com/64/ffe1b5/e6a13f?text=${getInitials(
    str
  )}`;
};

export const generate = (length = 10) => {
  const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let retVal = "";
  for (let i = 0, n = charset.length; i < length; i += 1) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
};

export const storage = {
  get: (key, defaultValue = false) => {
    try {
      return JSON.parse(sessionStorage.getItem(key));
    } catch (err) {
      return defaultValue;
    }
  },
  set: (key, value) => {
    const newValue = JSON.stringify(value);
    sessionStorage.setItem(key, newValue);
  },
  remove: (key) => {
    sessionStorage.removeItem(key);
  },
};

export const usePersistState = (
  key,
  state,
  config = { keepOnUnmount: true }
) => {
  const [value, setValue] = useState(storage.get(key) || state);
  // eslint-disable-next-line
  const updateState = useCallback(
    debounce((newState) => {
      storage.set(key, newState);
    }, 300),
    []
  );
  useEffect(() => {
    updateState(value);
  }, [value]); // eslint-disable-line
  useEffect(() => {
    return () => {
      if (!config.keepOnUnmount) {
        storage.remove(key);
      }
    };
  }, []); // eslint-disable-line
  return [value, setValue];
};

export const formatMobileNumber = (num) => {
  if (num.substring(0, 2) === "09") {
    return `639${num.substring(2, 11)}`;
  }
  return num;
};

export const payloadMobileNumber = (num) => {
  if (num.substring(0, 3) === "639") {
    return `09${num.substring(3, 12)}`;
  }
  return num;
};

export const formatSort = ({ sort, ...rest }) => {
  const sortKey = {};
  const [by, order] = (sort || "").split(":");
  if (by && order) {
    sortKey[`sort[${by}]`] = order;
  }
  return {
    ...rest,
    ...sortKey,
  };
};

export const downloadArrayToCsv = (data, filename) => {
  try {
    const rows = [];
    const headers = Object.keys(data[0]);
    rows.push(headers);
    each(data, (v) => {
      const rowArr = [];
      each(v, (r) => {
        rowArr.push(`"${r}"`);
      });
      rows.push(rowArr);
    });
    const csvContent = `data:text/csv;charset=utf-8,${rows
      .map((e) => e.join(","))
      .join("\n")}`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", filename);
    document.body.appendChild(link); // Required for FF
    link.click();
    document.body.removeChild(link);
  } catch (err) {
    toastError("Unable to generate download.");
  }
};

export const subDays = (count) => {
  const d = new Date();
  const final = new Date(d.setDate(d.getDate() - count));
  return final;
};

export const PARENT_OPTIONS = [
  { label: "FATHER", value: "FATHER" },
  { label: "MOTHER", value: "MOTHER" },
  { label: "GUARDIAN", value: "GUARDIAN" },
];

export const SUFFIX_OPTIONS = [
  { label: "Select Suffix", value: "" },
  { label: "SR", value: "SR" },
  { label: "JR", value: "JR" },
  { label: "III", value: "III" },
  { label: "II", value: "II" },
  { label: "I", value: "I" },
];

export const GENDER_OPTIONS = [
  { label: "Select Gender", value: "" },
  { label: "MALE", value: "MALE" },
  { label: "FEMALE", value: "FEMALE" },
  { label: "PREFER NOT TO RESPOND", value: "PREFER NOT TO RESPOND" },
];

export const VIDEO_OPTIONS = [
  { label: "Select Type of Video", value: "" },
  { label: "YOUTUBE", value: "YOUTUBE" },
  { label: "FACEBOOK", value: "FACEBOOK" },
];

export const BET_TYPES = [
  { label: "Select Type of Bet", value: "" },
  { label: "Daily Double", value: "Daily Double" },
  { label: "Extra Double", value: "Extra Double" },
  { label: "Daily Double Plus 1", value: "Daily Double Plus 1" },
  { label: "Forecast", value: "Forecast" },
];

export const BET_TYPES_EXOTIC = [
  { label: "Select Type of Bet", value: "" },
  { label: "Pick 5", value: "Pick 5" },
  { label: "Pick 6", value: "Pick 6" },
  { label: "Winner Takes All", value: "Winner Takes All" },
];

export const numberWithCommas = (x = 0) => {
  const parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

export const isNumber = (number) => {
  if (_.isEmpty(`${number}`)) return true;
  const regexp = /^([0-9]+\.?[0-9]*,?){1,}?$/;
  return regexp.test(number);
};

export const withNumber = (number) => {
  if (_.isEmpty(`${number}`)) return true;
  const regexp = /^\d+(,\d+)*$/;
  return regexp.test(number);
};
