import { useEffect, useCallback } from "react";
import { req, useApiGet, useApiLoading } from "react-reqq-lite";
import * as actions from "./actions";
import * as c from "./constants";

export const useAdminList = (params) => {
  const isLoading = useApiLoading(c.ADMIN_LIST, "get");
  const data = useApiGet(c.ADMIN_LIST, []);
  const pager = useApiGet(c.ADMIN_PAGER, {});
  useEffect(() => {
    actions.listAdmins(params);
  }, [params]);
  return [isLoading, data, pager];
};

export const useAdminSelected = () => {
  const isLoading = useApiLoading(c.ADMIN_ITEM, "get");
  const data = useApiGet(c.ADMIN_ITEM, {});
  return [isLoading, data];
};

export const useSelectAdmin = (id) => {
  useEffect(() => {
    if (!id) {
      req.set(c.ADMIN_ITEM, null);
      return;
    }
    actions.selectAdmin(id);
  }, [id]);
};

export const useCreateAdmin = () => {
  const isLoading = useApiLoading(c.ADMIN_ITEM, "post");
  const submit = useCallback((payload, onSuccess) => {
    actions.createAdmin(payload, onSuccess);
  }, []);
  return [isLoading, submit];
};

export const useUpdateAdmin = () => {
  const isLoading = useApiLoading(c.ADMIN_ITEM, "put");
  const submit = useCallback((id, payload, onSuccess) => {
    actions.updateAdmin(id, payload, onSuccess);
  }, []);
  return [isLoading, submit];
};

export const useUpdateAdminEmail = () => {
  const isLoading = useApiLoading(c.ADMIN_ITEM, "put");
  const submit = useCallback((id, payload, onSuccess) => {
    actions.updateAdminEmail(id, payload, onSuccess);
  }, []);
  return [isLoading, submit];
};

export const useChangeStatus = () => {
  const isLoading = useApiLoading(c.ADMIN_ITEM, "put");
  const submit = useCallback((id, payload, onSuccess) => {
    actions.changeStatus(id, payload, onSuccess);
  }, []);
  return [isLoading, submit];
};

export const useRemoveAdmin = () => {
  const isLoading = useApiLoading(c.ADMIN_ITEM, "remove");
  const submit = useCallback((id, onSuccess) => {
    actions.removeAdmin(id, onSuccess);
  }, []);
  return [isLoading, submit];
};
