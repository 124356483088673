import { req } from "react-reqq-lite";
import { toastSuccess } from "react-geek-toast";
import { transformSuperMasterAgent } from "./transformers";
import * as c from "./constants";

export const listSuperMasterAgents = (params) => {
  req.get({
    key: c.SUPER_MASTER_AGENT_LIST,
    url: "/api/superAdmin/superMasterAgents",
    params: params,
    transform: (res) => (res.data || []).map(transformSuperMasterAgent),
    onSuccess: (res) => {
      req.set(c.SUPER_MASTER_AGENT_PAGER, res?.response?.meta?.pagination || {});
      req.set(c.SUPER_MASTER_AGENT_FILTER, params);
    },
  });
};

export const selectSuperMasterAgent = (id) => {
  req.get({
    key: c.SUPER_MASTER_AGENT_ITEM,
    url: `/api/superAdmin/superMasterAgents/${id}`,
    transform: (res) => transformSuperMasterAgent(res.data),
  });
};

export const createSuperMasterAgent = (payload, onSuccess) => {
  req.post({
    key: c.SUPER_MASTER_AGENT_ITEM,
    url: "/api/superAdmin/superMasterAgents",
    payload,
    onSuccess: (res, state) => {
      toastSuccess("Created!");
      setTimeout(() => {
        listSuperMasterAgents(state[c.SUPER_MASTER_AGENT_FILTER]);
      }, 1000);
      if (onSuccess) onSuccess(transformSuperMasterAgent(res?.response?.data));
    },
  });
};

export const updateSuperMasterAgent = (id, payload, onSuccess) => {
  req.put({
    key: c.SUPER_MASTER_AGENT_ITEM,
    url: `/api/superAdmin/superMasterAgents/${id}`,
    payload,
    onSuccess: (res, state) => {
      toastSuccess("Updated!");
      setTimeout(() => {
        listSuperMasterAgents(state[c.SUPER_MASTER_AGENT_FILTER]);
      }, 1000);
      selectSuperMasterAgent(id);
      if (onSuccess) onSuccess(transformSuperMasterAgent(res?.response?.data));
    },
  });
};

export const updateSuperMasterAgentEmail = (id, payload, onSuccess) => {
  req.put({
    key: c.SUPER_MASTER_AGENT_ITEM,
    url: `/api/superAdmin/superMasterAgents/${id}`,
    payload,
    onSuccess: (res, state) => {
      toastSuccess("Updated!");
      setTimeout(() => {
        listSuperMasterAgents(state[c.SUPER_MASTER_AGENT_FILTER]);
      }, 1000);
      selectSuperMasterAgent(id);
      if (onSuccess) onSuccess(transformSuperMasterAgent(res?.response?.data));
    },
  });
};

export const removeSuperMasterAgent = (id, onSuccess) => {
  req.remove({
    key: c.SUPER_MASTER_AGENT_ITEM,
    url: `/api/superAdmin/superMasterAgents/${id}`,
    onSuccess: (res, state) => {
      toastSuccess("Removed!");
      setTimeout(() => {
        listSuperMasterAgents(state[c.SUPER_MASTER_AGENT_FILTER]);
      }, 1000);
      req.set(c.SUPER_MASTER_AGENT_ITEM, null);
      if (onSuccess) onSuccess();
    },
  });
};

export const changeStatus = (id, payload, onSuccess) => {
  req.put({
    key: c.SUPER_MASTER_AGENT_ITEM,
    url: `/api/superAdmin/superMasterAgents/${id}/change_status`,
    payload,
    onSuccess: (res, state) => {
      toastSuccess("Updated!");
      setTimeout(() => {
        listSuperMasterAgents(state[c.SUPER_MASTER_AGENT_FILTER]);
      }, 1000);
      selectSuperMasterAgent(id);
      if (onSuccess) onSuccess(transformSuperMasterAgent(res?.response?.data));
    },
  });
};
