import { formatDate } from "helper";

export const transformList = (raw) => ({
  id: raw?.id || "",
  type: raw?.attributes?.type || "N/a",
  amount: raw?.attributes.amount || "N/a",
  bet_type: raw?.attributes.bet_type || "N/a",
  bet: raw?.attributes.bet || "N/a",
  is_win: raw?.attributes.is_win || "N/a",
  winning_amount: raw?.attributes.winning_amount || "N/a",
  winning_horse: raw?.attributes.winning_horse || "N/a",
  date: formatDate(raw?.attributes?.created_at, "MMM DD, YYYY hh:mm A"),
});

export const transformWallet = (raw) => ({
  id: raw?.id || "",
  type: raw?.balance || "",
});
