import { req } from "react-reqq-lite";
import { ITEM, LIST, PAGER } from "./constants";
import { transformList, transformWallet } from "./transformers";

export const listTransaction = (params) => {
  req.get({
    key: LIST,
    url: "/api/my/wallet/transactions",
    params: params,
    transform: (res) => (res?.data || []).map(transformList),
    onSuccess: (res) => {
      // req.set(FILTER, params);
      req.set(PAGER, res?.response?.meta?.pagination || {});
    },
  });
};

export const getMyWallet = () => {
  req.get({
    key: ITEM,
    url: `/api/my/wallet`,
    onSuccess: (res, state) => {
      transformWallet(res?.response?.data?.attributes);
    },
  });
};
