import { req } from "react-reqq-lite";
import { toastSuccess } from "react-geek-toast";
import { FILTER, ITEM, LIST, PAGER } from "./constants";
import { transformList } from "./transformers";

export const listPlayer = (params) => {
  req.get({
    key: LIST,
    url: "/api/superAdmin/players",
    params: params,
    transform: (res) => (res?.data || []).map(transformList),
    onSuccess: (res) => {
      req.set(FILTER, params);
      req.set(PAGER, res?.response?.meta?.pagination || {});
    },
  });
};

export const selectSuperAdminPlayer = (id) => {
  req.get({
    key: ITEM,
    url: `/api/superAdmin/players/${id}`,
    transform: (res) => transformList(res.data),
  });
};

export const deletePlayer = (id, onSuccess) => {
  req.remove({
    key: ITEM,
    url: `/api/superAdmin/players/${id}`,
    onSuccess: (state) => {
      setTimeout(() => {
        listPlayer(state[FILTER]);
      }, 1000);
      toastSuccess("Deleted!");
      onSuccess();
    },
  });
};
