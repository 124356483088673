import cn from "classnames";
import { HiDocumentReport, HiOutlineX, HiUserGroup } from "react-icons/hi";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import logo from "assets/images/logo-inverted.png";
import { Dialog, Transition } from "@headlessui/react";
import { ImUsers } from "react-icons/im";
import { MdMeetingRoom, MdSupervisedUserCircle } from "react-icons/md";
import { IoIosCash } from "react-icons/io";
import {
  RiFolderUserFill,
  RiHandCoinFill,
  RiShieldUserFill,
  RiUser2Fill,
  RiUser6Fill,
} from "react-icons/ri";
import { UserIcon } from "@heroicons/react/outline";

const NavItem = ({ label, icon: Icon, to }) => {
  return (
    <NavLink to={to} className={cn("sidenav-link")} activeClassName="active">
      <Icon className="icon" aria-hidden="true" />
      <span>{label}</span>
    </NavLink>
  );
};

NavItem.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.instanceOf(Object).isRequired,
  to: PropTypes.string.isRequired,
};

const NavSubItem = ({ label, icon: Icon, to }) => {
  return (
    <NavLink to={to} className="sidenav-sublink group" activeClassName="active">
      {Icon && (
        <Icon className="icon group-hover:text-gray-500" aria-hidden="true" />
      )}
      <span>{label}</span>
    </NavLink>
  );
};

NavSubItem.defaultProps = {
  icon: false,
};

NavSubItem.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.bool]),
  to: PropTypes.string.isRequired,
};

const NavGroup = ({ label, children }) => (
  <div className="space-y-1">
    {label && (
      <h3 className="px-3 py-2 text-xs font-semibold text-gray-500 uppercase tracking-wider">
        {label}
      </h3>
    )}
    {children}
  </div>
);

NavGroup.defaultProps = {
  label: false,
};

NavGroup.propTypes = {
  label: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  children: PropTypes.instanceOf(Object).isRequired,
};

function Navigation({ className }) {
  return (
    <nav className={cn("px-2 space-y-3", className)}>
      <NavGroup label="Main">
        <NavItem to="/super-admin/players" label="Players" icon={HiUserGroup} />
        <NavItem
          to="/super-admin/super-master-agents"
          label="Super Master Agents"
          icon={RiUser6Fill}
        />
        <NavItem
          to="/super-admin/master-agents"
          label="Master Agents"
          icon={RiFolderUserFill}
        />
        <NavItem to="/super-admin/agents" label="Agents" icon={RiUser2Fill} />
        <NavItem to="/super-admin/handlers" label="Handlers" icon={ImUsers} />
        <NavItem
          to="/super-admin/admins"
          label="Admins"
          icon={MdSupervisedUserCircle}
        />
        <NavItem
          to="/super-admin/super-admin-page"
          label="Super Admins"
          icon={RiShieldUserFill}
        />
        <NavItem to="/super-admin/rooms" label="Rooms" icon={MdMeetingRoom} />
        <NavItem
          to="/super-admin/commissions"
          label="Commissions"
          icon={RiHandCoinFill}
        />
        <NavItem
          to="/super-admin/withdraw-requests"
          label="Withdraw Requests"
          icon={IoIosCash}
        />
        <NavItem
          to="/super-admin/reports"
          label="Reports"
          icon={HiDocumentReport}
        />
        <NavItem to="/profile" label="Profile" icon={UserIcon} />
        {/* <NavItem
          to="/super-admin/game-managers"
          label="Game Managers"
          icon={FaGamepad}
        /> */}
      </NavGroup>
    </nav>
  );
}

Navigation.defaultProps = {
  className: "",
};

Navigation.propTypes = {
  className: PropTypes.string,
};

function SuperAdminSidebar({ sidebarOpen, setSidebarOpen }) {
  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex z-40 md:hidden"
          open={sidebarOpen}
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-900">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                    type="button"
                  >
                    <span className="sr-only">Close sidebar</span>
                    <HiOutlineX
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-shrink-0 flex items-center px-4">
                <img className="h-8 w-auto" src={logo} alt="Brand" />
              </div>
              <div className="mt-5 flex-1 h-0 overflow-y-auto">
                <Navigation />
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true" />
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:flex-shrink-0">
        <div className="flex flex-col w-64">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-col flex-grow border-r border-gray-200 pt-5 pb-4 bg-gray-900 overflow-y-auto">
            <div className="flex items-center flex-shrink-0 px-4">
              <img className="h-16 w-auto mx-auto" src={logo} alt="Brand" />
            </div>
            <div className="mt-5 flex-grow flex flex-col">
              <Navigation className="flex-1 bg-gradient-to-t from-gray-800 to-gray-900" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

SuperAdminSidebar.propTypes = {
  sidebarOpen: PropTypes.bool.isRequired,
  setSidebarOpen: PropTypes.func.isRequired,
};

export default SuperAdminSidebar;
