import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Form from "partial/form/Form";
import FormInput from "partial/form/FormInput";
import Button from "partial/components/Button";
import { showDrawer } from "partial/components/Modal";
import { useUpdateChangeBet } from "../hooks";
import _ from "lodash";
import { toastError } from "react-geek-toast";

export const openStartBettingModal = (title, roomId, sessionId, onSuccess) => {
  showDrawer({
    size: "xs",
    title,
    content: (onClose) => (
      <StartBettingModal
        onClose={onClose}
        onSuccess={onSuccess}
        roomId={roomId}
        sessionId={sessionId}
      />
    ),
  });
};

function StartBettingModal({ roomId, sessionId, onClose, onSuccess }) {
  const [betType, setBetType] = useState([]);
  const [filteredBetType, setFilteredBetType] = useState("");

  const [form, setForm] = React.useState({
    round: "",
    horse_count: "",
    available_bet_types: "",
  });
  const [isUpdate, update] = useUpdateChangeBet();
  const handleSubmit = () => {
    const args = {
      ...form,
      available_bet_types: filteredBetType,
    };
    if (!_.isEmpty(filteredBetType)) {
      update(roomId, sessionId, args, onClose);
    } else {
      toastError("Please select bet type");
    }
  };

  const betTypes = [
    {
      id: "1",
      name: "Forecast",
    },
    {
      id: "2",
      name: "Daily Double",
    },
    {
      id: "3",
      name: "Daily Double Plus 1",
    },
    {
      id: "4",
      name: "Extra Double",
    },
    {
      id: "5",
      name: "Pick 5",
    },
    {
      id: "6",
      name: "Pick 6",
    },
    {
      id: "7",
      name: "Winner Takes All",
    },
  ];

  const handleBetType = (e, value) => {
    if (e.target.checked) {
      setBetType([...betType, e.target.value]);
    } else {
      setBetType(betType.filter((id) => id !== e.target.value));
    }
  };

  useEffect(() => {
    if (betType.length === 0) {
      setFilteredBetType("");
    } else {
      setFilteredBetType(betType.join(","));
    }
  }, [betType]);

  return (
    <Form className="divide-y divide-gray-200" onSubmit={handleSubmit}>
      <div>
        <div className="mb-3">
          <FormInput
            type="number"
            name="round"
            onChange={setForm}
            value={form.round}
            label="Race"
            required
          />
        </div>
        <div className="mb-3">
          <FormInput
            type="number"
            name="horse_count"
            onChange={setForm}
            value={form.horse_count}
            label="Horse Count"
            required
          />
        </div>
        {betTypes.map((xxx) => (
          <div key={xxx.name} className="space-x-2 flex items-center">
            <input
              id={xxx.id}
              name="status"
              type="checkbox"
              onChange={handleBetType}
              value={xxx.name}
              className="h-5 w-5 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
            />
            <label
              for={xxx.id}
              className="mr-2 font-semibold text-base w-full py-2"
            >
              {xxx.name}
            </label>
          </div>
        ))}
      </div>
      <div className="pt-5">
        <div className="flex justify-end">
          <Button type="submit" primary size="lg" disabled={isUpdate}>
            Save
          </Button>
        </div>
      </div>
    </Form>
  );
}

StartBettingModal.defaultProps = {
  roomId: "",
  sessionId: "",
  onSuccess: false,
};

StartBettingModal.propTypes = {
  roomId: PropTypes.number,
  sessionId: PropTypes.string,
  onSuccess: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onClose: PropTypes.func.isRequired,
};

export default StartBettingModal;
