import { debounce } from "lodash";
import React from "react";
import shortid from "shortid";

export const useInputId = (val) => {
  const [id, setId] = React.useState(val || shortid.generate());
  React.useEffect(() => {
    if (!val) return;
    setId(val);
  }, [setId, val]);
  return [id];
};

export const useDebouceEffect = (value, callback, timeout = 1000) => {
  // eslint-disable-next-line
  const update = React.useCallback(
    debounce((newValue) => {
      callback(newValue);
    }, timeout),
    []
  );
  const isMounted = React.useRef();
  React.useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }
    update(value);
  }, [update, value]);
};
