import React from "react";
import PropTypes from "prop-types";
import { useDebouceEffect } from "partial/form/hooks";
import { HiFilter } from "react-icons/hi";
import FormDatePicker from "partial/form/FormDatePicker";
import FormSelect from "partial/form/FormSelect";
import { useHandlerList } from "modules/super-admin/handlers/hooks";
import _ from "lodash";

function ReportFilter({ onSubmit, value }) {
  const [form, setForm] = React.useState(value);
  useDebouceEffect(form, () => {
    onSubmit((state) => ({
      ...state,
    }));
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit((state) => ({
      ...state,
      ...form,
    }));
  };

  const handleOnChangeDate = (key) => ({ target: { value } }) => {
    setForm({
      ...form,
      [key]: value || "",
    });
  };

  const [, handlerList] = useHandlerList();

  const handlerOptions = handlerList.map((x) => ({
    label: _.get(x, "name"),
    value: _.get(x, "user_id"),
  }));

  return (
    <form className="inline-flex mb-2 space-x-2 mt-2" onSubmit={handleSubmit}>
      <div>
        <FormDatePicker
          label="Date"
          onChange={handleOnChangeDate("date")}
          value={_.get(form, "date")}
          dateFormat="MM/dd/yyyy"
          showMonthDropdown={true}
          showYearDropdown={true}
        />
      </div>
      <div>
        <FormSelect
          name="handler_id"
          onChange={setForm}
          value={form.handler_id}
          label="Handler"
          required
          placeholder="Select Handler"
          options={handlerOptions}
        />
      </div>
      <button
        type="submit"
        className="flex items-center justify-center px-3.5 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
      >
        <HiFilter className="h-5 w-5 text-gray-400" aria-hidden="true" />
        <span className="sr-only">Search</span>
      </button>
    </form>
  );
}

ReportFilter.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Object).isRequired,
};

export default ReportFilter;
