import React from "react";
import PropTypes from "prop-types";
import Form from "partial/form/Form";
import FormInput from "partial/form/FormInput";
import Button from "partial/components/Button";
import { showDrawer } from "partial/components/Modal";
import { transformList } from "../transformers";
import { useMasterAgentAddCredit, useMasterAgentAddDebit } from "../hooks";

export const openMasterAgentWalletModal = (title, agentId, type, data, onSuccess) => {
  showDrawer({
    size: "xs",
    title,
    content: (onClose) => (
      <MasterAgentWalletModal
        data={data}
        onClose={onClose}
        onSuccess={onSuccess}
        type={type}
        agentId={agentId}
      />
    ),
  });
};

function MasterAgentWalletModal({ data, onClose, type, agentId }) {
  const [form, setForm] = React.useState({
    amount: 0,
  });
  const [isAddCredit, addCredit] = useMasterAgentAddCredit();
  const [isAddDebit, addDebit] = useMasterAgentAddDebit();
  const handleSubmit = () => {
    if (type === "CREDIT") {
      addCredit(agentId, form, (newData) => {
        onClose();
        // onSuccess(newData);
      });
      return;
    }
    if (type === "DEBIT") {
      addDebit(agentId, form, onClose);
      return;
    }
  };

  return (
    <Form className="divide-y divide-gray-200" onSubmit={handleSubmit}>
      <div>
        <div className="mb-3">
          <FormInput
            name="amount"
            onChange={setForm}
            value={form.amount}
            label="Amount"
            required
            type="number"
          />
        </div>
      </div>
      <div className="pt-5">
        <div className="flex justify-end">
          <Button
            type="submit"
            primary
            size="lg"
            disabled={isAddCredit || isAddDebit}
          >
            Save
          </Button>
        </div>
      </div>
    </Form>
  );
}

MasterAgentWalletModal.defaultProps = {
  data: transformList(),
  onSuccess: false,
};

MasterAgentWalletModal.propTypes = {
  data: PropTypes.instanceOf(Object),
  onSuccess: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onClose: PropTypes.func.isRequired,
};

export default MasterAgentWalletModal;
