import { req } from "react-reqq-lite";
import { FILTER, LIST, PAGER, ITEM } from "./constants";
import { transformList } from "./transformers";

export const listReport = (params) => {
  req.get({
    key: LIST,
    url: "/api/superAdmin/report",
    params: params,
    transform: (res) => (res?.data || []).map(transformList),
    onSuccess: (res) => {
      req.set(PAGER, res?.response?.meta?.pagination || {});
      req.set(FILTER, params);
    },
  });
};

export const selectReport = () => {
  req.get({
    key: ITEM,
    url: `/api/superAdmin/report/money`,
    onSuccess: (res) => {
      req.set(ITEM, res?.response?.data || {});
    },
  });
};
