import React, { useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import HeaderProfile from "partial/components/HeaderProfile";
import { HiOutlineMenuAlt2 } from "react-icons/hi";

import ModuleWrapper, {
  ModuleLabel,
  ModuleRoute,
} from "partial/wrappers/ModuleWrapper";

import SuperAdminSidebar from "modules/super-admin/partial/SuperAdminSidebar";
import MasterAgentRoutes from "modules/super-admin/master-agents/containers/MasterAgentRoutes";
import SuperAdminPlayerRoutes from "modules/super-admin/players/containers/SuperAdminPlayerRoutes";
import SuperAdminAgentRoutes from "modules/super-admin/agents/containers/SuperAdminAgentRoutes";
import Logout from "modules/auth/containers/Logout";
import PageNotFound from "partial/containers/PageNotFound";
import HandlerRoutes from "modules/super-admin/handlers/containers/HandlerRoutes";
import AdminRoutes from "modules/super-admin/admin/containers/AdminRoutes";
import SuperAdminPageRoutes from "modules/super-admin/super-admin-page/containers/SuperAdminPageRoutes";
import RoomRoutes from "modules/super-admin/rooms/containers/RoomRoutes";
import CommissionContainer from "modules/super-admin/commissions/containers/CommissionContainer";
import WithdrawRequestRoutes from "modules/super-admin/withdraw-requests/containers/WithdrawRequestRoutes";
import ReportContainer from "modules/super-admin/reports/containers/ReportContainer";
import Profile from "partial/containers/Profile";
import GameManagerRoutes from "modules/super-admin/game-managers/containers/GameManagerRoutes";
import SuperMasterAgentRoutes from "modules/super-admin/super-master-agents/containers/SuperMasterAgentRoutes";

function SuperAdminRoutes() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  return (
    <ModuleWrapper>
      <div className="h-screen flex overflow-hidden bg-gray-100">
        <SuperAdminSidebar
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
        <div className="flex flex-col w-0 flex-1 overflow-hidden">
          <div className="relative z-10 flex-shrink-0 flex h-16 bg-white shadow">
            <button
              className="px-4 border-r sm:border-gray-200 border-gray-700 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500 md:hidden"
              onClick={() => setSidebarOpen(true)}
              type="button"
            >
              <span className="sr-only">Open sidebar</span>
              <HiOutlineMenuAlt2 className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="flex-1 px-4 flex justify-between">
              <div className="flex-1 flex items-center relative">
                <div className="absolute w-full line-clamp-1">
                  <h1 className="text-2xl font-semibold text-gray-900 truncate">
                    <ModuleLabel />
                  </h1>
                </div>
              </div>
              <div className="ml-4 flex items-center md:ml-6">
                {/* Profile dropdown */}
                <HeaderProfile />
              </div>
            </div>
          </div>
          <main className="flex-1 relative overflow-y-auto focus:outline-none flex flex-col">
            <Switch>
              <ModuleRoute
                title="Players"
                path="/super-admin/players"
                component={SuperAdminPlayerRoutes}
              />
              <ModuleRoute
                title="Super Master Agents"
                path="/super-admin/super-master-agents"
                component={SuperMasterAgentRoutes}
              />
              <ModuleRoute
                title="Master Agents"
                path="/super-admin/master-agents"
                component={MasterAgentRoutes}
              />
              <ModuleRoute
                title="Agents"
                path="/super-admin/agents"
                component={SuperAdminAgentRoutes}
              />
              <ModuleRoute
                title="Handlers"
                path="/super-admin/handlers"
                component={HandlerRoutes}
              />
              <ModuleRoute
                title="Admins"
                path="/super-admin/admins"
                component={AdminRoutes}
              />
              <ModuleRoute
                title="Super Admins"
                path="/super-admin/super-admin-page"
                component={SuperAdminPageRoutes}
              />
              <ModuleRoute
                title="Rooms"
                path="/super-admin/rooms"
                component={RoomRoutes}
              />
              <ModuleRoute
                title="Commissions"
                path="/super-admin/commissions"
                component={CommissionContainer}
              />
              <ModuleRoute
                title="Withdraw Requets"
                path="/super-admin/withdraw-requests"
                component={WithdrawRequestRoutes}
              />
              <ModuleRoute
                title="View Reports"
                path="/super-admin/reports"
                component={ReportContainer}
              />
              <ModuleRoute
                title="Game Managers"
                path="/super-admin/game-managers"
                component={GameManagerRoutes}
              />
              <ModuleRoute
                title="Profile"
                path="/profile"
                component={Profile}
              />
              <Route path="/logout" component={Logout} />
              <Route exact path="/">
                <Redirect to="/super-admin/players" />
              </Route>
              <Route component={PageNotFound} />
            </Switch>
          </main>
        </div>
      </div>
    </ModuleWrapper>
  );
}

export default SuperAdminRoutes;
