import React from "react";
import PropTypes from "prop-types";

function SuperAdminPlayerContent({ data }) {
  const renderBadge = React.useCallback(
    (row) => (
      <>
        {row === true && <span className="badge badge-success">Active</span>}
        {row === false && <span className="badge badge-gray">Inactive</span>}
      </>
    ),
    []
  );

  return (
    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
      <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">First Name</dt>
        <dd className="mt-1 text-base text-gray-900 font-semibold">
          {data.first_name}
        </dd>
      </div>

      <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">Last Name</dt>
        <dd className="mt-1 text-base text-gray-900 font-semibold">
          {data.last_name}
        </dd>
      </div>

      <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">Contact Number</dt>
        <dd className="mt-1 text-base text-gray-900 font-semibold">
          {data.phone_number}
        </dd>
      </div>

      <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">Email Address</dt>
        <dd className="mt-1 text-base text-gray-900 font-semibold">
          {data.email}
        </dd>
      </div>
      <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">Referrer</dt>
        <dd className="mt-1 text-base text-gray-900 font-semibold">
          {data.referrer}
        </dd>
      </div>
      <div className="sm:col-span-3">
        <dt className="text-sm font-medium text-gray-500">Status</dt>
        <dd className="mt-1 text-sm text-gray-900">
          {renderBadge(data.status)}
        </dd>
      </div>
    </dl>
  );
}

SuperAdminPlayerContent.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default SuperAdminPlayerContent;
