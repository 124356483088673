import React from "react";
import * as actions from "../actions";

function Logout() {
  React.useEffect(() => {
    actions.logout();
  }, []);
  return <div>Logging out...please wait</div>;
}

Logout.propTypes = {};

export default Logout;
