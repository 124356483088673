import { req, useApiGet, useApiLoading } from "react-reqq-lite";
import { useCallback, useEffect } from "react";
import {
  ITEM,
  LIST,
  PAGER,
  PLAYER_WALLET_LIST,
  ADD_DEBIT_FUND_PLAYER,
  ADD_CREDIT_FUND_PLAYER,
  PLAYER_WALLET_PAGER,
} from "./constants";
import {
  listPlayer,
  selectPlayer,
  listPlayerWallet,
  addDebitFundPlayer,
  addCreditFundPlayer,
} from "./actions";

export const usePlayerList = (params) => {
  const isLoading = useApiLoading(LIST, "get");
  const meta = useApiGet(PAGER, {});
  const list = useApiGet(LIST, []);
  useEffect(() => {
    listPlayer(params);
  }, [params]);
  return [isLoading, list, meta];
};

export const usePlayerSelected = () => {
  const isLoading = useApiLoading(ITEM, "get");
  const data = useApiGet(ITEM, {});
  return [isLoading, data];
};

export const useSelectPlayer = (playerId) => {
  useEffect(() => {
    if (!playerId) {
      req.set(ITEM, null);
      return;
    }

    selectPlayer(playerId);
  }, [playerId]);
};

export const usePlayerWalletList = (params, playerId) => {
  const isLoading = useApiLoading(PLAYER_WALLET_LIST, "get");
  const meta = useApiGet(PLAYER_WALLET_PAGER, {});
  const list = useApiGet(PLAYER_WALLET_LIST, []);
  useEffect(() => {
    listPlayerWallet(params, playerId);
  }, [params, playerId]);
  return [isLoading, list, meta];
};

export const usePlayerAddCredit = () => {
  const isLoading = useApiLoading(ADD_CREDIT_FUND_PLAYER, "post");
  const submit = useCallback((playerId, payload, onSuccess) => {
    addCreditFundPlayer(playerId, payload, onSuccess);
  }, []);
  return [isLoading, submit];
};

export const usePlayerAddDebit = () => {
  const isLoading = useApiLoading(ADD_DEBIT_FUND_PLAYER, "post");
  const submit = useCallback((playerId, payload, onSuccess) => {
    addDebitFundPlayer(playerId, payload, onSuccess);
  }, []);
  return [isLoading, submit];
};
