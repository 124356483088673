import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import HeaderProfile from "partial/components/HeaderProfile";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import firebase from "./setup/firebase";
import { toastError } from "react-geek-toast";
import Cookies from "js-cookie";
import ModuleWrapper, {
  ModuleLabel,
  ModuleRoute,
} from "partial/wrappers/ModuleWrapper";
import PageNotFound from "partial/containers/PageNotFound";
import PlayerRoomRoutes from "modules/player/player-room/containers/PlayerRoomRoutes";
import Logout from "modules/auth/containers/Logout";
import PlayerSidebar from "modules/player/partial/PlayerSidebar";
import TransactionContainer from "modules/player/transactions/containers/TransactionContainer";
import WithdrawRoutes from "modules/player/withdraw/containers/WithdrawRoutes";
import Profile from "partial/containers/Profile";
import { usePlayerWalletDetails } from "modules/player/player-room/hooks";
import _ from "lodash";

function PlayerRoutes() {
  const profile = JSON.parse(Cookies.get("_user")) || {};
  const [, walletInfo] = usePlayerWalletDetails();
  const [playerWallet, setPlayerWallet] = useState();

  useEffect(() => {
    setPlayerWallet(_.get(walletInfo, "data.attributes.balance"));
  }, [walletInfo]);

  useEffect(() => {
    // build reference
    const walletApi = firebase
      .database()
      .ref("user/" + profile.id + "/balance/");

    // attach listener
    walletApi.on(
      "value",
      (snapshot) => {
        const data = snapshot.val();
        setPlayerWallet(data);
      },
      (error) => {
        toastError(error);
      }
    );

    // detach listener in unsubscribe callback
    return () => walletApi.off();
    // eslint-disable-next-line
  }, []);

  const [sidebarOpen, setSidebarOpen] = useState(false);
  return (
    <ModuleWrapper>
      <div className="h-screen flex overflow-hidden bg-gray-100">
        <PlayerSidebar
          playerWallet={playerWallet}
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
        <div className="flex flex-col w-0 flex-1 overflow-hidden border-l border-gray-800">
          <div className="relative z-10 flex-shrink-0 flex h-16 bg-gray-900 border-b border-gray-800 shadow">
            <button
              className="px-4 border-r sm:border-gray-200 border-gray-700 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500 md:hidden"
              onClick={() => setSidebarOpen(true)}
              type="button"
            >
              <span className="sr-only">Open sidebar</span>
              <HiOutlineMenuAlt2 className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="flex-1 px-4 flex justify-between">
              <div className="flex-1 flex items-center relative">
                <div className="absolute w-full line-clamp-1">
                  <h1 className="text-2xl font-semibold text-white truncate">
                    <ModuleLabel />
                  </h1>
                </div>
              </div>
              <div className="ml-4 flex items-center md:ml-6">
                {/* Profile dropdown */}
                <HeaderProfile />
              </div>
            </div>
          </div>
          <main className="flex-1 relative overflow-y-auto focus:outline-none flex flex-col bg-gray-900">
            <Switch>
              <ModuleRoute
                title="Room"
                path="/player/room"
                component={PlayerRoomRoutes}
              />
              {/* <ModuleRoute
                title="Game Room"
                path="/player/room/:id"
                component={PlayerRoomDetail}
              /> */}
              <ModuleRoute
                title="Transactions"
                path="/player/transaction"
                component={TransactionContainer}
              />
              <ModuleRoute
                title="Withdraw"
                path="/player/withdraw"
                component={WithdrawRoutes}
              />
              <ModuleRoute
                title="Profile"
                path="/profile"
                component={Profile}
              />
              <Route path="/logout" component={Logout} />
              <Route exact path="/">
                <Redirect to="/player/room" />
              </Route>
              <Route component={PageNotFound} />
            </Switch>
          </main>
        </div>
      </div>
    </ModuleWrapper>
  );
}

export default PlayerRoutes;
