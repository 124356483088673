import React from "react";
import PropTypes from "prop-types";
import { useDebouceEffect } from "partial/form/hooks";
import FormInlineInput from "partial/form/FormInlineInput";
import { HiFilter, HiOutlineSearch } from "react-icons/hi";

function GameManagerListFilter({ onSubmit, value }) {
  const [form, setForm] = React.useState(value);
  useDebouceEffect(form.email, (email) => {
    onSubmit((state) => ({
      ...state,
      email,
    }));
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit((state) => ({
      ...state,
      ...form,
    }));
  };
  return (
    <form className="mt-6 flex space-x-4" onSubmit={handleSubmit}>
      <div className="flex-1 min-w-0 search-wrapper">
        <label htmlFor="search" className="sr-only">
          Search
        </label>
        <FormInlineInput
          name="email"
          onChange={setForm}
          value={form.email}
          icon={HiOutlineSearch}
          placeholder="Search..."
        />
      </div>
      <button
        type="submit"
        className="inline-flex justify-center px-3.5 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
      >
        <HiFilter className="h-5 w-5 text-gray-400" aria-hidden="true" />
        <span className="sr-only">Search</span>
      </button>
    </form>
  );
}

GameManagerListFilter.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Object).isRequired,
};

export default GameManagerListFilter;
