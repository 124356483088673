import { useEffect, useCallback } from "react";
import { useApiGet, useApiLoading } from "react-reqq-lite";
import * as actions from "./actions";
import * as c from "./constants";

export const usePlayerRoomList = (params, roomId) => {
  const isLoading = useApiLoading(c.PLAYER_ROOM_LIST, "get");
  const data = useApiGet(c.PLAYER_ROOM_LIST, []);
  const pager = useApiGet(c.PLAYER_ROOM_PAGER, {});
  useEffect(() => {
    actions.listPlayerRooms(params, roomId);
  }, [params, roomId]);
  return [isLoading, data, pager];
};

export const usePlayerWalletDetails = () => {
  const isLoading = useApiLoading(c.PLAYER_WALLET_DETAILS, "get");
  const data = useApiGet(c.PLAYER_WALLET_DETAILS, {});
  useEffect(() => {
    actions.getPlayerWalletDetails();
  }, []);
  return [isLoading, data];
};

export const usePlayerRoomSelected = (params) => {
  const isLoading = useApiLoading(c.SELECT_GAME_ROOM, "get");
  const data = useApiGet(c.SELECT_GAME_ROOM, []);
  useEffect(() => {
    actions.selectGameRoom(params);
  }, [params]);
  return [isLoading, data];
};

export const useAddBet = () => {
  const isLoading = useApiLoading(c.PLAYER_BET, "post");
  const submit = useCallback((payload, roomId, onSuccess) => {
    actions.addBet(payload, roomId, onSuccess);
  }, []);
  return [isLoading, submit];
};