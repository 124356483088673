import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import SuperMasterAgentDetail from "./SuperMasterAgentDetail";

function SuperMasterAgentRoutes() {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}`} component={SuperMasterAgentDetail} />
      <Redirect to={`${match.url}`} />
    </Switch>
  );
}

SuperMasterAgentRoutes.propTypes = {};

export default SuperMasterAgentRoutes;
