import { req } from "react-reqq-lite";
import { toastSuccess } from "react-geek-toast";
import { transformList } from "./transformers";
import * as c from "./constants";

export const listHandlers = (params) => {
  req.get({
    key: c.HANDLER_LIST,
    url: "/api/superAdmin/handlers",
    params: params,
    transform: (res) => (res.data || []).map(transformList),
    onSuccess: (res) => {
      req.set(c.HANDLER_PAGER, res?.response?.meta?.pagination || {});
      req.set(c.HANDLER_FILTER, params);
    },
  });
};

export const selectHandler = (id) => {
  req.get({
    key: c.HANDLER_ITEM,
    url: `/api/superAdmin/handlers/${id}`,
    transform: (res) => transformList(res.data),
  });
};

export const createHandler = (payload, onSuccess) => {
  req.post({
    key: c.HANDLER_ITEM,
    url: "/api/superAdmin/handlers",
    payload,
    onSuccess: (res, state) => {
      toastSuccess("Created!");
      setTimeout(() => {
        listHandlers(state[c.HANDLER_FILTER]);
      }, 1000);
      if (onSuccess) onSuccess(transformList(res?.response?.data));
    },
  });
};

export const updateHandler = (id, payload, onSuccess) => {
  req.put({
    key: c.HANDLER_ITEM,
    url: `/api/superAdmin/handlers/${id}`,
    payload,
    onSuccess: (res, state) => {
      toastSuccess("Updated!");
      setTimeout(() => {
        listHandlers(state[c.HANDLER_FILTER]);
      }, 1000);
      selectHandler(id);
      if (onSuccess) onSuccess(transformList(res?.response?.data));
    },
  });
};

export const updateHandlerEmail = (id, payload, onSuccess) => {
  req.put({
    key: c.HANDLER_ITEM,
    url: `/api/superAdmin/handlers/${id}`,
    payload,
    onSuccess: (res, state) => {
      toastSuccess("Updated!");
      setTimeout(() => {
        listHandlers(state[c.HANDLER_FILTER]);
      }, 1000);
      selectHandler(id);
      if (onSuccess) onSuccess(transformList(res?.response?.data));
    },
  });
};

export const removeHandler = (id, onSuccess) => {
  req.remove({
    key: c.HANDLER_ITEM,
    url: `/api/superAdmin/handlers/${id}`,
    onSuccess: (res, state) => {
      toastSuccess("Removed!");
      setTimeout(() => {
        listHandlers(state[c.HANDLER_FILTER]);
      }, 1000);
      req.set(c.HANDLER_ITEM, null);
      if (onSuccess) onSuccess();
    },
  });
};

export const changeStatus = (id, payload, onSuccess) => {
  req.put({
    key: c.HANDLER_ITEM,
    url: `/api/superAdmin/handlers/${id}/change_status`,
    payload,
    onSuccess: (res, state) => {
      toastSuccess("Updated!");
      setTimeout(() => {
        listHandlers(state[c.HANDLER_FILTER]);
      }, 1000);
      selectHandler(id);
      if (onSuccess) onSuccess(transformList(res?.response?.data));
    },
  });
};
