import { toastSuccess } from "react-geek-toast";
import { req } from "react-reqq-lite";
import { FILTER, LIST, PAGER, CONVERT_TO_WALLET } from "./constants";
import { transformList } from "./transformers";

export const listCommission = (params) => {
  req.get({
    key: LIST,
    url: "/api/my/commissions",
    params: params,
    transform: (res) => (res?.data || []).map(transformList),
    onSuccess: (res) => {
      req.set(PAGER, res?.response?.meta?.pagination || {});
      req.set(FILTER, params);
    },
  });
};

export const convertToWallet = (payload, onSuccess) => {
  req.post({
    key: CONVERT_TO_WALLET,
    url: `/api/my/commissions/convert`,
    payload,
    onSuccess: (res, state) => {
      toastSuccess("You have successfully converted it to wallet.");
      setTimeout(() => {
        listCommission();
      }, 1000);
      if (onSuccess) onSuccess(transformList(res?.response?.data));
    },
  });
};
