import React from "react";
import PropTypes from "prop-types";
import { HiOutlinePencil } from "react-icons/hi";
import { openHandlerModal } from "../modals/HandlerModal";
import Button from "partial/components/Button";
import { useGetCurrentRoom } from "../hooks";
import _ from "lodash";

function HandlerContent({ data }) {
  const [, currentRoom] = useGetCurrentRoom();

  const handleOpenEdit = () => {
    openHandlerModal("Edit Handler", data, currentRoom);
  };

  const createMarkup = (text) => {
    const printableText = text.replace(/(?:\r\n|\r|\n)/g, "<br />");
    return { __html: printableText };
  };

  return (
    <>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto" />
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          {!_.isEmpty(data) && (
            <>
              <Button
                size="default"
                default
                type=""
                icon={HiOutlinePencil}
                className="w-full"
                onClick={handleOpenEdit}
              >
                <span>Edit</span>
              </Button>
            </>
          )}
        </div>
      </div>
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Session Name</dt>
          <dd className="mt-1 text-base text-gray-900 font-semibold">
            {data.name}
          </dd>
        </div>

        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">
            Number of Rounds
          </dt>
          <dd className="mt-1 text-base text-gray-900 font-semibold">
            {data.number_of_rounds}
          </dd>
        </div>

        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Type of Video</dt>
          <dd className="mt-1 text-base text-gray-900 font-semibold">
            {data.type_video}
          </dd>
        </div>
      </dl>

      <div className="sm:col-span-1 mt-2">
        <dt className="text-sm font-medium text-gray-500">Url</dt>
        <dd className="mt-1 text-base text-gray-900 font-semibold">
          {data.url}
        </dd>
      </div>

      <div className="sm:col-span-1 mt-2 border-t pt-2">
        <dt className="text-sm font-medium text-gray-500">Race Description</dt>
        <div
          dangerouslySetInnerHTML={createMarkup(
            _.get(data, "race_description") || "--"
          )}
        />
      </div>
    </>
  );
}

HandlerContent.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default HandlerContent;
