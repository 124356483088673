import history from "setup/history";
import { req } from "react-reqq-lite";
import * as c from "./constants";

export const register = (payload) => {
  req.post({
    key: c.REGISTER,
    url: "/api/masterAgent/register",
    payload,
    onSuccess: (res) => {
      history.push("/");
    },
  });
};
