export const transformList = (raw) => {
  return {
    id: raw?.id,
    name: raw?.attributes.cash_out_to_user.profile_type || "",
    amount: raw?.attributes.amount || "",
    email: raw?.attributes.cash_out_to_user.email || "",
    status: raw?.attributes?.status || "",
  };
};

export const transformUserList = (raw) => {
  return {
    raw,
  };
};
