import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import MasterAgentProfile from "./SuperMasterAgentMasterAgentProfile";

function SuperMasterAgentMasterAgentRoutes() {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}`} component={MasterAgentProfile} />
      <Redirect to={`${match.url}`} />
    </Switch>
  );
}

SuperMasterAgentMasterAgentRoutes.propTypes = {};

export default SuperMasterAgentMasterAgentRoutes;
