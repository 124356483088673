import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-reqq-lite";
import { Router } from "react-router-dom";
import App from "App";
import * as serviceWorker from "serviceWorker";
import store from "setup/store";
import history from "setup/history";
import "assets/scss/styles.scss";
import "react-datepicker/dist/react-datepicker.css";
import "assets/theme/default.css";
import "assets/css/styles.css";
import "react-phone-input-2/lib/plain.css";

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <StrictMode>
        <App />
      </StrictMode>
    </Router>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
