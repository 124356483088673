import { req } from "react-reqq-lite";
import { toastSuccess } from "react-geek-toast";
import { transformList, transformUserList } from "./transformers";
import * as c from "./constants";

export const listWithdraw = (params) => {
  req.get({
    key: c.WITHDRAW_LIST,
    url: "/api/my/wallet/cashout",
    params: params,
    transform: (res) => (res.data || []).map(transformList),
    onSuccess: (res) => {
      req.set(c.WITHDRAW_PAGER, res?.response?.meta?.pagination || {});
      req.set(c.WITHDRAW_FILTER, params);
    },
  });
};

export const selectWithdraw = (id) => {
  req.get({
    key: c.WITHDRAW_ITEM,
    url: `/api/my/wallet/cashout/${id}`,
    transform: (res) => transformList(res.data),
  });
};

export const createWithdraw = (payload, onSuccess) => {
  req.post({
    key: c.WITHDRAW_ITEM,
    url: "/api/my/wallet/cashout",
    payload,
    onSuccess: (res, state) => {
      toastSuccess(res.response.data.message);
      setTimeout(() => {
        listWithdraw(state[c.WITHDRAW_FILTER]);
      }, 1000);
      if (onSuccess) onSuccess(res.response.data);
    },
  });
};

export const removeWithdraw = (id, onSuccess) => {
  req.remove({
    key: c.WITHDRAW_ITEM,
    url: `/api/my/wallet/cashout/${id}`,
    onSuccess: (res, state) => {
      toastSuccess("Removed!");
      setTimeout(() => {
        listWithdraw(state[c.WITHDRAW_FILTER]);
      }, 1000);
      req.set(c.WITHDRAW_ITEM, null);
      if (onSuccess) onSuccess();
    },
  });
};

export const changeStatus = (id, payload, onSuccess) => {
  req.put({
    key: c.WITHDRAW_ITEM,
    url: `/api/my/wallet/cashout/${id}/change_status`,
    payload,
    onSuccess: (res, state) => {
      toastSuccess("Updated!");
      setTimeout(() => {
        listWithdraw(state[c.WITHDRAW_FILTER]);
      }, 1000);
      selectWithdraw(id);
      if (onSuccess) onSuccess(transformList(res?.response?.data));
    },
  });
};

export const listUser = () => {
  req.get({
    key: c.USER_LIST,
    url: "/api/my/wallet/cashout/user_list",
    transform: (res) =>
      (res?.response?.data?.list || []).map(transformUserList),
    onSuccess: (res) => {
      req.set(c.USER_LIST, res?.response?.data?.list || {});
    },
  });
};
