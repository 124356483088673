import { req, useApiGet, useApiLoading } from "react-reqq-lite";
import { useCallback, useEffect } from "react";
import { ITEM, LIST, PAGER } from "./constants";
import {
  deletePlayer,
  listPlayer,
  selectSuperAdminPlayer
} from "./actions";

export const useSuperAdminPlayerList = (params) => {
  const isLoading = useApiLoading(LIST, "get");
  const meta = useApiGet(PAGER, {});
  const list = useApiGet(LIST, []);
  useEffect(() => {
    listPlayer(params);
  }, [params]);
  return [isLoading, list, meta];
};

export const useDeletePlayer = () => {
  const isLoading = useApiLoading(ITEM, "remove");
  const submit = useCallback((id, onSuccess) => {
    deletePlayer(id, onSuccess);
  }, []);
  return [isLoading, submit];
};

export const useSuperAdminPlayerSelected = () => {
  const isLoading = useApiLoading(ITEM, "get");
  const data = useApiGet(ITEM, {});
  return [isLoading, data];
};

export const useSelectSuperAdminPlayer = (id) => {
  useEffect(() => {
    if (!id) {
      req.set(ITEM, null);
      return;
    }
    selectSuperAdminPlayer(id);
  }, [id]);
};
