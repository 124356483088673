import React from "react";
// import React from 'react';
// import PropTypes from 'prop-types';
import SideList from "partial/wrappers/SideList";
// import PageNotFound from "partial/containers/PageNotFound";
import NavTab, { NavTabItem } from "partial/components/NavTab";
import { useRouteMatch, Switch, Route, Redirect } from "react-router-dom";
import MasterAgentHeader from "../components/MasterAgentHeader";
import MasterAgentDetail from "../components/MasterAgentDetail";
import MasterAgentList from "../components/MasterAgentList";
import MasterAgentWallet from "../components/MasterAgentWallet";
import { useMasterAgentSelected } from "../hooks";

function MasterAgentProfile() {
  const renderList = React.useCallback(
    (selected, onSelect) => (
      <MasterAgentList onSelect={onSelect} selected={selected} />
    ),
    []
  );
  const match = useRouteMatch();
  const [isLoading, data] = useMasterAgentSelected();

  return (
    <SideList renderList={renderList}>
      <div className="bg-white">
        <MasterAgentHeader data={data} isLoading={isLoading} />
        <div className="border-b border-gray-200">
          <NavTab>
            <NavTabItem to={`${match.url}/details`} label="Summary" />
            <NavTabItem to={`${match.url}/wallet`} label="Wallet" />
          </NavTab>
        </div>
        {data.id ? (
          <div className="px-4 pt-4 pb-8 sm:px-6 lg:px-8">
            <Switch>
              <Route exact path={`${match.path}/details`}>
                <MasterAgentDetail data={data} />
              </Route>
              <Route path={`${match.path}/wallet`}>
                <MasterAgentWallet data={data} />
              </Route>
              <Redirect to={`${match.url}/details`} />
            </Switch>
          </div>
        ) : null}
      </div>
    </SideList>
  );
}

MasterAgentProfile.propTypes = {};

export default MasterAgentProfile;
