import { req } from "react-reqq-lite";
import { toastSuccess } from "react-geek-toast";
import { transformList } from "./transformers";
import * as c from "./constants";

export const listAdmins = (params) => {
  req.get({
    key: c.ADMIN_LIST,
    url: "/api/superAdmin/admins",
    params: params,
    transform: (res) => (res.data || []).map(transformList),
    onSuccess: (res) => {
      req.set(c.ADMIN_PAGER, res?.response?.meta?.pagination || {});
      req.set(c.ADMIN_FILTER, params);
    },
  });
};

export const selectAdmin = (id) => {
  req.get({
    key: c.ADMIN_ITEM,
    url: `/api/superAdmin/admins/${id}`,
    transform: (res) => transformList(res.data),
  });
};

export const createAdmin = (payload, onSuccess) => {
  req.post({
    key: c.ADMIN_ITEM,
    url: "/api/superAdmin/admins",
    payload,
    onSuccess: (res, state) => {
      toastSuccess("Created!");
      setTimeout(() => {
        listAdmins(state[c.ADMIN_FILTER]);
      }, 1000);
      if (onSuccess) onSuccess(transformList(res?.response?.data));
    },
  });
};

export const updateAdmin = (id, payload, onSuccess) => {
  req.put({
    key: c.ADMIN_ITEM,
    url: `/api/superAdmin/admins/${id}`,
    payload,
    onSuccess: (res, state) => {
      toastSuccess("Updated!");
      setTimeout(() => {
        listAdmins(state[c.ADMIN_FILTER]);
      }, 1000);
      selectAdmin(id);
      if (onSuccess) onSuccess(transformList(res?.response?.data));
    },
  });
};

export const updateAdminEmail = (id, payload, onSuccess) => {
  req.put({
    key: c.ADMIN_ITEM,
    url: `/api/superAdmin/admins/${id}`,
    payload,
    onSuccess: (res, state) => {
      toastSuccess("Updated!");
      setTimeout(() => {
        listAdmins(state[c.ADMIN_FILTER]);
      }, 1000);
      selectAdmin(id);
      if (onSuccess) onSuccess(transformList(res?.response?.data));
    },
  });
};

export const removeAdmin = (id, onSuccess) => {
  req.remove({
    key: c.ADMIN_ITEM,
    url: `/api/superAdmin/admins/${id}`,
    onSuccess: (res, state) => {
      toastSuccess("Removed!");
      setTimeout(() => {
        listAdmins(state[c.ADMIN_FILTER]);
      }, 1000);
      req.set(c.ADMIN_ITEM, null);
      if (onSuccess) onSuccess();
    },
  });
};

export const changeStatus = (id, payload, onSuccess) => {
  req.put({
    key: c.ADMIN_ITEM,
    url: `/api/superAdmin/admins/${id}/change_status`,
    payload,
    onSuccess: (res, state) => {
      toastSuccess("Updated!");
      setTimeout(() => {
        listAdmins(state[c.ADMIN_FILTER]);
      }, 1000);
      selectAdmin(id);
      if (onSuccess) onSuccess(transformList(res?.response?.data));
    },
  });
};
