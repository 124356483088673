import { req, useApiGet, useApiLoading } from "react-reqq-lite";
import { useCallback, useEffect } from "react";
import {
  ITEM,
  LIST,
  PAGER,
  MASTER_AGENT_WALLET_LIST,
  MASTER_AGENT_WALLET_PAGER,
  ADD_DEBIT_FUND_MASTER_AGENT,
  ADD_CREDIT_FUND_MASTER_AGENT,
  CHANGE_STATUS_MASTER_AGENT
} from "./constants";
import {
  listMasterAgent,
  selectMasterAgent,
  changeStatus,
  listMasterAgentWallet,
  addDebitFundAgent,
  addCreditFundAgent,
  updateMasterAgent,
} from "./actions";

export const useMasterAgentList = (params) => {
  const isLoading = useApiLoading(LIST, "get");
  const meta = useApiGet(PAGER, {});
  const list = useApiGet(LIST, []);
  useEffect(() => {
    listMasterAgent(params);
  }, [params]);
  return [isLoading, list, meta];
};

export const useMasterAgentSelected = () => {
  const isLoading = useApiLoading(ITEM, "get");
  const data = useApiGet(ITEM, {});
  return [isLoading, data];
};

export const useSelectMasterAgent = (masterAgentId) => {
  useEffect(() => {
    if (!masterAgentId) {
      req.set(ITEM, null);
      return;
    }
    selectMasterAgent(masterAgentId);
  }, [masterAgentId]);
};

export const useChangeStatus = () => {
  const isLoading = useApiLoading(CHANGE_STATUS_MASTER_AGENT, "put");
  const submit = useCallback((id, payload, onSuccess) => {
    changeStatus(id, payload, onSuccess);
  }, []);
  return [isLoading, submit];
};

export const useMasterAgentWalletList = (params, agentId) => {
  const isLoading = useApiLoading(MASTER_AGENT_WALLET_LIST, "get");
  const meta = useApiGet(MASTER_AGENT_WALLET_PAGER, {});
  const list = useApiGet(MASTER_AGENT_WALLET_LIST, []);
  useEffect(() => {
    listMasterAgentWallet(params, agentId);
  }, [params, agentId]);
  return [isLoading, list, meta];
};

export const useMasterAgentAddDebit = () => {
  const isLoading = useApiLoading(ADD_DEBIT_FUND_MASTER_AGENT, "post");
  const submit = useCallback((agentId, payload, onSuccess) => {
    addDebitFundAgent(agentId, payload, onSuccess);
  }, []);
  return [isLoading, submit];
};

export const useMasterAgentAddCredit = () => {
  const isLoading = useApiLoading(ADD_CREDIT_FUND_MASTER_AGENT, "post");
  const submit = useCallback((agentId, payload, onSuccess) => {
    addCreditFundAgent(agentId, payload, onSuccess);
  }, []);
  return [isLoading, submit];
};

export const useUpdateMasterAgent = () => {
  const isLoading = useApiLoading(ITEM, "put");
  const submit = useCallback((agentId, payload, onSuccess) => {
    updateMasterAgent(agentId, payload, onSuccess);
  }, []);
  return [isLoading, submit];
};
