export const transformSuperMasterAgent = (raw) => {
  return {
    id: `${raw?.id}`,
    name: `${raw?.attributes?.first_name || ""} ${
      raw?.attributes?.last_name || ""
    }`,
    first_name: raw?.attributes?.first_name || "",
    last_name: raw?.attributes?.last_name || "",
    phone_number: raw?.attributes?.phone_number || "",
    email: raw?.attributes?.user?.email || "",
    status: raw?.attributes?.user?.is_active || "",
  };
};
