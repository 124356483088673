import React from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";

const PrivateContext = React.createContext();

const DEFAULT_LABEL = "Admin Panel";

export const ModuleRoute = ({ title, children, ...props }) => {
  const { setActiveModule } = React.useContext(PrivateContext);
  React.useEffect(() => {
    setActiveModule(title);
    return () => {
      setActiveModule(DEFAULT_LABEL);
    };
  }, [title, setActiveModule]);
  return <Route {...props}>{children}</Route>;
};

ModuleRoute.defaultProps = {
  children: null,
};

ModuleRoute.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.instanceOf(Object),
};

export const ModuleLabel = () => {
  const { activeModule } = React.useContext(PrivateContext);
  return <span>{activeModule}</span>;
};

function ModuleWrapper({ children }) {
  const [activeModule, setActiveModule] = React.useState(DEFAULT_LABEL);
  return (
    <PrivateContext.Provider value={{ activeModule, setActiveModule }}>
      {children}
    </PrivateContext.Provider>
  );
}

ModuleWrapper.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
};

export default ModuleWrapper;
