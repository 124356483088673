import React from "react";
// import PropTypes from 'prop-types';
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import PlayerRoomDetail from "../components/PlayerRoomDetail";
import PlayerRoomList from "../components/PlayerRoomList";

function PlayerRoomRoutes() {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${match.path}`} component={PlayerRoomList} />
      <Route
        path={`${match.path}/game-room/:id`}
        component={PlayerRoomDetail}
      />
      <Redirect to={`${match.url}`} />
    </Switch>
  );
}

PlayerRoomRoutes.propTypes = {};

export default PlayerRoomRoutes;
