import { req } from "react-reqq-lite";
import { toastSuccess } from "react-geek-toast";
import {
  FILTER,
  ITEM,
  LIST,
  PAGER,
  ADD_CREDIT_FUND_SUPER_MASTER_AGENT,
  SUPER_MASTER_AGENT_WALLET_LIST,
  SUPER_MASTER_AGENT_WALLET_PAGER,
  ADD_DEBIT_FUND_SUPER_MASTER_AGENT,
} from "./constants";
import { transformList, transformWalletList } from "./transformers";

export const listSuperMasterAgent = (params) => {
  req.get({
    key: LIST,
    url: `/api/admin/superMasterAgents`,
    params: params,
    transform: (res) => (res.data || []).map(transformList),
    onSuccess: (res) => {
      req.set(PAGER, res?.response?.meta?.pagination || {});
      req.set(FILTER, params);
    },
  });
};

export const selectSuperMasterAgent = (id) => {
  req.get({
    key: ITEM,
    url: `/api/admin/superMasterAgents/${id}`,
    onSuccess: (res) => {
      req.set(ITEM, res?.response?.data || {});
    },
  });
};

export const listSuperMasterAgentWallet = (adminId) => {
  req.get({
    key: SUPER_MASTER_AGENT_WALLET_LIST,
    url: `/api/admin/superMasterAgents/${adminId}/transactions`,
    transform: (res) => (res?.data || []).map(transformWalletList),
    onSuccess: (res) => {
      req.set(SUPER_MASTER_AGENT_WALLET_PAGER, res?.response?.meta?.pagination || {});
    },
  });
};

export const addCreditFundSuperMasterAgent = (superSuperMasterAgentId, payload, onSuccess) => {
  req.post({
    key: ADD_CREDIT_FUND_SUPER_MASTER_AGENT,
    url: `/api/admin/superMasterAgents/${superSuperMasterAgentId}/wallet/credit`,
    payload,
    onSuccess: (res, state) => {
      toastSuccess("Successfully Added Credit!");
      setTimeout(() => {
        listSuperMasterAgentWallet(superSuperMasterAgentId);
      }, 1000);
      if (onSuccess) onSuccess(transformList(res?.response?.data));
    },
  });
};

export const addDebitFundSuperMasterAgent = (superSuperMasterAgentId, payload, onSuccess) => {
  req.post({
    key: ADD_DEBIT_FUND_SUPER_MASTER_AGENT,
    url: `/api/admin/superMasterAgents/${superSuperMasterAgentId}/wallet/debit`,
    payload,
    onSuccess: (res, state) => {
      toastSuccess("Successfully Added Debit!");
      setTimeout(() => {
        listSuperMasterAgentWallet(superSuperMasterAgentId);
      }, 1000);
      if (onSuccess) onSuccess(transformList(res?.response?.data));
    },
  });
};
