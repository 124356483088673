import { formatDate } from "helper";

export const transformList = (raw) => ({
  id: raw?.id || "",
  type: raw?.attributes?.type || "",
  amount: raw?.attributes.amount || "",
  date: formatDate(raw?.attributes?.created_at, "MMM DD, YYYY hh:mm A"),
});

export const transformWallet = (raw) => ({
  id: raw?.id || "",
  type: raw?.balance || "",
});
