import React from "react";
// import PropTypes from 'prop-types';
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import SuperAdminAgentDetail from "./SuperAdminAgentDetail";

function SuperAdminAgentRoutes() {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}`} component={SuperAdminAgentDetail} />
      <Redirect to={`${match.url}`} />
    </Switch>
  );
}

SuperAdminAgentRoutes.propTypes = {};

export default SuperAdminAgentRoutes;
