import React, { useState } from "react";
import PropTypes from "prop-types";
import Form from "partial/form/Form";
import FormInput from "partial/form/FormInput";
import Button from "partial/components/Button";
import { showDrawer } from "partial/components/Modal";
import { useCreateRoom, useUpdateRoom } from "../hooks";
import { transformList } from "../transformers";
import { useHandlerList } from "modules/super-admin/handlers/hooks";
import FormSelect from "partial/form/FormSelect";
import _ from "lodash";

export const openRoomModal = (title, data, onSuccess) => {
  showDrawer({
    size: "xs",
    title,
    content: (onClose) => (
      <RoomModal data={data} onClose={onClose} onSuccess={onSuccess} />
    ),
  });
};

function RoomModal({ data, onClose, onSuccess }) {
  const [form, setForm] = React.useState({
    handler_user_id: _.get(data, "attributes.handler_user_id"),
    name: _.get(data, "attributes.name"),
    status: _.get(data, "attributes.is_active") === 1 ? true : false,
    minimum_amount: _.get(data, "attributes.minimum_amount"),
    maximum_amount: _.get(data, "attributes.maximum_amount"),
  });

  const [isCreate, create] = useCreateRoom();
  const [isUpdate, update] = useUpdateRoom();
  const handleSubmit = () => {
    const args = {
      ...form,
      is_active: form.status,
    };
    if (data.id === "undefined") {
      create(args, (newData) => {
        onClose();
        onSuccess(newData);
      });
      return;
    }
    update(data?.id, args, onClose);
  };

  const [, handlerList] = useHandlerList();

  const handlerOptions = handlerList.map((x) => ({
    label: _.get(x, "name"),
    value: _.get(x, "user_id"),
  }));

  const onChange = (e) => {
    setForm({
      ...form,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  return (
    <Form className="divide-y divide-gray-200" onSubmit={handleSubmit}>
      <div>
        <div className="mb-3">
          <FormSelect
            name="handler_user_id"
            onChange={setForm}
            value={form.handler_user_id || ""}
            label="Handler"
            required
            placeholder="Select Handler"
            options={handlerOptions}
          />
        </div>
        <div className="mb-3">
          <FormInput
            name="name"
            onChange={setForm}
            value={form.name || ""}
            label="Room Name"
            required
          />
        </div>
        <div className="mb-3">
          <FormInput
            name="minimum_amount"
            onChange={setForm}
            value={form.minimum_amount || 0}
            label="Minimum Bet"
            required
            type="number"
          />
        </div>
        <div className="mb-3">
          <FormInput
            name="maximum_amount"
            onChange={setForm}
            value={form.maximum_amount || 0}
            label="Maximum Bet"
            required
            type="number"
          />
        </div>
        {data.id !== "undefined" && (
          <div className="mb-3">
            <span className="mr-2">Is Active</span>
            <input
              id="status"
              name="status"
              type="checkbox"
              checked={form.status}
              onChange={onChange}
              className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
            />
          </div>
        )}
      </div>
      <div className="pt-5">
        <div className="flex justify-end">
          <Button
            type="submit"
            primary
            size="lg"
            disabled={isCreate || isUpdate}
          >
            {data.id === "undefined" ? "Add" : "Save"}
          </Button>
        </div>
      </div>
    </Form>
  );
}

RoomModal.defaultProps = {
  data: transformList(),
  onSuccess: false,
};

RoomModal.propTypes = {
  data: PropTypes.instanceOf(Object),
  onSuccess: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onClose: PropTypes.func.isRequired,
};

export default RoomModal;
