import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";
import { HiOutlineChevronLeft, HiOutlineChevronRight } from "react-icons/hi";
import { formatNumber } from "helper";

function PagerPage({ active, page }) {
  const handleChangePage = (e) => {
    e.preventDefault();
    // eslint-disable-next-line
    console.log("go to", page);
  };
  return (
    <a
      key={page}
      href="/"
      onClick={handleChangePage}
      aria-current="page"
      className={cn(
        active
          ? "z-10 bg-indigo-50 border-indigo-500 text-indigo-600"
          : "bg-white border-gray-300 text-gray-500 hover:bg-gray-50",
        "relative inline-flex items-center px-4 py-2 border text-sm font-medium"
      )}
    >
      {page}
    </a>
  );
}

PagerPage.defaultProps = {
  active: false,
};

PagerPage.propTypes = {
  active: PropTypes.bool,
  page: PropTypes.number.isRequired,
};

function Pagination({ value, onChange }) {
  const handlePrev = () => {
    const newPage = value.current_page - 1;
    if (newPage <= 0) return;
    onChange((state) => ({ ...state, page: newPage }));
  };

  const handleNext = () => {
    const newPage = value.current_page + 1;
    if (newPage > value.total_pages) return;
    onChange((state) => ({ ...state, page: newPage }));
  };

  return (
    <div className="px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
      <div className="flex w-full items-center justify-between">
        <div>
          <p className="text-sm text-white">
            <span className="font-medium">
              {formatNumber(value.total_pages, 0)}
            </span>{" "}
            page(s)
          </p>
        </div>
        <div>
          <nav
            className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
            aria-label="Pagination"
          >
            <button
              type="button"
              onClick={handlePrev}
              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only">Previous</span>
              <HiOutlineChevronLeft className="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              type="button"
              disabled
              className="relative inline-flex items-center px-4 py-2 border bg-white border-gray-300 text-sm font-medium"
            >
              <span>{value.current_page}</span>
            </button>
            <button
              type="button"
              onClick={handleNext}
              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only">Next</span>
              <HiOutlineChevronRight className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
}

Pagination.propTypes = {
  value: PropTypes.instanceOf(Object),
  onChange: PropTypes.func,
};

Pagination.defaultProps = {
  onChange: () => {},
  value: {
    current_page: 1,
    total_pages: 1,
    total: 1,
  },
};

export default Pagination;
