import {
  HiSortAscending,
  HiDownload,
  HiSortDescending,
  HiOutlinePencil,
  HiOutlineTrash,
} from "react-icons/hi";
import React from "react";
import cn from "classnames";
import { get } from "lodash";
import PropTypes from "prop-types";
import { downloadArrayToCsv } from "helper";
import Button from "./Button";

const parser = (v, path) => {
  const val = get(v, path, 0);
  if (typeof val === "string") return val.toLowerCase();
  return val;
};

export const sortList = (list, sortBy) => {
  const sort = sortBy.split(":");
  const x = [sort[1] === "asc" ? 1 : -1, sort[1] === "asc" ? -1 : 1];
  const newSorted = [...list];
  newSorted.sort((a, b) =>
    parser(a, sort[0]) > parser(b, sort[0]) ? x[0] : x[1]
  );
  return newSorted;
};

export const tableAction = (label, onClick) => {
  const handleClick = (row) => (e) => {
    e.preventDefault();
    onClick(row);
  };
  return {
    id: "action",
    label: <span className="sr-only">Action</span>,
    key: (row) => (
      <>
        {row.type === "BET" && (
          <a
            href="/"
            className="text-primary-600 hover:text-primary-900"
            onClick={handleClick(row)}
          >
            {label}
          </a>
        )}
      </>
    ),
    width: 100,
  };
};

export const tableEditDelete = (onEdit, onDelete) => {
  const handleEditClick = (row) => (e) => {
    e.preventDefault();
    onEdit(row);
  };
  const handleDeleteClick = (row) => (e) => {
    e.preventDefault();
    onDelete(row);
  };
  return {
    id: "action",
    label: <span className="sr-only">Action</span>,
    key: (row) => (
      <div className="space-x-2 text-gray-600 flex items-center">
        <a href="/" className="link-default" onClick={handleEditClick(row)}>
          <HiOutlinePencil className="w-3 h-3" />
          <span>Edit</span>
        </a>
        <a href="/" className="link-danger" onClick={handleDeleteClick(row)}>
          <HiOutlineTrash className="w-3 h-3" />
          <span>Delete</span>
        </a>
      </div>
    ),
    width: 100,
  };
};

export const tableDelete = (onDelete) => {
  const handleDeleteClick = (row) => (e) => {
    e.preventDefault();
    onDelete(row);
  };
  return {
    id: "action",
    label: <span className="sr-only">Action</span>,
    key: (row) => (
      <div className="space-x-2 text-gray-600 flex items-center">
        <a href="/" className="link-danger" onClick={handleDeleteClick(row)}>
          <HiOutlineTrash className="w-3 h-3" />
          <span>Delete</span>
        </a>
      </div>
    ),
    width: 100,
  };
};

function Table({
  format,
  data,
  downloadCsv,
  downloadFilename,
  isLoading,
  subLabel,
  sortKey,
  onSort,
  sort,
}) {
  const [sortBy, orderBy] = React.useMemo(() => (sort || "").split(":"), [
    sort,
  ]);
  const handleDownload = () => {
    const downloadData =
      typeof downloadCsv === "function" ? data.map(downloadCsv) : data;
    downloadArrayToCsv(downloadData, downloadFilename);
  };
  const handleSort = (col) => (e) => {
    e.preventDefault();
    const switchOrder = orderBy === "asc" ? "desc" : "asc";
    onSort((state) => ({
      ...state,
      [sortKey]: `${col.sortKey}:${
        sortBy !== col.sortKey ? "asc" : switchOrder
      }`,
    }));
  };
  return (
    <div className="flex flex-col flex-grow rounded-lg overflow-hidden">
      <div className="relative flex flex-grow">
        <div className="overflow-auto absolute h-full w-full">
          {isLoading ? (
            <div className="grid grid-cols-2 gap-3 p-3">
              <div className="animate-pulse bg-gray-200 h-8 w-full rounded" />
              <div className="animate-pulse bg-gray-200 h-8 w-full rounded" />
              <div className="col-span-2 animate-pulse bg-gray-200 h-8 w-full rounded" />
              <div className="col-span-2 animate-pulse bg-gray-200 h-8 w-full rounded" />
              <div className="col-span-2 animate-pulse bg-gray-200 h-8 w-full rounded" />
            </div>
          ) : (
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50 sticky top-0">
                <tr>
                  {format.map((col) => {
                    return (
                      <th
                        key={col.id || col.key}
                        scope="col"
                        width={col.width || "initial"}
                      >
                        {onSort && col.sortKey ? (
                          <a
                            href="/"
                            onClick={handleSort(col)}
                            className={cn(
                              "whitespace-nowrap px-6 py-3 text-left text-xs font-medium tracking-wider uppercase flex items-center space-x-2 hover:bg-gray-100 transition",
                              sortBy === col.sortKey
                                ? "text-primary-500"
                                : "text-gray-500"
                            )}
                          >
                            <span>{col.label}</span>
                            {sortBy === col.sortKey && orderBy === "desc" ? (
                              <HiSortAscending className="w-4 h-4" />
                            ) : (
                              <HiSortDescending className="w-4 h-4" />
                            )}
                          </a>
                        ) : (
                          <span className="px-6 py-3 text-left text-sm font-medium text-gray-500 tracking-wider uppercase flex items-center space-x-2">
                            {col.label}
                          </span>
                        )}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {data.map((row) => (
                  <tr
                    key={row.id}
                    // className={cn("group cursor-pointer hover:bg-gray-50", index % 2 === 0 ? 'bg-white' : 'bg-gray-50')}
                    className={cn(
                      "group cursor-pointer border-t border-b hover:bg-gray-50 border-gray-100 transition"
                    )}
                  >
                    {format.map((col) => {
                      const content = (() => {
                        if (typeof col.key === "function") return col.key(row);
                        return get(row, col.key);
                      })();
                      return (
                        <td
                          key={`${row.id}-${col.id}`}
                          className={cn(
                            "px-6 py-3 text-left text-sm font-medium text-gray-500",
                            col.className || ""
                          )}
                          width={col.width || "initial"}
                        >
                          {content}
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
      {(downloadCsv || subLabel) && (
        <div className="bg-gray-50 flex items-center justify-between">
          {downloadCsv ? (
            <div className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">
              <Button size="xs" icon={HiDownload} onClick={handleDownload}>
                Download CSV
              </Button>
            </div>
          ) : (
            <span />
          )}
          {subLabel ? (
            <div className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">
              {subLabel}
            </div>
          ) : (
            <span />
          )}
        </div>
      )}
    </div>
  );
}

Table.defaultProps = {
  downloadFilename: "download.csv",
  downloadCsv: false,
  isLoading: false,
  sortKey: "sort",
  onSort: false,
  subLabel: "",
  sort: "",
  data: [],
};

Table.propTypes = {
  onSort: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  format: PropTypes.instanceOf(Array).isRequired,
  data: PropTypes.instanceOf(Array),
  downloadFilename: PropTypes.string,
  downloadCsv: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  subLabel: PropTypes.string,
  sortKey: PropTypes.string,
  isLoading: PropTypes.bool,
  sort: PropTypes.string,
};

export default Table;
