export const transformList = (raw) => {
  return {
    id: `${raw?.id}`,
    name: raw?.attributes?.session_name || "",
    number_of_rounds: raw?.attributes?.number_of_rounds || "",
    url: raw?.attributes?.url || "",
    race_description: raw?.attributes?.race_description || "",
    type_video: raw?.attributes?.type_video || "",
    state: raw?.attributes?.state,
    room: raw?.attributes?.room || {},
  };
};

export const transformRoom = (raw) => {
  return {
    id: `${raw?.id}`,
    name: raw?.attributes?.name || "",
  };
};
