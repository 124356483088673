import React from "react";
import PropTypes from "prop-types";
import { HiPlus } from "react-icons/hi";
import List from "partial/components/List";
import Button from "partial/components/Button";
import Pagination from "partial/components/Pagination";
import { openGameManagerModal } from "../modals/GameManagerModal";
import GameManagerListFilter from "./GameManagerListFilter";
import { useGameManagerList, useSelectGameManager } from "../hooks";

function GameManagerList({ selected, onSelect }) {
  const [filter, setFilter] = React.useState({
    email: "",
    page: 1,
  });
  const [isLoading, list, pager] = useGameManagerList(filter);
  const handleOpenAdd = () => {
    openGameManagerModal("Add GameManager", undefined, onSelect);
  };
  React.useEffect(() => {
    if (window.innerWidth <= 1024) return;
    if (!selected && list[0]) onSelect(list[0]);
  }, [selected, list, onSelect]);
  useSelectGameManager(selected?.id);
  return (
    <>
      <div className="px-6 pt-6 pb-4">
        <div className="float-right">
          <Button icon={HiPlus} onClick={handleOpenAdd}>
            Add
          </Button>
        </div>
        <p className="mt-1 text-sm text-gray-600">
          Total of {pager?.total || 0} GameManager(s)
        </p>
        <GameManagerListFilter onSubmit={setFilter} value={filter} />
      </div>
      <div className="relative flex-grow">
        <div className="absolute h-full w-full top-0 left-0 overflow-auto">
          <List
            label="GameManager"
            data={list}
            selected={selected}
            onSelect={onSelect}
            isLoading={isLoading}
            subTitleKey="email"
            // renderBadge={renderBadge}
          />
        </div>
      </div>
      <Pagination onChange={setFilter} value={pager} />
    </>
  );
}

GameManagerList.defaultProps = {
  selected: null,
};

GameManagerList.propTypes = {
  selected: PropTypes.instanceOf(Object),
  onSelect: PropTypes.func.isRequired,
};

export default GameManagerList;
