import React from "react";
import PropTypes from "prop-types";
import Form from "partial/form/Form";
import FormInput from "partial/form/FormInput";
import Button from "partial/components/Button";
import { showDrawer } from "partial/components/Modal";
import { useCreateGameManager, useUpdateGameManager } from "../hooks";
import { transformList } from "../transformers";
import FormInputPassword from "partial/form/FormInputPassword";

export const openGameManagerModal = (title, data, onSuccess) => {
  showDrawer({
    size: "xs",
    title,
    content: (onClose) => (
      <GameManagerModal data={data} onClose={onClose} onSuccess={onSuccess} />
    ),
  });
};

function GameManagerModal({ data, onClose, onSuccess }) {
  const [form, setForm] = React.useState(data);
  const [isCreate, create] = useCreateGameManager();
  const [isUpdate, update] = useUpdateGameManager();
  const handleSubmit = () => {
    if (data.id === "undefined") {
      create(form, (newData) => {
        onClose();
        onSuccess(newData);
      });
      return;
    }
    update(data?.id, form, onClose);
  };
  return (
    <Form className="divide-y divide-gray-200" onSubmit={handleSubmit}>
      <div>
        <div className="mb-3">
          <FormInput
            name="first_name"
            onChange={setForm}
            value={form.first_name}
            label="First Name"
            required
          />
        </div>
        <div className="mb-3">
          <FormInput
            name="last_name"
            onChange={setForm}
            value={form.last_name}
            label="Last Name"
            required
          />
        </div>
        {data.id === "undefined" && (
          <>
            <div className="mb-3">
              <FormInput
                name="email"
                onChange={setForm}
                value={form.email}
                label="Email"
                required
                type="email"
              />
            </div>
            <div className="mb-3">
              <FormInputPassword
                name="password"
                onChange={setForm}
                value={form.password}
                label="Password"
                required
                withIcon={true}
              />
            </div>
            <div className="mb-3">
              <FormInputPassword
                name="password_confirmation"
                onChange={setForm}
                value={form.password_confirmation}
                label="Confirm Password"
                required
                withIcon={true}
              />
            </div>
          </>
        )}
      </div>
      <div className="pt-5">
        <div className="flex justify-end">
          <Button
            type="submit"
            primary
            size="lg"
            disabled={isCreate || isUpdate}
          >
            {data.id === "undefined" ? "Add" : "Save"}
          </Button>
        </div>
      </div>
    </Form>
  );
}

GameManagerModal.defaultProps = {
  data: transformList(),
  onSuccess: false,
};

GameManagerModal.propTypes = {
  data: PropTypes.instanceOf(Object),
  onSuccess: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onClose: PropTypes.func.isRequired,
};

export default GameManagerModal;
