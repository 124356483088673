import { configureApi } from "react-reqq-lite";
import Cookie from "js-cookie";
import { get } from "lodash";
import { toastError } from "react-geek-toast";
import history from "./history";

const store = configureApi({
  endpoint: process.env.REACT_APP_END_POINT,
  requestHeaders: () => {
    const token = Cookie.get("_token");
    if (!token)
      return {
        "Content-Type": "application/json",
      };
    return {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
  },
  timeout: 180000,
  onError: (err) => {
    const err_message = get(err, "response.message");
    if (err.status === 401) {
      const message = get(
        err,
        `response.error.${Object.keys(get(err, "response.error") || [])[0]}`
      );
      toastError(
        message ||
        err?.response?.message ||
        err?.response?.data?.message ||
        "Unauthorized!"
      );
      if (err?.response?.error === "token_expired") {
        history.push("/logout");
      }
      return;
    }
    if (err.status === 422) {
      const message = get(
        err,
        `response.messages.${Object.keys(get(err, "response.messages") || [])[0]
        }`
      );
      toastError(
        get(err, "response.message") ||
        message ||
        get(err, "response.data.message") ||
        "Unprocessable Entity!"
      );
      return;
    }
    if (err.status === 400) {
      toastError(err_message || "Bad Request!");
      return;
    }
    if (err.status === 404) {
      const message = get(
        err,
        `response.error.${Object.keys(get(err, "response.error") || [])[0]}`
      );
      toastError(
        message ||
        err?.response?.message ||
        err?.response?.data?.message ||
        "Unauthorized!"
      );
      if (err?.response?.error === "token_expired") {
        history.push("/logout");
      }
      if (
        err?.response?.data?.message === "There is no currently live session."
      ) {
        history.push("/player/room");
      }
      return;
    }
    if (err.status === 403) {
      if (
        err?.response?.message === "Token Expired" ||
        err_message === "Token Expired"
      ) {
        Cookie.remove("_token");
        Cookie.remove("_profile");
        Cookie.remove("_userType");
        Cookie.remove("_user");
        Cookie.remove("_sessionId");
        history.push("/logout");
      }
      const message = get(
        err,
        `response.error.${Object.keys(get(err, "response.error") || [])[0]}`
      );
      toastError(
        message ||
        err?.response?.message ||
        err?.response?.data?.message ||
        "Unauthorized!"
      );
      if (err?.response?.error === "token_expired") {
        history.push("/logout");
      }
      return;
    }
    if (err.status === 0) {
      toastError("Unable to connect to web service");
      return;
    }
    console.warn("unhandled error", err); // eslint-disable-line
  },
});

export default store;
