import React from "react";
import PropTypes from "prop-types";
import { toastError } from "react-geek-toast";
import FormInput from "partial/form/FormInputWithIcon";

function OverrideModal({ onSubmit, onClose }) {
  const [form, setForm] = React.useState({ pin: "" });
  const handleSubmit = (e) => {
    e.preventDefault();
    if (form.pin !== "123456") {
      setForm({ pin: "" });
      toastError("Invalid PIN!");
      return;
    }
    onClose();
    setTimeout(onSubmit, 300);
  };
  return (
    <>
      <form className="p-3" onSubmit={handleSubmit}>
        <div className="mb-2">
          <FormInput
            name="pin"
            onChange={setForm}
            value={form.pin}
            label="Enter 2-Factor PIN"
          />
        </div>
        <div className="flex justify-center">
          <button className="btn danger" type="submit">
            Override
          </button>
        </div>
      </form>
    </>
  );
}

OverrideModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default OverrideModal;
