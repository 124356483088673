import { useEffect, useCallback } from "react";
import { req, useApiGet, useApiLoading } from "react-reqq-lite";
import * as actions from "./actions";
import * as c from "./constants";

export const useMasterAgentList = (params) => {
  const isLoading = useApiLoading(c.MASTER_AGENT_LIST, "get");
  const data = useApiGet(c.MASTER_AGENT_LIST, []);
  const pager = useApiGet(c.MASTER_AGENT_PAGER, {});
  useEffect(() => {
    actions.listMasterAgents(params);
  }, [params]);
  return [isLoading, data, pager];
};

export const useMasterAgentSelected = () => {
  const isLoading = useApiLoading(c.MASTER_AGENT_ITEM, "get");
  const data = useApiGet(c.MASTER_AGENT_ITEM, {});
  return [isLoading, data];
};

export const useSelectMasterAgent = (id) => {
  useEffect(() => {
    if (!id) {
      req.set(c.MASTER_AGENT_ITEM, null);
      return;
    }
    actions.selectMasterAgent(id);
  }, [id]);
};

export const useCreateMasterAgent = () => {
  const isLoading = useApiLoading(c.MASTER_AGENT_ITEM, "post");
  const submit = useCallback((payload, onSuccess) => {
    actions.createMasterAgent(payload, onSuccess);
  }, []);
  return [isLoading, submit];
};

export const useUpdateMasterAgent = () => {
  const isLoading = useApiLoading(c.MASTER_AGENT_ITEM, "put");
  const submit = useCallback((id, payload, onSuccess) => {
    actions.updateMasterAgent(id, payload, onSuccess);
  }, []);
  return [isLoading, submit];
};

export const useUpdateMasterAgentEmail = () => {
  const isLoading = useApiLoading(c.MASTER_AGENT_ITEM, "put");
  const submit = useCallback((id, payload, onSuccess) => {
    actions.updateMasterAgentEmail(id, payload, onSuccess);
  }, []);
  return [isLoading, submit];
};

export const useChangeStatus = () => {
  const isLoading = useApiLoading(c.MASTER_AGENT_ITEM, "put");
  const submit = useCallback((id, payload, onSuccess) => {
    actions.changeStatus(id, payload, onSuccess);
  }, []);
  return [isLoading, submit];
};

export const useRemoveMasterAgent = () => {
  const isLoading = useApiLoading(c.MASTER_AGENT_ITEM, "remove");
  const submit = useCallback((id, onSuccess) => {
    actions.removeMasterAgent(id, onSuccess);
  }, []);
  return [isLoading, submit];
};
