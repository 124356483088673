import React from "react";
import ToastMarker from "react-geek-toast";
import { useApiGet } from "react-reqq-lite";
import { useAuth } from "modules/auth/hooks";
import Logout from "modules/auth/containers/Logout";
import { Link, Route, Switch } from "react-router-dom";
import { AUTHENTICATION } from "modules/auth/constants";
import {
  AlertMarker,
  LightboxMarker,
  ModalMarker,
} from "partial/components/Modal";
import ErrorBoundary from "partial/containers/ErrorBoundary";
import Public from "./Public";
import SuperAdminRoutes from "SuperAdminRoutes";
import PlayerRoutes from "PlayerRoutes";
import MasterAgentRoutes from "MasterAgentRoutes";
import AgentRoutes from "AgentRoutes";
import AdminRoutes from "AdminRoutes";
import HandlerRoutes from "HandlerRoutes";
import SuperMasterAgentRoutes from "SuperMasterAgentRoutes";

export const PURGE_IGNORE = () => <input type="tel" />;

const PrivateRoutes = React.memo(() => {
  const auth = useApiGet(AUTHENTICATION, {});

  if (auth.userType === "SuperAdmin") return <SuperAdminRoutes />;

  if (auth.userType === "Player") return <PlayerRoutes />;

  if (auth.userType === "MasterAgent") return <MasterAgentRoutes />;

  if (auth.userType === "Agent") return <AgentRoutes />;

  if (auth.userType === "Handlers") return <HandlerRoutes />;

  if (auth.userType === "Admin") return <AdminRoutes />;

  if (auth.userType === "SuperMasterAgent") return <SuperMasterAgentRoutes />;

  return (
    <Switch>
      <Route path="/logout" component={Logout} />
      <Route>
        <div>
          Unknown user type.{" "}
          <Link className="text-primary-500 hover:underline" to="/logout">
            Logout
          </Link>
        </div>
      </Route>
    </Switch>
  );
});

function App() {
  const [isAppReady, isAuthenticated] = useAuth();
  if (!isAppReady) return null;
  return (
    <ErrorBoundary>
      <ToastMarker />
      <AlertMarker />
      <LightboxMarker />
      <ModalMarker />
      <Switch>
        {/* <Route path="/p/print-sample/:doc_id" component={PrintContainer} /> */}
        <Route>
          {isAuthenticated ? (
            // <Suspense fallback={<div>Loading...</div>}>
            <PrivateRoutes />
          ) : (
            // </Suspense>
            <Public />
          )}
        </Route>
      </Switch>
    </ErrorBoundary>
  );
}

export default App;
