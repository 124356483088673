import React from "react";
import Cookie from "js-cookie";
import history from "setup/history";
import Form from "partial/form/Form";
import logo from "../../../assets/images/logo.png";
import * as actions from "../actions";

function Verification() {
  const email = Cookie.get("_email");

  const handleSubmit = () => {
    actions.verify(email);
  };

  const handleOnClick = (e) => {
    e.preventDefault();
    history.push("/");
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div className="min-h-screen bg-white flex">
        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img className="h-16 w-auto" src={logo} alt="Karera Logo" />
              <h1 className="text-lg md:text-xl font-bold leading-tight mt-3 mb-4">
                Email Verification
              </h1>
              <p className="text-sm font-medium text-gray-700 mt-3">
                We have sent verification link to your email
              </p>
              <p className="text-sm font-medium text-gray-700 mt-3">
                <b>{email}</b>.
              </p>
            </div>

            <div className="mt-8">
              <div className="mt-3">
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                >
                  Check Email
                </button>
              </div>
            </div>
            <div className="flex items-center justify-center space-x-1 mt-4">
              <span>Back to Login?</span>
              <button onClick={handleOnClick} className="btn-link">
                Sign in
              </button>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
}

Verification.propTypes = {};

export default Verification;
