import React from "react";
import PropTypes from "prop-types";
import List from "partial/components/List";
import { usePlayerList, useSelectPlayer } from "../hooks";
import PlayerListFilter from "./PlayerListFilter";
import Pagination from "partial/components/Pagination";

function PlayerList({ selected, onSelect }) {
  const [filter, setFilter] = React.useState({
    email: "",
    page: 1,
  });
  const [isLoading, list, pager] = usePlayerList(filter);

  React.useEffect(() => {
    if (window.innerWidth <= 1024) return;
    if (!selected && list[0]) onSelect(list[0]);
  }, [selected, list, onSelect]);
  useSelectPlayer(selected?.id);

  return (
    <>
      <div className="px-6 pt-6 pb-4">
        <h2 className="text-lg font-medium text-gray-900">Players</h2>
        <p className="mt-1 text-sm text-gray-600">
          Total of {pager?.total || 0} user(s)
        </p>
        <PlayerListFilter onSubmit={setFilter} value={filter} />
      </div>
      <div className="relative flex-grow">
        <div className="absolute h-full w-full top-0 left-0 overflow-auto">
          <List
            label="Player"
            data={list}
            selected={selected}
            onSelect={onSelect}
            isLoading={isLoading}
          />
        </div>
      </div>
      <Pagination onChange={setFilter} value={pager} />
    </>
  );
}

PlayerList.defaultProps = {
  selected: null,
};

PlayerList.propTypes = {
  selected: PropTypes.instanceOf(Object),
  onSelect: PropTypes.func.isRequired,
};

export default PlayerList;
