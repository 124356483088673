import Cookie from "js-cookie";
import history from "setup/history";
import { req } from "react-reqq-lite";
import { closeModal } from "partial/components/Modal";
// import { getUserTypeFromToken } from "./transformers";
import * as c from "./constants";

export const login = (payload) => {
  req.post({
    key: c.LOGIN,
    url: "/api/auth",
    payload,
    onSuccess: (res) => {
      const token = res?.response?.data?.token;
      Cookie.set("_token", token);
      Cookie.set("_userType", res?.response?.data?.user?.profile_type);
      Cookie.set("_profile", res?.response?.data?.user?.profile);
      Cookie.set("_user", res?.response?.data?.user);
      req.set(c.AUTHENTICATION, {
        isAuthenticated: true,
        userType: res?.response?.data?.user?.profile_type,
        profile: res?.response?.data?.user?.profile,
        user: res?.response?.data?.user,
      });
    },
  });
};

export const logout = () => {
  Cookie.remove("_token");
  Cookie.remove("_profile");
  Cookie.remove("_userType");
  Cookie.remove("_user");
  Cookie.remove("_sessionId");
  closeModal();
  req.set(c.AUTHENTICATION, {
    isAuthenticated: false,
    userType: null,
    profile: {},
    isFirstLogin: null,
  });
};

export const register = (payload) => {
  req.post({
    key: c.REGISTER,
    url: "/api/v1/register",
    payload,
    onSuccess: (res) => {
      const email = res?.response?.data?.email;
      Cookie.set("_email", email);
      history.push("/check-email");
    },
  });
};

export const verify = (payload) => {
  req.get({
    key: c.VERIFY,
    url: "/api/v1/verify",
    payload,
    onSuccess: (res) => {
      history.push("/");
    },
  });
};
