import { useEffect, useCallback } from "react";
import { req, useApiGet, useApiLoading } from "react-reqq-lite";
import * as actions from "./actions";
import * as c from "./constants";

export const useWithdrawRequestList = (params) => {
  const isLoading = useApiLoading(c.WITHDRAW_REQUEST_LIST, "get");
  const data = useApiGet(c.WITHDRAW_REQUEST_LIST, []);
  const pager = useApiGet(c.WITHDRAW_REQUEST_PAGER, {});
  useEffect(() => {
    actions.listWithdrawRequests(params);
  }, [params]);
  return [isLoading, data, pager];
};

export const useWithdrawRequestSelected = () => {
  const isLoading = useApiLoading(c.WITHDRAW_REQUEST_ITEM, "get");
  const data = useApiGet(c.WITHDRAW_REQUEST_ITEM, {});
  return [isLoading, data];
};

export const useSelectWithdrawRequest = (id) => {
  useEffect(() => {
    if (!id) {
      req.set(c.WITHDRAW_REQUEST_ITEM, null);
      return;
    }
    actions.selectWithdrawRequest(id);
  }, [id]);
};

export const useCreateWithdrawRequest = () => {
  const isLoading = useApiLoading(c.WITHDRAW_REQUEST_ITEM, "post");
  const submit = useCallback((payload, onSuccess) => {
    actions.createWithdrawRequest(payload, onSuccess);
  }, []);
  return [isLoading, submit];
};

export const useUpdateWithdrawRequest = () => {
  const isLoading = useApiLoading(c.WITHDRAW_REQUEST_ITEM, "put");
  const submit = useCallback((id, payload, onSuccess) => {
    actions.updateWithdrawRequest(id, payload, onSuccess);
  }, []);
  return [isLoading, submit];
};

export const useUpdateWithdrawRequestEmail = () => {
  const isLoading = useApiLoading(c.WITHDRAW_REQUEST_ITEM, "put");
  const submit = useCallback((id, payload, onSuccess) => {
    actions.updateWithdrawRequestEmail(id, payload, onSuccess);
  }, []);
  return [isLoading, submit];
};

export const useApproveCashout = () => {
  const isLoading = useApiLoading(c.WITHDRAW_REQUEST_ITEM, "put");
  const submit = useCallback((id, payload, onSuccess) => {
    actions.approveCashout(id, payload, onSuccess);
  }, []);
  return [isLoading, submit];
};

export const useRemoveWithdrawRequest = () => {
  const isLoading = useApiLoading(c.WITHDRAW_REQUEST_ITEM, "remove");
  const submit = useCallback((id, onSuccess) => {
    actions.removeWithdrawRequest(id, onSuccess);
  }, []);
  return [isLoading, submit];
};
