import React, { useEffect, useState } from "react";
import Card from "partial/components/Card";
import { openEditProfileModal } from "partial/modals/EditProfileModal";
import { useProfileSelected } from "partial/modals/hooks";
import { get } from "lodash";
import Button from "partial/components/Button";
import { HiChevronLeft } from "react-icons/hi";
import { useHistory } from "react-router-dom";

function Profile() {
  const [, profile] = useProfileSelected();
  const history = useHistory();

  const [arrayData, setFormArrayData] = useState(profile.data || {});

  const handleOpenEdit = () => {
    openEditProfileModal("Edit Profile", arrayData.profile, "profile");
  };

  const handleChangePassword = () => {
    openEditProfileModal("Update Password", arrayData.profile, "password");
  };

  useEffect(() => {
    setFormArrayData(profile.data);
  }, [profile.data]);

  const handleOnBack = (e) => {
    e.preventDefault();
    history.push("/");
  };

  return (
    <div className="py-6">
      <div className="max-w-7xl mx-auto w-full px-4 sm:px-6 md:px-8 mb-2">
        <Button onClick={handleOnBack} size="sm" icon={HiChevronLeft}>
          Back
        </Button>
      </div>
      <div className="max-w-7xl w-full mx-auto px-4 sm:px-6 md:px-8 grid grid-cols-1 sm:grid-cols-2 gap-2">
        <Card label="Details">
          <dl className="sm:divide-y sm:divide-gray-200 px-4">
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Account</dt>
              <dd className="mt-1 text-base text-gray-900 font-semibold sm:mt-0 sm:col-span-2 text-right border-b border-gray-100 sm:border-white sm:pb-0 pb-2">
                {get(arrayData, "profile.first_name")}{" "}
                {get(arrayData, "profile.last_name")}
                <span className="flex-grow">&nbsp;</span>
                <span className="ml-4 flex-shrink-0">
                  <button
                    type="button"
                    className="bg-white rounded-md font-medium text-primary-600 hover:text-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                    onClick={handleOpenEdit}
                  >
                    Update Account
                  </button>
                </span>
              </dd>
              <dt className="text-sm font-medium text-gray-500">
                Contact Number
              </dt>
              <dd className="mt-1 text-base text-gray-900 font-semibold sm:mt-0 sm:col-span-2 text-right border-b border-gray-100 sm:border-white sm:pb-0 pb-2">
                {get(arrayData, "profile.phone_number")}
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Type</dt>
              <dd className="mt-1 text-base text-gray-900 font-semibold sm:mt-0 sm:col-span-2 text-right border-b border-gray-100 sm:border-white sm:pb-0 pb-2">
                {get(arrayData, "profile.profile_type")}
              </dd>
            </div>
          </dl>
        </Card>
        <Card label="Security">
          <dl className="sm:divide-y sm:divide-gray-200 px-4">
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Password</dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <span className="flex-grow">&nbsp;</span>
                <span className="ml-4 flex-shrink-0">
                  <button
                    type="button"
                    className="bg-white rounded-md font-medium text-primary-600 hover:text-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                    onClick={handleChangePassword}
                  >
                    Change Password
                  </button>
                </span>
              </dd>
            </div>
          </dl>
        </Card>
      </div>
    </div>
  );
}

Profile.propTypes = {};

export default Profile;
