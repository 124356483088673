import React from "react";
import PropTypes from "prop-types";
import { HiUser } from "react-icons/hi";
import cn from "classnames";
import ThumbIcon from "partial/components/ThumbIcon";

function WithdrawRequestHeader({ isLoading, data }) {
  return (
    <div className="relative z-10 mb-4">
      <div>
        <div
          className={cn(
            "bg-gradient-to-br from-gray-200 to-gray-400 h-28 w-full object-contain object-center bg-center bg-cover lg:h-32"
          )}
          style={{
            backgroundImage: data?.banner ? `url(${data?.banner})` : null,
          }}
        />
      </div>
      <div className="max-w-4xl mx-auto px-4 sm:px-6 md:flex md:items-start md:justify-between md:space-x-5 lg:max-w-full lg:px-8 -mt-24 sm:-mt-20">
        <div className="flex items-center sm:items-start space-x-5">
          <div className="flex-shrink-0">
            <div className="relative">
              <ThumbIcon
                className="h-20 w-20 sm:h-28 sm:w-28 rounded-2xl p-1"
                url={data?.thumbnail_url}
                alt={data?.name}
                icon={data?.name ? null : HiUser}
                iconClassName="h-12 w-12"
              />
              <span
                className="absolute inset-0  rounded-full"
                aria-hidden="true"
              />
            </div>
          </div>
          <div className="mt-0 sm:mt-1">
            <h1 className="text-xl sm:text-2xl font-bold text-gray-900 line-clamp-1">
              {data?.name}
            </h1>
            <p className="text-xs sm:text-sm font-medium text-gray-500">
              {data?.mobile_number}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

WithdrawRequestHeader.defaultProps = {
  isLoading: false,
};

WithdrawRequestHeader.propTypes = {
  isLoading: PropTypes.bool,
  data: PropTypes.instanceOf(Object).isRequired,
};

export default WithdrawRequestHeader;
