import React from "react";
// import React from 'react';
// import PropTypes from 'prop-types';
import SideList from "partial/wrappers/SideList";
import NavTab, { NavTabItem } from "partial/components/NavTab";
import { useRouteMatch, Switch, Route, Redirect } from "react-router-dom";
import WithdrawRequestHeader from "../components/WithdrawRequestHeader";
import WithdrawRequestContent from "../components/WithdrawRequestContent";
import WithdrawRequestList from "../components/WithdrawRequestList";
import { useWithdrawRequestSelected } from "../hooks";

function WithdrawRequestDetail() {
  const renderList = React.useCallback(
    (selected, onSelect) => (
      <WithdrawRequestList onSelect={onSelect} selected={selected} />
    ),
    []
  );
  const match = useRouteMatch();
  const [isLoading, data] = useWithdrawRequestSelected();

  return (
    <>
      <SideList renderList={renderList}>
        <div className="bg-white">
          <WithdrawRequestHeader data={data} isLoading={isLoading} />
          <div className="border-b border-gray-200">
            <NavTab>
              <NavTabItem to={`${match.url}/details`} label="Details" />
            </NavTab>
          </div>
          {data.id ? (
            <div className="px-4 pt-4 pb-8 sm:px-6 lg:px-8">
              <Switch>
                <Route path={`${match.path}/details`}>
                  <WithdrawRequestContent data={data} />
                </Route>
                <Redirect to={`${match.url}/details`} />
              </Switch>
            </div>
          ) : null}
        </div>
      </SideList>
    </>
  );
}

WithdrawRequestDetail.propTypes = {};

export default WithdrawRequestDetail;
