import React from "react";
import PropTypes from "prop-types";
import Form from "partial/form/Form";
import FormInput from "partial/form/FormInput";
import Button from "partial/components/Button";
import { showDrawer } from "partial/components/Modal";
import { useCreateMasterAgent, useUpdateMasterAgent } from "../hooks";
import { transformMasterAgent } from "../transformers";
import ReactInputMask from "react-input-mask";
import FormInputPassword from "partial/form/FormInputPassword";

export const openMasterAgentModal = (title, data, onSuccess) => {
  showDrawer({
    size: "xs",
    title,
    content: (onClose) => (
      <MasterAgentModal data={data} onClose={onClose} onSuccess={onSuccess} />
    ),
  });
};

function MasterAgentModal({ data, onClose, onSuccess }) {
  const [form, setForm] = React.useState(data);
  const [isCreate, create] = useCreateMasterAgent();
  const [isUpdate, update] = useUpdateMasterAgent();
  const handleSubmit = () => {
    if (data.id === "undefined") {
      create(form, (newData) => {
        onClose();
        onSuccess(newData);
      });
      return;
    }
    update(data?.id, form, onClose);
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };
  return (
    <Form className="divide-y divide-gray-200" onSubmit={handleSubmit}>
      <div>
        {data.id === "undefined" && (
          <div className="mb-3">
            <FormInput
              name="email"
              onChange={setForm}
              value={form.email}
              label="Email"
              required
              type="email"
            />
          </div>
        )}
        <div className="mb-3">
          <FormInput
            name="first_name"
            onChange={setForm}
            value={form.first_name}
            label="First Name"
            required
          />
        </div>
        <div className="mb-3">
          <FormInput
            name="last_name"
            onChange={setForm}
            value={form.last_name}
            label="Last Name"
            required
          />
        </div>
        <div className="mb-3">
          <div className="relative border rounded-md px-3 py-2 shadow-sm focus-within:ring-1 bg-white border-gray-300 focus-within:ring-primary-600 focus-within:border-primary-600">
            <label
              htmlFor="phone"
              className="block text-xs font-medium text-gray-900"
            >
              Mobile Number
            </label>
            <ReactInputMask
              required
              type="text"
              mask="09999999999"
              maskChar=""
              className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-base focus:outline-none font-semibold"
              name="phone_number"
              value={form.phone_number}
              onChange={handleOnChange}
              placeholder="0xxxxxxxxxx"
            />
          </div>
        </div>
        {data.id === "undefined" && (
          <>
            <div className="mb-3">
              <FormInputPassword
                name="password"
                onChange={setForm}
                value={form.password}
                label="Password"
                required
                withIcon={true}
              />
            </div>
            <div className="mb-3">
              <FormInputPassword
                name="password_confirmation"
                onChange={setForm}
                value={form.password_confirmation}
                label="Confirm Password"
                required
                withIcon={true}
              />
            </div>
          </>
        )}
      </div>
      <div className="pt-5">
        <div className="flex justify-end">
          <Button
            type="submit"
            primary
            size="lg"
            disabled={isCreate || isUpdate}
          >
            {data.id === "undefined" ? "Add" : "Save"}
          </Button>
        </div>
      </div>
    </Form>
  );
}

MasterAgentModal.defaultProps = {
  data: transformMasterAgent(),
  onSuccess: false,
};

MasterAgentModal.propTypes = {
  data: PropTypes.instanceOf(Object),
  onSuccess: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onClose: PropTypes.func.isRequired,
};

export default MasterAgentModal;
