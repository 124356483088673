import React from "react";
import Pagination from "partial/components/Pagination";
import { useReportList, useReportSelected } from "../hooks";
import ReportFilter from "../components/ReportFilter";
import { RiFolderUserFill, RiUser2Fill } from "react-icons/ri";
import { HiUserGroup } from "react-icons/hi";
import { IoCash } from "react-icons/io5";
import _ from "lodash";

function ReportContainer() {
  const [filter, setFilter] = React.useState({
    date: "",
    handler_id: "",
    page: 1,
  });
  const [, list, pager] = useReportList(filter);

  const [, moneyData] = useReportSelected();

  return (
    <div>
      <div className="mx-4">
        <ReportFilter onSubmit={setFilter} value={filter} />
      </div>
      <div className="sm:flex sm:items-center">
        <div className="grid grid-cols-4 gap-2 m-4 w-full">
          <div className="flex items-center border rounded-lg px-4 py-3 bg-white shadow-sm">
            <div className="flex items-center space-x-2">
              <span className="bg-primary-50 rounded-full p-3">
                <HiUserGroup className="w-6 h-6 flex-shrink-0 text-primary-500" />
              </span>
              <div className="sm:flex-auto">
                <p className="text-sm text-gray-700">Player</p>
                <p className="text-lg text-primary-500 font-semibold flex items-center space-x-1">
                  <span>{_.get(moneyData, "report.players")}</span>
                  <span className="text-xs text-gray-400">PHP</span>
                </p>
              </div>
            </div>
          </div>
          <div className="flex items-center border rounded-lg px-4 py-3 bg-white shadow-sm">
            <div className="flex items-center space-x-2">
              <span className="bg-red-50 rounded-full p-3">
                <RiFolderUserFill className="w-6 h-6 flex-shrink-0 text-red-500" />
              </span>
              <div className="sm:flex-auto">
                <p className="text-sm text-gray-700">Master Agent</p>
                <p className="text-lg text-red-500 font-semibold flex items-center space-x-1">
                  <span>{_.get(moneyData, "report.master_agents")}</span>
                  <span className="text-xs text-gray-400">PHP</span>
                </p>
              </div>
            </div>
          </div>
          <div className="flex items-center border rounded-lg px-4 py-3 bg-white shadow-sm">
            <div className="flex items-center space-x-2">
              <span className="bg-secondary-50 bg-opacity-50 rounded-full p-3">
                <RiUser2Fill className="w-6 h-6 flex-shrink-0 text-secondary-500" />
              </span>
              <div className="sm:flex-auto">
                <p className="text-sm text-gray-700">Agent</p>
                <p className="text-lg text-secondary-500 font-semibold flex items-center space-x-1">
                  <span>{_.get(moneyData, "report.agents")}</span>
                  <span className="text-xs text-gray-400">PHP</span>
                </p>
              </div>
            </div>
          </div>
          <div className="flex items-center border rounded-lg px-4 py-3 bg-white shadow-sm">
            <div className="flex items-center space-x-2">
              <span className="bg-green-50 rounded-full p-3">
                <IoCash className="w-6 h-6 flex-shrink-0 text-green-500" />
              </span>
              <div className="sm:flex-auto">
                <p className="text-sm text-gray-700">Total</p>
                <p className="text-lg text-green-500 font-semibold flex items-center space-x-1">
                  <span>{_.get(moneyData, "report.total")}</span>
                  <span className="text-xs text-gray-400">PHP</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-4 bg-white p-4 rounded-lg shadow-sm">
        <div className="flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col">
                        <span className="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider uppercase flex items-center space-x-2">
                          Transaction
                        </span>
                      </th>
                      <th scope="col">
                        <span className="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider uppercase flex items-center space-x-2">
                          Amount
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {list.map((row) => (
                      <tr key={row.name}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base font-medium text-gray-900 sm:pl-6">
                          {row.type}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base font-medium text-gray-900 sm:pl-6">
                          {row.amount}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <p className="m-4 text-center text-sm text-gray-600">
          Total of {pager?.total || 0} Reports(s)
        </p>
        <div className="m-2">
          <Pagination onChange={setFilter} value={pager} />
        </div>
      </div>
    </div>
  );
}

export default ReportContainer;
