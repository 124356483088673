import { useEffect, useCallback } from "react";
import { req, useApiGet, useApiLoading } from "react-reqq-lite";
import * as actions from "./actions";
import * as c from "./constants";

export const useSuperMasterAgentList = (params) => {
  const isLoading = useApiLoading(c.SUPER_MASTER_AGENT_LIST, "get");
  const data = useApiGet(c.SUPER_MASTER_AGENT_LIST, []);
  const pager = useApiGet(c.SUPER_MASTER_AGENT_PAGER, {});
  useEffect(() => {
    actions.listSuperMasterAgents(params);
  }, [params]);
  return [isLoading, data, pager];
};

export const useSuperMasterAgentSelected = () => {
  const isLoading = useApiLoading(c.SUPER_MASTER_AGENT_ITEM, "get");
  const data = useApiGet(c.SUPER_MASTER_AGENT_ITEM, {});
  return [isLoading, data];
};

export const useSelectSuperMasterAgent = (id) => {
  useEffect(() => {
    if (!id) {
      req.set(c.SUPER_MASTER_AGENT_ITEM, null);
      return;
    }
    actions.selectSuperMasterAgent(id);
  }, [id]);
};

export const useCreateSuperMasterAgent = () => {
  const isLoading = useApiLoading(c.SUPER_MASTER_AGENT_ITEM, "post");
  const submit = useCallback((payload, onSuccess) => {
    actions.createSuperMasterAgent(payload, onSuccess);
  }, []);
  return [isLoading, submit];
};

export const useUpdateSuperMasterAgent = () => {
  const isLoading = useApiLoading(c.SUPER_MASTER_AGENT_ITEM, "put");
  const submit = useCallback((id, payload, onSuccess) => {
    actions.updateSuperMasterAgent(id, payload, onSuccess);
  }, []);
  return [isLoading, submit];
};

export const useUpdateSuperMasterAgentEmail = () => {
  const isLoading = useApiLoading(c.SUPER_MASTER_AGENT_ITEM, "put");
  const submit = useCallback((id, payload, onSuccess) => {
    actions.updateSuperMasterAgentEmail(id, payload, onSuccess);
  }, []);
  return [isLoading, submit];
};

export const useChangeStatus = () => {
  const isLoading = useApiLoading(c.SUPER_MASTER_AGENT_ITEM, "put");
  const submit = useCallback((id, payload, onSuccess) => {
    actions.changeStatus(id, payload, onSuccess);
  }, []);
  return [isLoading, submit];
};

export const useRemoveSuperMasterAgent = () => {
  const isLoading = useApiLoading(c.SUPER_MASTER_AGENT_ITEM, "remove");
  const submit = useCallback((id, onSuccess) => {
    actions.removeSuperMasterAgent(id, onSuccess);
  }, []);
  return [isLoading, submit];
};
