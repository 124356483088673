import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";
import ThumbIcon from "partial/components/ThumbIcon";
import { HiOutlineDocumentAdd } from "react-icons/hi";

function isLetter(str) {
  return str.length === 1 && str.match(/[a-z]/i);
}

const identifyGroup = (x) => {
  // GROUP A-Z
  if (Number.isNaN(+x) && typeof x === "string" && x[0] && isLetter(x[0])) {
    return x[0].toUpperCase();
  }
  // GROUP 0-9
  if (!Number.isNaN(+x) && typeof +x === "number") {
    return "0-9";
  }
  // GROUP Other
  return "Other";
};

const useGroupList = (raw, key) => {
  const [grouped, setGrouped] = React.useState({});
  React.useEffect(() => {
    const group = {};
    raw.forEach((row) => {
      const g = identifyGroup((row[key] || "")[0]);
      const temp = group[g] || [];
      temp.push(row);
      group[g] = temp;
    });
    setGrouped(group);
  }, [raw, setGrouped, key]);
  return [grouped];
};

function List({
  data,
  selected,
  onSelect,
  groupKey,
  label,
  photoKey,
  subTitleKey,
  labelKey,
  renderBadge,
  withAdd,
}) {
  const handleSelectRow = (row) => (e) => {
    e.preventDefault();
    document.activeElement.blur();
    onSelect(row);
  };
  const [grouped] = useGroupList(data, groupKey);
  const selectedRef = React.useRef({});
  React.useEffect(() => {
    const temp = data.find((x) => x?.id === selected?.id);
    setTimeout(() => {
      if (temp && selectedRef.current) {
        selectedRef.current.scrollIntoView(
          {
            behavior: "smooth",
            block: "nearest",
          },
          100
        );
      }
    });
  }, [data, selected]);
  return (
    <nav className="h-full overflow-y-auto" aria-label="List">
      {Object.keys(grouped).length < 1 ? (
        <div className="text-center">
          <HiOutlineDocumentAdd className="h-8 w-8 text-gray-500 inline-block" />
          <h3 className="mt-2 text-base font-medium text-gray-900">
            No {label}
          </h3>
          {withAdd === true && (
            <p className="mt-1 text-sm text-gray-500">
              Get started by creating a new {label}.
            </p>
          )}
        </div>
      ) : (
        Object.keys(grouped).map((letter) => (
          <div key={letter} className="relative">
            <div className="z-10 sticky top-0 px-6 py-1 text-sm font-bold text-gray-500 bg-gray-200">
              <h3>{letter}</h3>
            </div>
            <ul className="relative z-0 divide-y divide-gray-100 remove-bullet">
              {grouped[letter].map((row) => (
                <li key={row.id} className="bg-white">
                  <div
                    className={cn(
                      "side-list",
                      selected?.id === row?.id ? "active" : "hover:bg-gray-50"
                    )}
                  >
                    <div
                      ref={selected?.id === row?.id ? selectedRef : null}
                      className="absolute left-0"
                      style={{ top: "-30px", height: "calc(100% + 30px)" }}
                    />
                    <div className="flex-shrink-0">
                      <ThumbIcon
                        className="h-10 w-10 rounded-lg"
                        url={row[photoKey]}
                        alt={row.name}
                      />
                    </div>
                    <div className="flex-1 min-w-0">
                      <a
                        href="/"
                        className="focus:outline-none"
                        onClick={handleSelectRow(row)}
                      >
                        {/* Extend touch target to entire panel */}
                        <span className="absolute inset-0" aria-hidden="true" />
                        <p className="text-base font-medium text-gray-900">
                          {row[labelKey] || "-"}
                        </p>
                        <p className="text-sm text-gray-500 truncate">
                          {row[subTitleKey]}
                        </p>
                      </a>
                    </div>
                    <div className="flex-none">
                      {typeof renderBadge === "function"
                        ? renderBadge(row)
                        : null}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ))
      )}
    </nav>
  );
}

List.defaultProps = {
  data: [],
  selected: null,
  groupKey: "name",
  label: "item",
  labelKey: "name",
  subTitleKey: "description",
  photoKey: "logo",
  renderBadge: null,
  withAdd: false,
};

List.propTypes = {
  label: PropTypes.string,
  labelKey: PropTypes.string,
  photoKey: PropTypes.string,
  groupKey: PropTypes.string,
  subTitleKey: PropTypes.string,
  data: PropTypes.instanceOf(Array),
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.instanceOf(Object),
  renderBadge: PropTypes.instanceOf(Object),
  withAdd: PropTypes.bool,
};

export default List;
