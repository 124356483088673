import React, { useState, forwardRef, useEffect } from "react";
import _ from "lodash";
import moment from "moment";
// import InputMask from 'react-input-mask';
import cn from "classnames";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import { toastError } from "react-geek-toast";

function FormDatePicker({
  // disabled,
  withTime,
  // isRequired,
  // placeholder,
  label,
  onChange,
  maxDate,
  minDate,
  value,
  openToDate,
  dateFormat,
  showYearPicker,
  showMonthYearPicker,
  otherProps,
  showMonthDropdown,
  showYearDropdown,
}) {
  const formatDate = (date, def = "", format = "YYYY-MM-DD") => {
    try {
      const endDate = moment(date, "YYYY-MM-DD HH:mm").format(format);

      if (_.isEmpty(date) && date === "") {
        return endDate === "";
      }

      if (endDate === "Invalid date") {
        toastError("Entered date is invalid");
      }

      const newDate = endDate === "Invalid date" ? "" : endDate;

      return newDate;
    } catch (error) {
      return def;
    }
  };

  const CustomRender = forwardRef(({ onClick }, ref) => (
    <button
      type="button"
      ref={ref}
      className="btn btn-secondary btn-sm"
      onClick={onClick}
    >
      <i className="fa fa-lg fa-calendar" />
    </button>
  ));

  CustomRender.propTypes = {
    onClick: PropTypes.instanceOf(Function),
  };

  CustomRender.defaultProps = {
    onClick: () => {},
  };

  const renderDateFormat = (showMonthYearPicker, withTime) => {
    if (showMonthYearPicker) {
      return "YYYY-MM";
    }
    if (withTime) {
      return "YYYY-MM-DD HH:mm";
    }
    return "YYYY-MM-DD";
  };

  const renderPlaceHolder = (data, showMonthYearPicker, withTime) => {
    let placeHolder = "";
    if (data.length > 0) {
      return data;
    }

    placeHolder = () => {
      if (showMonthYearPicker) {
        return "YYYY-MM";
      }
      if (withTime) {
        return "YYYY-MM-DD HH:MM";
      }
      return "YYYY-MM-DD";
    };

    return placeHolder();
  };

  const [selected, setSelected] = useState(null);

  const handleChangePicker = (e) => {
    const newVal = formatDate(
      e,
      "",
      renderDateFormat(showMonthYearPicker, withTime)
    );
    onChange({ target: { value: newVal } });
  };

  useEffect(() => {
    setSelected(value);
  }, [value]);

  return (
    <div
      className={cn(
        "relative border rounded-md px-3 py-2 shadow-sm focus-within:ring-1 bg-white"
      )}
    >
      <label
        htmlFor="last_name"
        className="block text-xs font-medium text-gray-900"
      >
        {label}
      </label>
      <DatePicker
        wrapperClassName="date-picker-wrapper"
        selected={new Date(value || openToDate)}
        // selected={value || new Date()}
        showMonthYearPicker={showMonthYearPicker}
        onChange={handleChangePicker}
        showTimeSelect={withTime}
        timeIntervals={15}
        openToDate={!_.isEmpty(value) ? new Date(selected) : openToDate}
        dateFormat={dateFormat}
        timeFormat="HH:mm"
        maxDate={maxDate}
        minDate={minDate}
        placeholder={renderPlaceHolder}
        showYearPicker={showYearPicker}
        showMonthDropdown={showMonthDropdown}
        showYearDropdown={showYearDropdown}
        {...otherProps}
      />
    </div>
  );
}

FormDatePicker.defaultProps = {
  // disabled: false,
  showMonthYearPicker: false,
  withTime: false,
  // isRequired: false,
  // placeholder: '',
  maxDate: new Date("3000-01-01"),
  minDate: new Date("1900-01-01"),
  openToDate: new Date(),
  value: "",
  otherProps: "",
  label: "",
  showYearDropdown: false,
  showMonthDropdown: false,
};

/* eslint-disable */
FormDatePicker.propTypes = {
  // disabled: PropTypes.bool,
  showMonthYearPicker: PropTypes.bool,
  withTime: PropTypes.bool,
  // isRequired: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.instanceOf(Function).isRequired,
  maxDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  dateFormat: PropTypes.string.isRequired,
  openToDate: PropTypes.instanceOf(Date),
  showYearPicker: PropTypes.bool,
  value: PropTypes.string,
  otherProps: PropTypes.node,
  showMonthDropdown: PropTypes.bool,
  showYearDropdown: PropTypes.bool,
};

export default FormDatePicker;
