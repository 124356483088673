import React from "react";
import Landing from "modules/auth/containers/Landing";
import PageNotFound from "partial/containers/PageNotFound";
import { Switch, Route, Redirect } from "react-router-dom";
import Verification from "modules/auth/components/Verification";
import PlayerSignUpContainer from "modules/agent/sign-up/container/PlayerSignUpContainer";
import AgentSignUpContainer from "modules/master-agent/agents/sign-up/container/AgentSignUpContainer";
import MasterAgentSignUpContainer from "modules/super-master-agent/master-agents/sign-up/container/MasterAgentSignUpContainer";

function Public() {
  return (
    <Switch>
      <Route exact path="/" component={Landing} />
      <Route path="/master-agent/signup" component={MasterAgentSignUpContainer} />
      <Route path="/agent/signup" component={AgentSignUpContainer} />
      <Route path="/player/signup" component={PlayerSignUpContainer} />
      <Route path="/check-email" component={Verification} />
      <Route path="/logout">
        <Redirect to="/" />
      </Route>
      <Route component={PageNotFound} />
    </Switch>
  );
}

Public.propTypes = {};

export default Public;
