import React, { useState } from "react";
import cn from "classnames";
import PropTypes from "prop-types";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { HiOutlineEyeOff, HiOutlineEye } from "react-icons/hi";
import { useInputId } from "./hooks";

function FormInputWithIcon({
  id: defaultId,
  icon: Icon,
  label,
  name,
  onChange,
  value,
  error,
  required,
  withIcon,
  ...props
}) {
  const [id] = useInputId(defaultId);
  const handleChange = ({ target }) => {
    onChange((state) => ({
      ...state,
      [name]: target.value,
    }));
  };
  const showIcon = Icon || error;
  const isError = !!error;

  const [hidden, setHidden] = useState(true);

  return (
    <div
      className={cn(
        "relative border rounded-md px-3 py-2 shadow-sm focus-within:ring-1 bg-white",
        isError
          ? "border-primary-300 focus-within:ring-primary-500 focus-within:border-primary-500"
          : "border-gray-300 focus-within:ring-primary-600 focus-within:border-primary-600"
      )}
    >
      <label htmlFor={id} className="block text-xs font-medium text-gray-900">
        <span>{label}</span>
        {!required && <span className="ml-1 font-light">(Optional)</span>}
      </label>
      {showIcon && !withIcon && (
        <>
          <input
            id={id}
            type="password"
            name="name"
            className={cn(
              "block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-base font-semibold",
              "focus:outline-none",
              showIcon ? "pr-10" : ""
            )}
            autoComplete="new-password"
            onChange={handleChange}
            value={value || ""}
            {...props}
          />
          <div
            className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
            title={error || ""}
          >
            {error ? (
              <ExclamationCircleIcon
                className="h-5 w-5 text-primary-500"
                aria-hidden="true"
              />
            ) : (
              <Icon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            )}
          </div>
        </>
      )}
      {withIcon === true && (
        <>
          <input
            {...props}
            type={hidden ? "password" : "text"}
            name="name"
            className={cn(
              "block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-base font-semibold",
              "focus:outline-none",
              showIcon ? "pr-10" : ""
            )}
            autoComplete="new-password"
            onChange={handleChange}
            value={value || ""}
          />
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
            <button
              type="button"
              tabIndex={-1}
              className="btn mr-auto"
              onClick={() => setHidden(!hidden)}
            >
              {hidden ? <HiOutlineEyeOff /> : <HiOutlineEye />}
            </button>
          </div>
        </>
      )}
    </div>
  );
}

FormInputWithIcon.defaultProps = {
  id: "",
  icon: false,
  type: "text",
  error: null,
  required: false,
  withIcon: false,
  hidden: false,
};

FormInputWithIcon.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  icon: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.bool]),
  error: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  withIcon: PropTypes.bool,
};

export default React.memo(FormInputWithIcon);
