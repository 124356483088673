import React from "react";
import PropTypes from "prop-types";
import List from "partial/components/List";
import Pagination from "partial/components/Pagination";
import SuperAdminAgentListFilter from "./SuperAdminAgentListFilter";
import { useSuperAdminAgentList, useSelectSuperAdminAgent } from "../hooks";

function SuperAdminAgentList({ selected, onSelect }) {
  const [filter, setFilter] = React.useState({
    email: "",
    page: 1,
  });
  const [isLoading, list, pager] = useSuperAdminAgentList(filter);
  
  React.useEffect(() => {
    if (window.innerWidth <= 1024) return;
    if (!selected && list[0]) onSelect(list[0]);
  }, [selected, list, onSelect]);
  useSelectSuperAdminAgent(selected?.id);
  return (
    <>
      <div className="px-6 pt-6 pb-4">
        <p className="mt-1 text-sm text-gray-600">
          Total of {pager?.total || 0} Agent(s)
        </p>
        <SuperAdminAgentListFilter onSubmit={setFilter} value={filter} />
      </div>
      <div className="relative flex-grow">
        <div className="absolute h-full w-full top-0 left-0 overflow-auto">
          <List
            label="Agent"
            data={list}
            selected={selected}
            onSelect={onSelect}
            isLoading={isLoading}
            subTitleKey="email"
            // renderBadge={renderBadge}
          />
        </div>
      </div>
      <Pagination onChange={setFilter} value={pager} />
    </>
  );
}

SuperAdminAgentList.defaultProps = {
  selected: null,
};

SuperAdminAgentList.propTypes = {
  selected: PropTypes.instanceOf(Object),
  onSelect: PropTypes.func.isRequired,
};

export default SuperAdminAgentList;
