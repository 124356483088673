import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  BsApple,
  BsFillRecordFill,
  BsHourglass,
  BsPlayCircleFill,
  BsStopCircleFill,
} from "react-icons/bs";
import _ from "lodash";
import firebase from "../../../setup/firebase";
import {
  useUpdateChangeState,
  useUpdateChangeBet,
  useSetWinningHorse,
} from "../hooks";
import ReactPlayer from "react-player";
import Button from "partial/components/Button";
import { HiCheck, HiX } from "react-icons/hi";
import { toastError } from "react-geek-toast";
import { openStartBettingModal } from "../modals/StartBettingModal";
import { formatDate, numberWithCommas } from "helper";
import { openScratchNormalModal } from "../modals/ScratchNormalModal";
import { openScratchExoticModal } from "../modals/ScratchExoticModal";

function HandlerComponent({ data }) {
  const [currentDate, setCurrentDate] = useState();

  React.useEffect(() => {
    setCurrentDate(formatDate(new Date(), "MMM DD, YYYY hh:mm A"));
  }, []);

  const [form, setForm] = useState({});
  const [isChangeState, changeState] = useUpdateChangeState();
  const [isChangeBet, changeBet] = useUpdateChangeBet();
  const [stateGame, setStateGame] = useState(false);
  const [bettingStatus, setBettingStatus] = useState(false);
  const [round, setRound] = useState("");
  const [winners, setWinners] = useState([]);
  const [showWin, setShowWin] = useState(false);
  const [sumOfBets, setSumOfBets] = useState([]);
  const [playerBets, setPlayerBets] = useState([]);
  const [totalBetOfPlayers, setTotalBetOfPlayers] = useState(0);
  const [bettingTypes, ] = useState([
    {
      id: 1,
      label: "Forecast",
      value: "Forecast",
    },
    {
      id: 2,
      label: "Daily Double",
      value: "Daily Double",
    },
    {
      id: 3,
      label: "Daily Double Plus 1",
      value: "Daily Double Plus 1",
    },
    {
      id: 4,
      label: "Extra Double",
      value: "Extra Double",
    },
    {
      id: 5,
      label: "Pick 5",
      value: "Pick 5",
    },
    {
      id: 6,
      label: "Pick 6",
      value: "Pick 6",
    },
    {
      id: 7,
      label: "Winner Takes All",
      value: "Winner Takes All",
    },
  ]);
  const [showInput, setShowInput] = useState(false);

  const createMarkup = (text) => {
    const printableText = text.replace(/(?:\r\n|\r|\n)/g, "<br />");
    return { __html: printableText };
  };

  const [videoUrl, setVideoUrl] = useState("");

  React.useEffect(() => {
    if (!_.isEmpty(_.get(data, "url"))) {
      if (_.get(data, "type_video") === "YOUTUBE") {
        const finalUrl = `${_.get(
          data,
          "url"
        )}?disablekb=1&frameborder=0&autoplay=1&controls=0&showinfo=0&wmode=transparent`;
        setVideoUrl(finalUrl.toString().replace("watch?v=", "embed/"));
      } else {
        setVideoUrl(_.get(data, "url"));
      }
    }
  }, []);

  const handleOnChangeState = () => (e) => {
    e.preventDefault();
    changeState(_.get(data, "room.id"), data.id, () => {});
  };

  const handleOnStartBet = () => (e) => {
    e.preventDefault();
    openStartBettingModal("Start Bet", _.get(data, "room.id"), data.id);
  };

  const handleOnEndBet = () => (e) => {
    e.preventDefault();
    changeBet(_.get(data, "room.id"), data.id, () => {});
  };

  const handleOnScratchNormal = () => (e) => {
    e.preventDefault();
    openScratchNormalModal("Scratch Normal", _.get(data, "room.id"), data.id);
  };

  const handleOnScratchExotic = () => (e) => {
    e.preventDefault();
    openScratchExoticModal("Scratch Exotic", _.get(data, "room.id"), data.id);
  };

  useEffect(() => {
    // build reference
    const bettingStatusApi = firebase
      .database()
      .ref(
        "rooms/" +
          _.get(data, "room.id") +
          "/session/" +
          data.id +
          "/betting_status"
      );

    // attach listener
    const listener = bettingStatusApi.on(
      "value",
      (snapshot) => {
        const data = snapshot.val();
        if (data === null) {
          setSumOfBets([]);
          setTotalBetOfPlayers(0);
          setPlayerBets([]);
          setBettingStatus(false);
        } else {
          setBettingStatus(data);
        }
      },
      (error) => {
        toastError(error);
      }
    );

    // detach listener in unsubscribe callback
    return () => bettingStatusApi.off();
  }, []);

  useEffect(() => {
    // build reference
    const roundApi = firebase
      .database()
      .ref(
        "rooms/" + _.get(data, "room.id") + "/session/" + data.id + "/rounds"
      );

    // attach listener
    const listener = roundApi.on(
      "value",
      (snapshot) => {
        const data = snapshot.val();
        setRound(data);
      },
      (error) => {
        toastError(error);
      }
    );

    // detach listener in unsubscribe callback
    return () => roundApi.off();
  }, []);

  useEffect(() => {
    // build reference
    const stateApi = firebase
      .database()
      .ref(
        "rooms/" + _.get(data, "room.id") + "/session/" + data.id + "/state"
      );

    // attach listener
    const listener = stateApi.on(
      "value",
      (snapshot) => {
        const data = snapshot.val();
        if (data === null) {
          setSumOfBets([]);
          setTotalBetOfPlayers(0);
          setPlayerBets([]);
          setStateGame(false);
        } else {
          setStateGame(data);
        }
      },
      (error) => {
        toastError(error);
      }
    );

    // detach listener in unsubscribe callback
    return () => stateApi.off();
  }, []);

  useEffect(() => {
    // build reference
    const last5WinsApi = firebase
      .database()
      .ref(
        "rooms/" + _.get(data, "room.id") + "/session/" + data.id + "/lastwins"
      );

    // attach listener
    const listener = last5WinsApi.on(
      "value",
      (snapshot) => {
        const data = snapshot.val();
        if (data !== null) {
          let entries = {};
          let finalArr = [];
          let finalWinners = [];

          entries = Object.entries(data);
          for (const [prop, val] of entries) {
            finalArr.push(val);
          }

          let totalWinners = [];
          totalWinners = Object.keys(finalArr[0]).map((x) => ({
            data: finalArr[0][x],
            round: x,
          }));

          totalWinners.forEach(function (item) {
            var items = Object.values(item.data);
            finalWinners.push({
              data: items,
              round: item.round,
            });
          });

          setWinners(finalWinners);
          setShowWin(true);
        } else {
          setWinners([]);
          setShowWin(false);
        }
      },
      (error) => {
        toastError(error);
      }
    );

    // detach listener in unsubscribe callback
    return () => last5WinsApi.off();
  }, []);

  useEffect(() => {
    // build reference
    const betsPlayerApi = firebase
      .database()
      .ref(
        "rooms/" + _.get(data, "room.id") + "/session/" + data.id + "/game/bets"
      );

    // attach listener
    const listener = betsPlayerApi.get().then(
      (snapshot) => {
        if (snapshot.exists()) {
          betsPlayerApi.once("value", (snapshot) => {
            const data = snapshot.val();

            let entries = {};
            let finalArr = [];

            if (!_.isEmpty(data)) {
              entries = Object.entries(data);
              for (const [prop, val] of entries) {
                finalArr.push(val[0]);
              }
            }

            const ids = finalArr.map((o) => o.id);
            const filtered = finalArr.filter(
              ({ id }, index) => !ids.includes(id, index + 1)
            );

            setPlayerBets(filtered);

            const sumInArray = getTotalAmount(filtered);

            setTotalBetOfPlayers(sumInArray);

            let finalbets = [];

            filtered.forEach((element) => {
              element.amount = element.amount;
              finalbets.push(element);
            });

            var result = finalbets.reduce(function (acc, val) {
              var o = acc
                .filter(function (obj) {
                  return obj.type === val.type && obj.horse === val.horse;
                })
                .pop() || { type: val.type, horse: val.horse, amount: 0 };

              o.amount += val.amount;
              acc.push(o);
              return acc;
            }, []);

            if (result.length > 0) {
              let filteredArray = _.uniqWith(result, _.isEqual);
              setSumOfBets(filteredArray);
            }
          });
        } else {
          betsPlayerApi.once("value", (snapshot) => {
            const data = snapshot.val();

            let entries = {};
            let finalArr = [];

            if (!_.isEmpty(data)) {
              entries = Object.entries(data);
              for (const [prop, val] of entries) {
                finalArr.push(val[0]);
              }
            }

            const ids = finalArr.map((o) => o.id);
            const filtered = finalArr.filter(
              ({ id }, index) => !ids.includes(id, index + 1)
            );

            setPlayerBets(filtered);

            const sumInArray = getTotalAmount(filtered);

            setTotalBetOfPlayers(sumInArray);

            let finalbets = [];

            filtered.forEach((element) => {
              element.amount = element.amount;
              finalbets.push(element);
            });

            var result = finalbets.reduce(function (acc, val) {
              var o = acc
                .filter(function (obj) {
                  return obj.type === val.type && obj.horse === val.horse;
                })
                .pop() || { type: val.type, horse: val.horse, amount: 0 };

              o.amount += val.amount;
              acc.push(o);
              return acc;
            }, []);

            if (result.length > 0) {
              let filteredArray = _.uniqWith(result, _.isEqual);
              setSumOfBets(filteredArray);
            }
          });
        }
        betsPlayerApi.on("child_added", (snapshot) => {
          const data = snapshot.val();

          betsPlayerApi.get().then((snapshot) => {
            if (snapshot.exists()) {
              betsPlayerApi.once("value", (snapshot) => {
                const data = snapshot.val();

                let entries = {};
                let finalArr = [];

                if (!_.isEmpty(data)) {
                  entries = Object.entries(data);
                  for (const [prop, val] of entries) {
                    finalArr.push(val[0]);
                  }
                }

                const ids = finalArr.map((o) => o.id);
                const filtered = finalArr.filter(
                  ({ id }, index) => !ids.includes(id, index + 1)
                );

                setPlayerBets(filtered);

                const sumInArray = getTotalAmount(filtered);

                setTotalBetOfPlayers(sumInArray);

                let finalbets = [];

                filtered.forEach((element) => {
                  element.amount = element.amount;
                  finalbets.push(element);
                });

                var result = finalbets.reduce(function (acc, val) {
                  var o = acc
                    .filter(function (obj) {
                      return obj.type === val.type && obj.horse === val.horse;
                    })
                    .pop() || { type: val.type, horse: val.horse, amount: 0 };

                  o.amount += val.amount;
                  acc.push(o);
                  return acc;
                }, []);

                if (result.length > 0) {
                  let filteredArray = _.uniqWith(result, _.isEqual);
                  setSumOfBets(filteredArray);
                }
              });
            }
          });
        });
      },
      (error) => {
        toastError(error);
      }
    );

    // detach listener in unsubscribe callback
    return () => betsPlayerApi.off();
  }, []);

  useEffect(() => {
    // build reference
    const betsUpdateApi = firebase
      .database()
      .ref(
        "rooms/" + _.get(data, "room.id") + "/session/" + data.id + "/game/bets"
      );

    // attach listener
    const listener = betsUpdateApi.get().then(
      (snapshot) => {
        betsUpdateApi.on("value", (snapshot) => {
          const data = snapshot.val();

          betsUpdateApi.get().then((snapshot) => {
            if (snapshot.exists()) {
              betsUpdateApi.once("value", (snapshot) => {
                const data = snapshot.val();

                let entries = {};
                let finalArr = [];

                if (!_.isEmpty(data)) {
                  entries = Object.entries(data);
                  for (const [prop, val] of entries) {
                    finalArr.push(val[0]);
                  }
                }

                const ids = finalArr.map((o) => o.id);
                const filtered = finalArr.filter(
                  ({ id }, index) => !ids.includes(id, index + 1)
                );

                setPlayerBets(filtered);

                const sumInArray = getTotalAmount(filtered);

                setTotalBetOfPlayers(sumInArray);

                let finalbets = [];

                filtered.forEach((element) => {
                  element.amount = element.amount;
                  finalbets.push(element);
                });

                var result = finalbets.reduce(function (acc, val) {
                  var o = acc
                    .filter(function (obj) {
                      return obj.type === val.type && obj.horse === val.horse;
                    })
                    .pop() || { type: val.type, horse: val.horse, amount: 0 };

                  o.amount += val.amount;
                  acc.push(o);
                  return acc;
                }, []);

                if (result.length > 0) {
                  let filteredArray = _.uniqWith(result, _.isEqual);
                  setSumOfBets(filteredArray);
                }
              });
            }
          });
        });
      },
      (error) => {
        toastError(error);
      }
    );

    // detach listener in unsubscribe callback
    return () => betsUpdateApi.off();
  }, []);

  const onChange = (e) => {
    setForm({
      ...form,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const selectType = (row) => (e) => {
    setShowInput(row);
    setForm({
      ...form,
      type: row,
    });
  };

  const [isSetWinningHorse, setWinningHorse] = useSetWinningHorse();

  const handleOnSetWinning = () => {
    const args = {
      ...form,
      rounds_lookup: round,
      bet_type: showInput,
    };
    setWinningHorse(_.get(data, "room.id"), data.id, args, (newData) => {
      setForm({});
      setShowInput("");
    });
  };

  const getTotalAmount = (sumData) => {
    let amount = 0;
    amount = sumData.reduce(
      (totalAmount, x) => totalAmount + parseFloat(x.amount),
      0
    );
    return amount;
  };

  return (
    <>
      <div className="flex items-center sm:px-4 px-1 sm:pt-4 pt-2 sm:pb-0 pb-2 sm:space-x-5 space-x-0  sm:space-y-0 space-y-3 flex-col sm:flex-row justify-between bg-gray-100 border-b sm:border-none border-gray-200 rounded-t-lg">
        <div className="sm:space-x-2 gap-2 grid sm:grid-cols-3 grid-cols-2 space-x-0">
          <div className="flex items-center sm:bg-white bg-none sm:border border-none border-gray-200 py-3 px-2 rounded-lg">
            <div className="px-2">
              <div>
                <div className="text-gray-900 text-sm">Total Bet Amount</div>
                <div className="text-green-500 font-bold text-lg">
                  <div className="flex items-center space-x-2">
                    <div className="text-xs text-gray-500">PHP</div>
                    <div>
                      {numberWithCommas(
                        parseFloat(totalBetOfPlayers).toFixed(2)
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center sm:bg-white bg-none sm:border border-none border-gray-200 py-3 px-2 rounded-lg">
            <div className="px-2">
              <div>
                <div className="text-gray-900 text-sm">Room Name</div>
                <div className="text-secondary-500 font-bold text-lg">
                  {_.get(data, "room.name")}
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center sm:col-span-1 col-span-2 flex-col justify-end border-t sm:border-none pt-2 sm:pt-0 border-gray-300 my-2 sm:my-0">
            <div className="flex items-center space-x-2">
              <div className="text-lg sm:text-2xl text-gray-900 font-bold">
                Race {round} of {_.get(data, "number_of_rounds")}
              </div>
              <div className="px-2 bg-red-500 text-white rounded-md">
                <div className="flex items-center space-x-1">
                  <BsFillRecordFill className="w-3 h-3 flex-shrink-0 p-px border border-white rounded-full" />
                  <div>Live</div>
                </div>
              </div>
            </div>
            <div className="text-gray-600 w-full sm:text-right text-center">
              Today, {currentDate}
            </div>
          </div>
        </div>
        <div className="flex items-center space-x-2">
          {stateGame === false ? (
            <Button
              disabled={isChangeState}
              type="button"
              success
              size="xl"
              icon={HiCheck}
              onClick={handleOnChangeState()}
            >
              <span>Start Session</span>
            </Button>
          ) : (
            <Button
              disabled={isChangeState}
              type="button"
              default
              size="xl"
              icon={HiX}
              onClick={handleOnChangeState()}
            >
              <span>End Session</span>
            </Button>
          )}
          {stateGame === true && bettingStatus === false && (
            <>
              <Button
                disabled={isChangeBet}
                type="button"
                success
                size="xl"
                icon={BsPlayCircleFill}
                onClick={handleOnStartBet()}
              >
                <span>Start Betting</span>
              </Button>
            </>
          )}
          {stateGame === true && (
            <>
              <Button
                type="button"
                success
                size="xl"
                icon={BsApple}
                onClick={handleOnScratchNormal()}
              >
                <span>Scratch (Normal) </span>
              </Button>
              <Button
                type="button"
                success
                size="xl"
                icon={BsHourglass}
                onClick={handleOnScratchExotic()}
              >
                <span>Scratch (Exotic) </span>
              </Button>
            </>
          )}
          {stateGame === true && bettingStatus === true && (
            <Button
              disabled={isChangeBet}
              type="button"
              danger
              size="xl"
              icon={BsStopCircleFill}
              onClick={handleOnEndBet()}
            >
              <span>End Betting</span>
            </Button>
          )}
        </div>
      </div>
      <div className="space-y-4 sm:px-4 sm:py-4 pt-0 pb-4 bg-gray-100 rounded-b-lg">
        <div className="bg-white p-0 sm:rounded-md rounded-none">
          <div className="grid sm:grid-cols-5 grid-cols-1">
            <div className="sm:col-span-3 col-span-1 sm:h-96 h-60 p-5 flex items-center justify-center bg-black">
              <div className="text-lg text-gray-500 w-full h-full">
                {_.get(data, "type_video") === "YOUTUBE" && (
                  <iframe
                    width="100%"
                    height="100%"
                    src={videoUrl}
                    name="youtube embed"
                    allow="autoplay; encrypted-media"
                  ></iframe>
                )}
                {_.get(data, "type_video") === "FACEBOOK" && (
                  <ReactPlayer
                    controls
                    className="react-player"
                    url={videoUrl}
                    width="100%"
                    height="100%"
                    config={{
                      facebook: {
                        attributes: {
                          width: "560px",
                          height: "315px",
                          padding: "0",
                          backgroundcolor: "#000",
                        },
                      },
                    }}
                  />
                )}
              </div>
            </div>
            <div className="game-details sm:col-span-2 col-span-1 h-96 p-5 sm:flex overflow-y-auto hidden">
              <div
                // eslint-disable-next-line
                dangerouslySetInnerHTML={createMarkup(
                  _.get(data, "race_description") || "--"
                )}
              />
            </div>
          </div>
        </div>
        <div className="overflow-hidden sm:rounded-lg rounded-0">
          <div className="grid sm:grid-cols-10 grid-cols-1 sm:divide-x divide-x-0 divide-gray-200 gap-4 sm:gap-0 bg-white border rounded-lg border-gray-200 overflow-hidden">
            <div className="sm:col-span-3 col-span-1 order-2 sm:order-1 sm:rounded-l-lg rounded-none overflow-hidden">
              <div className="text-gray-900 font-bold px-3 py-4 uppercase bg-gradient-to-bl from-gray-200 to-gray-300">
                Total Bets
              </div>
              <div className="max-h-[400px] overflow-x-auto overflow-y-auto overflow-hidden">
                {(sumOfBets || []).map((row, index) => (
                  <div key={`${row.horse}+${index + 1}`} className="bg-white">
                    <div className="race-item divide-y divide-gray-200 border-b-4 border-gray-200 my-1">
                      <div className="flex items-center space-x-2 justify-between px-3 py-1">
                        <div className="tracking-wide font-bold text-gray-900 py-1 text-base">
                          Race {round}
                        </div>
                        {/* <div className="text-gray-500 text-xs">
                        Start Time: 12:48PM
                      </div> */}
                      </div>
                      <div className="px-3 py-2">
                        <div className="text-gray-900">{row.type}</div>
                        <div className="flex items-center space-x-2 justify-between">
                          <div className="flex items-center space-x-1">
                            <div className="text-green-900 bg-green-500 tracking-widest px-1.5 py-0.5 text-sm rounded-md font-bold">
                              {row.horse}
                            </div>
                          </div>
                        </div>
                        <div className="flex items-center space-x-2 justify-between mt-2">
                          <div className="flex items-center space-x-1">
                            <div className="tracking-widest px-1.5 py-0.5 text-sm rounded-md font-bold">
                              Amount:
                            </div>
                          </div>
                          <div>
                            <div className="text-gray-800">
                              {numberWithCommas(
                                parseFloat(row.amount).toFixed(2)
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="sm:col-span-4 col-span-1 sm:p-10 p-4 order-1 sm:order-2 bg-white overflow-hidden">
              <div className="text-center space-y-5">
                <div className="relative max-w-xl mx-auto">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-center">
                    <span className="px-2 bg-white text-base font-bold text-gray-900 py-1 uppercase leading-none">
                      Enter winning number for Race {round}
                    </span>
                  </div>
                </div>
                <div className="space-y-2 flex flex-col">
                  <div className="inline-flex flex-wrap flex-row justify-center mx-auto gap-1 p-1">
                    {(bettingTypes || []).map((row) => (
                      <button
                        key={row.id}
                        onClick={selectType(row.value)}
                        className={`bg-gray-100 text-gray-900 text-base rounded-lg px-4 py-2 hover:bg-gray-500 hover:text-white transition whitespace-nowrap font-semibold ${
                          row.value === showInput &&
                          "bg-gray-600 text-white hover:text-white"
                        }`}
                      >
                        {row.value}
                      </button>
                    ))}
                  </div>
                  {showInput === "Forecast" && (
                    <>
                      <div className="space-y-2 justify-center">
                        <div className="flex max-w-xs items-center justify-between mx-auto bg-gray-100 rounded-lg overflow-hidden pl-4">
                          <span className="text-gray-700">Horse</span>
                          <div className="flex items-center space-x-2">
                            <input
                              name="winning_horse"
                              type="text"
                              placeholder="5,5"
                              value={_.get(form, "winning_horse")}
                              onChange={onChange}
                              className="bg-gray-100 placeholder-gray-300 text-secondary-500 w-40 text-2xl font-semibold text-right px-3 transition border-0  focus:ring-0"
                            />
                          </div>
                        </div>
                        <div className="flex max-w-xs items-center justify-between mx-auto bg-gray-100 rounded-lg overflow-hidden pl-4">
                          <span className="text-gray-700">Multiplier</span>
                          <div className="flex items-center space-x-2">
                            <input
                              name="multiplier"
                              type="number"
                              placeholder="0"
                              value={_.get(form, "multiplier")}
                              onChange={onChange}
                              className="bg-gray-100 placeholder-gray-300 text-secondary-500 w-40 text-2xl font-semibold text-right px-3 transition border-0  focus:ring-0"
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {showInput === "Daily Double" && (
                    <>
                      <div className="space-y-2 justify-center">
                        <div className="flex max-w-xs items-center justify-between mx-auto bg-gray-100 rounded-lg overflow-hidden pl-4">
                          <span className="text-gray-700">Horse</span>
                          <div className="flex items-center space-x-2">
                            <input
                              name="winning_horse"
                              type="text"
                              placeholder="5,5"
                              value={_.get(form, "winning_horse")}
                              onChange={onChange}
                              className="bg-gray-100 placeholder-gray-300 text-secondary-500 w-40 text-2xl font-semibold text-right px-3 transition border-0  focus:ring-0"
                            />
                          </div>
                        </div>
                        <div className="flex max-w-xs items-center justify-between mx-auto bg-gray-100 rounded-lg overflow-hidden pl-4">
                          <span className="text-gray-700">Multiplier</span>
                          <div className="flex items-center space-x-2">
                            <input
                              name="multiplier"
                              type="number"
                              placeholder="0"
                              value={_.get(form, "multiplier")}
                              onChange={onChange}
                              className="bg-gray-100 placeholder-gray-300 text-secondary-500 w-40 text-2xl font-semibold text-right px-3 transition border-0  focus:ring-0"
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {showInput === "Daily Double Plus 1" && (
                    <>
                      <div className="space-y-2 justify-center">
                        <div className="flex max-w-xs items-center justify-between mx-auto bg-gray-100 rounded-lg overflow-hidden pl-4">
                          <span className="text-gray-700">Horse</span>
                          <div className="flex items-center space-x-2">
                            <input
                              name="winning_horse"
                              type="text"
                              placeholder="5,5,5"
                              value={_.get(form, "winning_horse")}
                              onChange={onChange}
                              className="bg-gray-100 placeholder-gray-300 text-secondary-500 w-40 text-2xl font-semibold text-right px-3 transition border-0  focus:ring-0"
                            />
                          </div>
                        </div>
                        <div className="flex max-w-xs items-center justify-between mx-auto bg-gray-100 rounded-lg overflow-hidden pl-4">
                          <span className="text-gray-700">Multiplier</span>
                          <div className="flex items-center space-x-2">
                            <input
                              name="multiplier"
                              type="number"
                              placeholder="0"
                              value={_.get(form, "multiplier")}
                              onChange={onChange}
                              className="bg-gray-100 placeholder-gray-300 text-secondary-500 w-40 text-2xl font-semibold text-right px-3 transition border-0  focus:ring-0"
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {showInput === "Extra Double" && (
                    <>
                      <div className="space-y-2 justify-center">
                        <div className="flex max-w-xs items-center justify-between mx-auto bg-gray-100 rounded-lg overflow-hidden pl-4">
                          <span className="text-gray-700">Horse</span>
                          <div className="flex items-center space-x-2">
                            <input
                              name="winning_horse"
                              type="text"
                              placeholder="5,5"
                              value={_.get(form, "winning_horse")}
                              onChange={onChange}
                              className="bg-gray-100 placeholder-gray-300 text-secondary-500 w-40 text-2xl font-semibold text-right px-3 transition border-0  focus:ring-0"
                            />
                          </div>
                        </div>
                        <div className="flex max-w-xs items-center justify-between mx-auto bg-gray-100 rounded-lg overflow-hidden pl-4">
                          <span className="text-gray-700">Multiplier</span>
                          <div className="flex items-center space-x-2">
                            <input
                              name="multiplier"
                              type="number"
                              placeholder="0"
                              value={_.get(form, "multiplier")}
                              onChange={onChange}
                              className="bg-gray-100 placeholder-gray-300 text-secondary-500 w-40 text-2xl font-semibold text-right px-3 transition border-0  focus:ring-0"
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {showInput === "Pick 5" && (
                    <>
                      <div className="space-y-2 justify-center">
                        <div className="flex max-w-xs items-center justify-between mx-auto bg-gray-100 rounded-lg overflow-hidden pl-4">
                          <span className="text-gray-700">Horse</span>
                          <div className="flex items-center space-x-2">
                            <input
                              name="winning_horse"
                              type="text"
                              placeholder="5,5,5,5,5"
                              value={_.get(form, "winning_horse")}
                              onChange={onChange}
                              className="bg-gray-100 placeholder-gray-300 text-secondary-500 w-40 text-2xl font-semibold text-right px-3 transition border-0  focus:ring-0"
                            />
                          </div>
                        </div>
                        <div className="flex max-w-xs items-center justify-between mx-auto bg-gray-100 rounded-lg overflow-hidden pl-4">
                          <span className="text-gray-700">Multiplier</span>
                          <div className="flex items-center space-x-2">
                            <input
                              name="multiplier"
                              type="number"
                              placeholder="0"
                              value={_.get(form, "multiplier")}
                              onChange={onChange}
                              className="bg-gray-100 placeholder-gray-300 text-secondary-500 w-40 text-2xl font-semibold text-right px-3 transition border-0  focus:ring-0"
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {showInput === "Pick 6" && (
                    <>
                      <div className="space-y-2 justify-center">
                        <div className="flex max-w-xs items-center justify-between mx-auto bg-gray-100 rounded-lg overflow-hidden pl-4">
                          <span className="text-gray-700">Horse</span>
                          <div className="flex items-center space-x-2">
                            <input
                              name="winning_horse"
                              type="text"
                              placeholder="5,5,5,5,5,5"
                              value={_.get(form, "winning_horse")}
                              onChange={onChange}
                              className="bg-gray-100 placeholder-gray-300 text-secondary-500 w-40 text-2xl font-semibold text-right px-3 transition border-0  focus:ring-0"
                            />
                          </div>
                        </div>
                        <div className="flex max-w-xs items-center justify-between mx-auto bg-gray-100 rounded-lg overflow-hidden pl-4">
                          <span className="text-gray-700">Multiplier</span>
                          <div className="flex items-center space-x-2">
                            <input
                              name="multiplier"
                              type="number"
                              placeholder="0"
                              value={_.get(form, "multiplier")}
                              onChange={onChange}
                              className="bg-gray-100 placeholder-gray-300 text-secondary-500 w-40 text-2xl font-semibold text-right px-3 transition border-0  focus:ring-0"
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {showInput === "Winner Takes All" && (
                    <>
                      <div className="space-y-2 justify-center">
                        <div className="flex max-w-xs items-center justify-between mx-auto bg-gray-100 rounded-lg overflow-hidden pl-4">
                          <span className="text-gray-700">Horse</span>
                          <div className="flex items-center space-x-2">
                            <input
                              name="winning_horse"
                              type="text"
                              placeholder="5,5,5,5,5,5"
                              value={_.get(form, "winning_horse")}
                              onChange={onChange}
                              className="bg-gray-100 placeholder-gray-300 text-secondary-500 w-40 text-2xl font-semibold text-right px-3 transition border-0  focus:ring-0"
                            />
                          </div>
                        </div>
                        <div className="flex max-w-xs items-center justify-between mx-auto bg-gray-100 rounded-lg overflow-hidden pl-4">
                          <span className="text-gray-700">Multiplier</span>
                          <div className="flex items-center space-x-2">
                            <input
                              name="multiplier"
                              type="number"
                              placeholder="0"
                              value={_.get(form, "multiplier")}
                              onChange={onChange}
                              className="bg-gray-100 placeholder-gray-300 text-secondary-500 w-40 text-2xl font-semibold text-right px-3 transition border-0  focus:ring-0"
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="max-w-sm mx-auto">
                    <button
                      disabled={isSetWinningHorse}
                      onClick={handleOnSetWinning}
                      type="button"
                      className="bg-green-500 font-bold text-lg px-7 py-3 rounded-md text-white hover:bg-green-400 transition"
                    >
                      <div className="inline-flex items-center space-x-2 justify-center">
                        <div className="">Declare Winner</div>
                      </div>
                    </button>
                  </div>
                </div>
                <div className="relative max-w-xl mx-auto">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-center">
                    <span className="px-2 bg-white text-base font-bold text-gray-900 py-1 uppercase leading-none">
                      Player Bets
                    </span>
                  </div>
                </div>
                <div className="space-y-4 text-white">
                  <table className="min-w-full divide-y divide-gray-100 text-left rounded-lg overflow-hidden">
                    <thead className="bg-gray-200">
                      <tr>
                        <th
                          scope="col"
                          className="py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
                        >
                          Bet Type
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-2 text-left text-sm font-semibold text-gray-900"
                        >
                          Bet
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-2 text-right text-sm font-semibold text-gray-900"
                        >
                          Amount
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-100 bg-gray-50">
                      {(playerBets || []).map((row) => (
                        <tr key={row.id}>
                          <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900">
                            {row.type}
                          </td>
                          <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-800">
                            <div className="flex items-center space-x-2">
                              <div className="">{row.horse}</div>
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-800 text-right">
                            {row.amount}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {showWin === true && (
              <div className="sm:col-span-3 col-span-1 order-3 sm:order-3 sm:rounded-r-lg rounded-none overflow-hidden">
                <div className="text-green-700 font-bold px-3 py-4 uppercase bg-gradient-to-bl from-green-100 to-green-200">
                  Race Results
                </div>
                <div className="max-h-[400px] overflow-y-auto overflow-hidden">
                  {(winners || []).map((row, index) => (
                    <div key={`${row.round}+${index + 1}`} className="bg-white">
                      {(row.data || []).map((item, i) => (
                        <React.Fragment key={`${item.id}+${i + 1}`}>
                          <div className="race-item divide-y divide-gray-200 border-b-4 border-gray-200 my-1">
                            <div className="flex items-center space-x-2 justify-between px-3 py-1">
                              <div className="tracking-wide font-bold text-gray-900 py-1 text-base">
                                Race {row.round}
                              </div>
                              <div className="text-gray-500 text-xs text-right">
                                Declare Time: <br />
                                {formatDate(
                                  item[0].created_at,
                                  "MMM DD, YYYY hh:mm A"
                                )}
                              </div>
                            </div>
                            <div className="flex items-center space-x-2 justify-between px-3 py-1">
                              <div className="text-gray-400">
                                {item[0].bet_type}
                              </div>
                              <div className="text-gray-400 text-sm text-right">
                                Count: {item[0].count}
                              </div>
                            </div>
                            <div className="flex items-center space-x-2 justify-between px-3 py-1">
                              <div className="text-white bg-gray-500 px-1.5 py-0.5 text-sm rounded-md font-bold">
                                {item[0].winning_horse}{" "}
                                <span className="text-white">
                                  [x{item[0].multiplier}]{" "}
                                </span>
                              </div>
                              <div className="text-gray-500 text-xs text-right">
                                Total Amount: {item[0].total_amount}
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className="game-details sm:col-span-2 col-span-1 h-96 p-5 flex overflow-y-auto sm:hidden order-last">
              <div>
                <div
                  // eslint-disable-next-line
                  dangerouslySetInnerHTML={createMarkup(
                    _.get(data, "race_description") || "--"
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

HandlerComponent.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default HandlerComponent;
