import React from "react";
import PropTypes from "prop-types";
import Form from "partial/form/Form";
import FormInput from "partial/form/FormInput";
import Button from "partial/components/Button";
import { showDrawer } from "partial/components/Modal";
import { transformList } from "../transformers";
import { usePlayerAddCredit } from "../hooks";

export const openPlayerWalletModal = (
  title,
  playerId,
  type,
  data,
  onSuccess
) => {
  showDrawer({
    size: "xs",
    title,
    content: (onClose) => (
      <PlayerWalletModal
        data={data}
        onClose={onClose}
        onSuccess={onSuccess}
        type={type}
        playerId={playerId}
      />
    ),
  });
};

function PlayerWalletModal({ data, onClose, type, playerId }) {
  const [form, setForm] = React.useState({
    amount: 0,
  });
  const [isAddCredit, addCredit] = usePlayerAddCredit();
  const handleSubmit = () => {
    if (type === "CREDIT") {
      addCredit(data.id, playerId, form, (newData) => {
        onClose();
        // onSuccess(newData);
      });
      return;
    }
  };

  return (
    <Form className="divide-y divide-gray-200" onSubmit={handleSubmit}>
      <div>
        <div className="mb-3">
          <FormInput
            name="amount"
            onChange={setForm}
            value={form.amount}
            label="Amount"
            required
            type="number"
          />
        </div>
      </div>
      <div className="pt-5">
        <div className="flex justify-end">
          <Button type="submit" primary size="lg" disabled={isAddCredit}>
            Save
          </Button>
        </div>
      </div>
    </Form>
  );
}

PlayerWalletModal.defaultProps = {
  data: transformList(),
  onSuccess: false,
};

PlayerWalletModal.propTypes = {
  data: PropTypes.instanceOf(Object),
  onSuccess: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onClose: PropTypes.func.isRequired,
};

export default PlayerWalletModal;
