import { req, useApiGet, useApiLoading } from "react-reqq-lite";
import { useCallback, useEffect } from "react";
import {
  ITEM,
  LIST,
  PAGER,
  SUPER_MASTER_AGENT_WALLET_LIST,
  ADD_DEBIT_FUND_SUPER_MASTER_AGENT,
  ADD_CREDIT_FUND_SUPER_MASTER_AGENT,
  SUPER_MASTER_AGENT_WALLET_PAGER,
} from "./constants";
import {
  listSuperMasterAgent,
  selectSuperMasterAgent,
  listSuperMasterAgentWallet,
  addCreditFundSuperMasterAgent,
  addDebitFundSuperMasterAgent,
} from "./actions";

export const useSuperMasterAgentList = (params) => {
  const isLoading = useApiLoading(LIST, "get");
  const meta = useApiGet(PAGER, {});
  const list = useApiGet(LIST, []);
  useEffect(() => {
    listSuperMasterAgent(params);
  }, [params]);
  return [isLoading, list, meta];
};

export const useSuperMasterAgentSelected = () => {
  const isLoading = useApiLoading(ITEM, "get");
  const data = useApiGet(ITEM, {});
  return [isLoading, data];
};

export const useSelectSuperMasterAgent = (agentId) => {
  useEffect(() => {
    if (!agentId) {
      req.set(ITEM, null);
      return;
    }

    selectSuperMasterAgent(agentId);
  }, [agentId]);
};

export const useSuperMasterAgentWalletList = (masterAgentId) => {
  const isLoading = useApiLoading(SUPER_MASTER_AGENT_WALLET_LIST, "get");
  const meta = useApiGet(SUPER_MASTER_AGENT_WALLET_PAGER, {});
  const list = useApiGet(SUPER_MASTER_AGENT_WALLET_LIST, []);
  useEffect(() => {
    listSuperMasterAgentWallet(masterAgentId);
  }, [masterAgentId]);
  return [isLoading, list, meta];
};

export const useSuperMasterAgentAddCredit = () => {
  const isLoading = useApiLoading(ADD_CREDIT_FUND_SUPER_MASTER_AGENT, "post");
  const submit = useCallback((masterAgentId, payload, onSuccess) => {
    addCreditFundSuperMasterAgent(masterAgentId, payload, onSuccess);
  }, []);
  return [isLoading, submit];
};

export const useSuperMasterAgentAddDebit = () => {
  const isLoading = useApiLoading(ADD_DEBIT_FUND_SUPER_MASTER_AGENT, "post");
  const submit = useCallback((masterAgentId, payload, onSuccess) => {
    addDebitFundSuperMasterAgent(masterAgentId, payload, onSuccess);
  }, []);
  return [isLoading, submit];
};
