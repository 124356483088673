import { req } from "react-reqq-lite";
import { toastSuccess } from "react-geek-toast";
import {
  FILTER,
  ITEM,
  LIST,
  PAGER,
  MASTER_AGENT_WALLET_LIST,
  MASTER_AGENT_WALLET_PAGER,
  ADD_CREDIT_FUND_MASTER_AGENT,
  ADD_DEBIT_FUND_MASTER_AGENT,
  CHANGE_STATUS_MASTER_AGENT,
} from "./constants";
import { transformList, transformWalletList } from "./transformers";

export const listMasterAgent = (params) => {
  req.get({
    key: LIST,
    url: `/api/superMasterAgent/masterAgents`,
    params: params,
    transform: (res) => (res.data || []).map(transformList),
    onSuccess: (res) => {
      req.set(PAGER, res?.response?.meta?.pagination || {});
      req.set(FILTER, params);
    },
  });
};

export const selectMasterAgent = (id) => {
  req.get({
    key: ITEM,
    url: `/api/superMasterAgent/masterAgents/${id}`,
    onSuccess: (res) => {
      req.set(ITEM, res?.response?.data || {});
    },
  });
};

export const listMasterAgentWallet = (masterAgentId) => {
  req.get({
    key: MASTER_AGENT_WALLET_LIST,
    url: `/api/superMasterAgent/masterAgents/${masterAgentId}/transactions`,
    transform: (res) => (res?.data || []).map(transformWalletList),
    onSuccess: (res) => {
      req.set(MASTER_AGENT_WALLET_PAGER, res?.response?.meta?.pagination || {});
    },
  });
};

export const addCreditFundAgent = (masterAgentId, payload, onSuccess) => {
  req.post({
    key: ADD_CREDIT_FUND_MASTER_AGENT,
    url: `/api/superMasterAgent/masterAgents/${masterAgentId}/wallet/credit`,
    payload,
    onSuccess: (res, state) => {
      toastSuccess("Successfully Added!");
      setTimeout(() => {
        listMasterAgentWallet(masterAgentId);
      }, 1000);
      if (onSuccess) onSuccess(transformList(res?.response?.data));
    },
  });
};

export const addDebitFundAgent = (masterAgentId, payload, onSuccess) => {
  req.post({
    key: ADD_DEBIT_FUND_MASTER_AGENT,
    url: `/api/superMasterAgent/masterAgents/${masterAgentId}/wallet/debit`,
    payload,
    onSuccess: (res, state) => {
      toastSuccess("Successfully Deducted!");
      setTimeout(() => {
        listMasterAgentWallet(masterAgentId);
      }, 1000);
      if (onSuccess) onSuccess(transformList(res?.response?.data));
    },
  });
};

export const changeStatus = (masterAgentId, payload, onSuccess) => {
  req.put({
    key: CHANGE_STATUS_MASTER_AGENT,
    url: `/api/superMasterAgent/masterAgents/${masterAgentId}/change_status`,
    payload,
    onSuccess: (res, state) => {
      toastSuccess("Updated!");
      setTimeout(() => {
        listMasterAgent();
      }, 1000);
      selectMasterAgent(masterAgentId);
      if (onSuccess) onSuccess(transformList(res?.response?.data));
    },
  });
};

export const updateMasterAgent = (masterAgentId, payload, onSuccess) => {
  req.put({
    key: ITEM,
    url: `/api/superMasterAgent/masterAgents/${masterAgentId}/change_percentage`,
    payload,
    onSuccess: (res, state) => {
      toastSuccess("Updated!");
      setTimeout(() => {
        listMasterAgent();
      }, 1000);
      selectMasterAgent(masterAgentId);
      if (onSuccess) onSuccess(transformList(res?.response?.data));
    },
  });
};
