import React, { useState } from "react";
import PropTypes from "prop-types";
import { HiPlus } from "react-icons/hi";
import List from "partial/components/List";
import Button from "partial/components/Button";
import { useAgentList, useSelectAgent } from "../hooks";
import { toastSuccess } from "react-geek-toast";
import Cookies from "js-cookie";
import useClippy from "use-clippy";
import AgentListFilter from "./AgentListFilter";
import Pagination from "partial/components/Pagination";

function AgentList({ selected, onSelect }) {
  const profile = JSON.parse(Cookies.get("_profile")) || {};
  const [filter, setFilter] = React.useState({
    email: "",
    page: 1,
  });
  const [isLoading, list, pager] = useAgentList(filter);

  const [text] = useState(
    window.origin + "/agent/signup?referral_code=" + profile.referral_code
  );

  const [, setClipboard] = useClippy();

  const copyText = () => {
    setClipboard(text);
    toastSuccess("Text copied");
  };

  React.useEffect(() => {
    if (window.innerWidth <= 1024) return;
    if (!selected && list[0]) onSelect(list[0]);
  }, [selected, list, onSelect]);
  useSelectAgent(selected?.id);

  return (
    <>
      <div className="px-6 pt-6 pb-4">
        <div className="float-right">
          <Button icon={HiPlus} onClick={copyText}>
            Copy Your Referral Link
          </Button>
        </div>
        <h2 className="text-lg font-medium text-gray-900">Agents</h2>
        <p className="mt-1 text-sm text-gray-600">
          Total of {pager?.total || 0} user(s)
        </p>
        <AgentListFilter onSubmit={setFilter} value={filter} />
      </div>
      <div className="relative flex-grow">
        <div className="absolute h-full w-full top-0 left-0 overflow-auto">
          <List
            label="agent"
            data={list}
            selected={selected}
            onSelect={onSelect}
            isLoading={isLoading}
          // subTitleKey="email"
          // renderBadge={renderBadge}
          />
        </div>
      </div>
      <Pagination onChange={setFilter} value={pager} />
    </>
  );
}

AgentList.defaultProps = {
  selected: null,
};

AgentList.propTypes = {
  selected: PropTypes.instanceOf(Object),
  onSelect: PropTypes.func.isRequired,
};

export default AgentList;
