export const LIST = "PLAYER/list";
export const PAGER = "PLAYER/pager";
export const ITEM = "PLAYER/item";
export const FILTER = "PLAYER/filter";

export const PLAYER_WALLET_LIST = "PLAYER/get_player_wallet_list";
export const PLAYER_WALLET_PAGER = "PLAYER/get_player_wallet_pager";
export const ADD_CREDIT_FUND_PLAYER = "PLAYER/add_credit_agent";

export const CHANGE_STATUS_PLAYER = "PLAYER/change_status_agent";
