import { useApiGet, useApiLoading } from "react-reqq-lite";
import { useEffect } from "react";
import { ITEM, LIST, PAGER } from "./constants";
import { getMyWallet, listTransaction } from "./actions";

export const useTransactionList = (params) => {
  const isLoading = useApiLoading(LIST, "get");
  const meta = useApiGet(PAGER, {});
  const list = useApiGet(LIST, []);
  useEffect(() => {
    listTransaction(params);
  }, [params]);
  return [isLoading, list, meta];
};

export const useGetWallet = () => {
  const isLoading = useApiLoading(ITEM, "get");
  const data = useApiGet(ITEM, {});
  useEffect(() => {
    getMyWallet();
  }, []);
  return [isLoading, data];
};
