import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "partial/components/Button";
import { BsWallet } from "react-icons/bs";
import { FiDollarSign } from "react-icons/fi";
import PlayerWalletContainer from "../components/PlayerWalletContainer";
import PlayerCashoutContainer from "../components/PlayerCashoutContainer";
import { usePlayerList } from "modules/master-agent/agents/hooks";
import _ from "lodash";
import { HiChevronLeft } from "react-icons/hi";
import Pagination from "partial/components/Pagination";

export default function PlayerContainer({ data }) {
  const [showPlayerWallet, setShowPlayerWallet] = useState(false);
  const [showPlayerCashout, setShowPlayerCashout] = useState(false);

  const [selectedPlayerId, setSelectedPlayerId] = useState(false);

  const [filter, setFilter] = React.useState({
    page: 1,
  });

  const [, list, pager] = usePlayerList(data.id, filter);

  console.log(pager, "here");

  const handleOnViewPlayerWallet = (item) => (e) => {
    e.preventDefault();
    setSelectedPlayerId(_.get(item, "attributes.user.id"));
    setShowPlayerWallet(true);
    setShowPlayerCashout(false);
  };

  const handleOnViewPlayerCashout = (item) => (e) => {
    e.preventDefault();
    setSelectedPlayerId(item.id);
    setShowPlayerCashout(true);
    setShowPlayerWallet(false);
  };

  const backToListing = () => (e) => {
    e.preventDefault();
    setShowPlayerWallet(false);
    setShowPlayerCashout(false);
  };

  return (
    <>
      {showPlayerWallet === false &&
        showPlayerCashout === false &&
        ((list || []).length > 0 ? (
          <div>
            <div className="mt-4 flex flex-col">
              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                  <div className="overflow-hidden">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr>
                          <th scope="col">
                            <span className="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider uppercase flex items-center space-x-2">
                              First Name
                            </span>
                          </th>
                          <th scope="col">
                            <span className="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider uppercase flex items-center space-x-2">
                              Last Name
                            </span>
                          </th>
                          <th scope="col">
                            <span className="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider uppercase flex items-center space-x-2">
                              Contact Number
                            </span>
                          </th>
                          <th scope="col">
                            <span className="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider uppercase flex items-center space-x-2">
                              Address
                            </span>
                          </th>
                          <th
                            scope="col"
                            className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                          >
                            <span className="sr-only">Action</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {(list || []).map((row) => (
                          <tr key={row.id}>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base font-medium text-gray-900 sm:pl-6">
                              {_.get(row, "attributes.first_name")}
                            </td>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base font-medium text-gray-900 sm:pl-6">
                              {_.get(row, "attributes.last_name")}
                            </td>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base font-medium text-gray-900 sm:pl-6">
                              {_.get(row, "attributes.phone_number")}
                            </td>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base font-medium text-gray-900 sm:pl-6">
                              {_.get(row, "attributes.address")}
                            </td>
                            <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 flex justify-end">
                              <span className="flex space-x-2">
                                <Button
                                  type="button"
                                  default
                                  icon={BsWallet}
                                  onClick={handleOnViewPlayerWallet(row)}
                                >
                                  <span>View Wallet</span>
                                </Button>
                                <Button
                                  type="button"
                                  default
                                  icon={FiDollarSign}
                                  onClick={handleOnViewPlayerCashout(row)}
                                >
                                  <span>View Cashout</span>
                                </Button>
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <Pagination onChange={setFilter} value={pager} />
          </div>
        ) : (
          <span> No player for this agent! </span>
        ))}
      {showPlayerWallet === true && showPlayerCashout === false && (
        <>
          <div className="border-b sm:border-none pt-2 sm:pt-0 border-gray-700 sm:w-auto w-full pb-2 sm:pb-0">
            <Button
              primary
              className="mr-auto sm:mr-0"
              onClick={backToListing()}
              icon={HiChevronLeft}
              type="button"
              size="sm"
            >
              Back to Listing
            </Button>
          </div>
          <PlayerWalletContainer
            agentId={data.id}
            playerId={selectedPlayerId}
          />
        </>
      )}
      {showPlayerCashout === true && showPlayerWallet === false && (
        <>
          <div className="border-b sm:border-none pt-2 sm:pt-0 border-gray-700 sm:w-auto w-full pb-2 sm:pb-0">
            <Button
              primary
              className="mr-auto sm:mr-0"
              onClick={backToListing()}
              icon={HiChevronLeft}
              type="button"
              size="sm"
            >
              Back to Listing
            </Button>
          </div>
          <PlayerCashoutContainer
            agentId={data.id}
            playerId={selectedPlayerId}
          />
        </>
      )}
    </>
  );
}

PlayerContainer.defaultProps = {
  data: {},
};

PlayerContainer.propTypes = {
  data: PropTypes.instanceOf(Object),
};
