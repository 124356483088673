import React from "react";
import Button from "partial/components/Button";
import {
  useApproveCashout,
  usePlayerCashoutList,
} from "modules/master-agent/agents/hooks";
import { HiOutlineEye } from "react-icons/hi";
import { showAlert } from "partial/components/Modal";
import Pagination from "partial/components/Pagination";

function PlayerCashoutContainer({ agentId, playerId }) {
  const [filter, setFilter] = React.useState({
    page: 1,
  });
  const [, list, pager] = usePlayerCashoutList(agentId, playerId, filter);

  const renderBadge = React.useCallback(
    (row) => (
      <>
        {row === "APPROVE" && (
          <span className="badge badge-success">
            <i class="feather icon-check"></i>
          </span>
        )}
        {row === "DECLINED" && (
          <span className="badge badge-danger">
            <i class="fa fa-times"></i>
          </span>
        )}
      </>
    ),
    []
  );

  const [, approveCashout] = useApproveCashout();

  const handleOnApproveCashout = (row) => (e) => {
    e.preventDefault();
    showAlert({
      title: "Approve Cashout?",
      yesLabel: "Approve Cashout?",
      onYes: (closeAlert) => {
        closeAlert();
        approveCashout(row.id);
      },
    });
  };

  return (
    <div>
      <div className="mt-4 flex flex-col">
        <div className="flex items-center border px-4 py-3 bg-white shadow-sm mb-2">
          <div className="flex items-center space-x-2">
            <div className="sm:flex-auto">
              <p className="text-sm text-gray-700">Cashout History</p>
            </div>
          </div>
        </div>
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col">
                      <span className="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider uppercase flex items-center space-x-2">
                        Date
                      </span>
                    </th>
                    <th scope="col">
                      <span className="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider uppercase flex items-center space-x-2">
                        Amount
                      </span>
                    </th>
                    <th scope="col">
                      <span className="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider uppercase flex items-center space-x-2">
                        Status
                      </span>
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Action</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {list.map((row) => (
                    <tr key={row.name}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base font-medium text-gray-900 sm:pl-6">
                        {row.attributes.date}
                      </td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base font-medium text-gray-900 sm:pl-6">
                        {row.attributes.amount}
                      </td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base font-medium text-gray-900 sm:pl-6">
                        {renderBadge(row.attributes.status)}
                      </td>
                      <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 flex justify-end">
                        <span className="flex space-x-2">
                          {row.attributes.status === "PENDING" && (
                            <Button
                              type="button"
                              default
                              icon={HiOutlineEye}
                              onClick={handleOnApproveCashout(row)}
                            >
                              <span>Approve Cashout</span>
                            </Button>
                          )}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Pagination onChange={setFilter} value={pager} />
    </div>
  );
}

export default PlayerCashoutContainer;
