import { useApiGet, useApiLoading } from "react-reqq-lite";
import { useEffect } from "react";
import { LIST, PAGER, ITEM } from "./constants";
import { listReport, selectReport } from "./actions";

export const useReportList = (params) => {
  const isLoading = useApiLoading(LIST, "get");
  const meta = useApiGet(PAGER, {});
  const list = useApiGet(LIST, []);
  useEffect(() => {
    listReport(params);
  }, [params]);
  return [isLoading, list, meta];
};

export const useReportSelected = () => {
  const isLoading = useApiLoading(ITEM, "get");
  const data = useApiGet(ITEM, {});
  useEffect(() => {
    selectReport();
  }, []);
  return [isLoading, data];
};
