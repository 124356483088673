import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";
import { useInputId } from "./hooks";

function FormInput({
  id: defaultId,
  label,
  name,
  onChange,
  value,
  error,
  required,
  ...props
}) {
  const [id] = useInputId(defaultId);
  const handleChange = ({ target }) => {
    onChange((state) => ({
      ...state,
      [name]: target.value,
    }));
  };
  const isError = !!error;
  return (
    <div
      className={cn(
        "relative border rounded-md px-3 py-2 shadow-sm focus-within:ring-1 bg-white",
        isError
          ? "border-red-300 focus-within:ring-red-500 focus-within:border-red-500"
          : "border-gray-300 focus-within:ring-primary-600 focus-within:border-primary-600"
      )}
    >
      <label htmlFor={id} className="block text-xs font-medium text-gray-900">
        <span>{label}</span>
        {!required && <span className="ml-1 font-light">(Optional)</span>}
      </label>
      <input
        id={id}
        type="text"
        name="name"
        className={cn(
          "block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-base font-semibold",
          "focus:outline-none"
        )}
        onChange={handleChange}
        value={value || ""}
        {...props}
      />
    </div>
  );
}

FormInput.defaultProps = {
  id: "",
  type: "text",
  error: null,
  required: false,
};

FormInput.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  error: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  onChange: PropTypes.func.isRequired,
};

export default React.memo(FormInput);
