export const HANDLER_LIST = "HANDLERS/list";
export const HANDLER_PAGER = "HANDLERS/pager";
export const HANDLER_FILTER = "HANDLERS/filter";
export const HANDLER_ITEM = "HANDLERS/item";

export const HANDLER_CURRENT_ROOM = "HANDLERS/current_room";
export const HANDLER_LIVE_GAME = "HANDLER/get_live_game";
export const HANDLER_CHANGE_STATE = "HANDLER/change_state";
export const HANDLER_CHANGE_BET = "HANDLER/change_bet";
export const SET_WINNING_HORSE = "HANDLER/set_winning_horse";

export const SCRATCH_NORMAL = "GAME/scratch_normal";
export const SCRATCH_EXOTIC = "GAME/scratch_exo";

export const FORM_DATA = {
  url: "",
  state: false,
  number_of_rounds: "",
  name: "",
  race_description: "",
};
