import React from "react";
import PropTypes from "prop-types";
import Form from "partial/form/Form";
import FormInput from "partial/form/FormInput";
import Button from "partial/components/Button";
import { showDrawer } from "partial/components/Modal";
import {
  usePlayerAddCredit,
  usePlayerAddDebit,
} from "modules/master-agent/agents/hooks";

export const openPlayerWalletModal = (
  title,
  agentId,
  playerId,
  type,
  data,
  onSuccess
) => {
  showDrawer({
    size: "xs",
    title,
    content: (onClose) => (
      <PlayerWalletModal
        data={data}
        onClose={onClose}
        onSuccess={onSuccess}
        type={type}
        agentId={agentId}
        playerId={playerId}
      />
    ),
  });
};

function PlayerWalletModal({
  data,
  onClose,
  onSuccess,
  type,
  agentId,
  playerId,
}) {
  const [form, setForm] = React.useState({
    amount: 0,
  });
  const [isAddCredit, addCredit] = usePlayerAddCredit();
  const [isAddDebit, addDebit] = usePlayerAddDebit();
  const handleSubmit = () => {
    if (type === "CREDIT") {
      addCredit(agentId, playerId, form, (newData) => {
        onClose();
        onSuccess(newData);
      });
      return;
    }
    if (type === "DEBIT") {
      addDebit(agentId, playerId, form, onClose);
    }
  };

  return (
    <Form className="divide-y divide-gray-200" onSubmit={handleSubmit}>
      <div>
        <div className="mb-3">
          <FormInput
            name="amount"
            onChange={setForm}
            value={form.amount}
            label="Amount"
            required
            type="number"
          />
        </div>
      </div>
      <div className="pt-5">
        <div className="flex justify-end">
          <Button
            type="submit"
            primary
            size="lg"
            disabled={isAddCredit || isAddDebit}
          >
            Save
          </Button>
        </div>
      </div>
    </Form>
  );
}

PlayerWalletModal.defaultProps = {
  agentId: "",
  playerId: "",
  type: "",
  onSuccess: false,
};

PlayerWalletModal.propTypes = {
  agentId: PropTypes.number,
  playerId: PropTypes.number,
  type: PropTypes.string,
  onSuccess: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onClose: PropTypes.func.isRequired,
};

export default PlayerWalletModal;
