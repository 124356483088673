import React from "react";
import { Switch, Route, Redirect, Link } from "react-router-dom";
import HeaderProfile from "partial/components/HeaderProfile";
import logo from "assets/images/logo-inverted.png";
import ModuleWrapper, {
  ModuleLabel,
  ModuleRoute,
} from "partial/wrappers/ModuleWrapper";
import Logout from "modules/auth/containers/Logout";
import PageNotFound from "partial/containers/PageNotFound";
import Profile from "partial/containers/Profile";
import HandlerPageRoutes from "modules/handler/containers/HandlerPageRoutes";
import HandlerComponent from "modules/handler/components/HandlerComponent";

function HandlerRoutes() {
  return (
    <ModuleWrapper>
      <div className="h-screen flex overflow-hidden bg-gray-100">
        <div className="flex flex-col w-0 flex-1 overflow-hidden">
          <div className="relative z-10 flex-shrink-0 flex h-16 bg-white shadow">
            <div className="flex-1 px-4 flex justify-between">
              <div className="flex-1 flex items-center relative">
                <div className="absolute w-full line-clamp-1">
                  <div className="flex items-center space-x-2">
                    <Link to="/">
                      <img className="h-10 w-auto" src={logo} alt="Brand" />
                    </Link>
                    <h1 className="text-2xl font-semibold text-gray-900 truncate">
                      <ModuleLabel />
                    </h1>
                  </div>
                </div>
              </div>
              <div className="ml-4 flex items-center md:ml-6">
                {/* Profile dropdown */}
                <HeaderProfile />
              </div>
            </div>
          </div>
          <main className="flex-1 relative overflow-y-auto focus:outline-none flex flex-col">
            <Switch>
              <ModuleRoute
                title="Handler"
                path="/handler/session"
                component={HandlerPageRoutes}
              />
              <ModuleRoute
                title="Profile"
                path="/profile"
                component={Profile}
              />
              <ModuleRoute
                title="Handler Room"
                path="/handler/session-details"
                component={HandlerComponent}
              />
              <Route path="/logout" component={Logout} />
              <Route exact path="/">
                <Redirect to="/handler/session" />
              </Route>
              <Route component={PageNotFound} />
            </Switch>
          </main>
        </div>
      </div>
    </ModuleWrapper>
  );
}

export default HandlerRoutes;
