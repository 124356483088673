import { req } from "react-reqq-lite";
import { transformPlayerRoom } from "./transformers";
import * as c from "./constants";
import { toastSuccess } from "react-geek-toast";
import Cookies from "js-cookie";

export const listPlayerRooms = (params) => {
  req.get({
    key: c.PLAYER_ROOM_LIST,
    url: "/api/player/room",
    params: params,
    transform: (res) => (res.data || []).map(transformPlayerRoom),
    onSuccess: (res) => {},
  });
};

export const selectGameRoom = (id) => {
  req.get({
    key: c.SELECT_GAME_ROOM,
    url: `/api/player/room/${id}/live`,
    onSuccess: (res, state) => {
      Cookies.set("_sessionId", res?.response?.data.id);
    },
  });
};

export const getPlayerWalletDetails = () => {
  req.get({
    key: c.PLAYER_WALLET_DETAILS,
    url: `/api/my/wallet`,
    onSuccess: (res, state) => {
      // transformList(res?.response?.data?.profile);
    },
  });
};

export const addBet = (payload, roomId, onSuccess) => {
  req.post({
    key: c.PLAYER_BET,
    url: `/api/player/room/${roomId}/game/bet`,
    payload,
    onSuccess: (res, state) => {
      setTimeout(() => {
        selectGameRoom(roomId);
        getPlayerWalletDetails();
      }, 1000);
      toastSuccess(res.response.message);
      if (onSuccess) onSuccess(res?.response?.data);
    },
  });
};
