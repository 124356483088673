import { req, useApiGet, useApiLoading } from "react-reqq-lite";
import { useCallback, useEffect } from "react";
import {
  ITEM,
  LIST,
  PAGER,
  AGENT_WALLET_LIST,
  AGENT_WALLET_PAGER,
  APPROVE_CASHOUT,
  CHANGE_STATUS_AGENT,
  ADD_DEBIT_FUND_PLAYER,
  ADD_CREDIT_FUND_PLAYER,
  ADD_DEBIT_FUND_AGENT,
  ADD_CREDIT_FUND_AGENT,
  AGENT_PLAYER_LIST,
  AGENT_PLAYER_PAGER,
  AGENT_PLAYER_CASHOUT_LIST,
  AGENT_PLAYER_CASHOUT_PAGER,
  AGENT_PLAYER_WALLET_LIST,
  AGENT_PLAYER_WALLET_PAGER,
} from "./constants";
import {
  listAgent,
  selectAgent,
  listPlayerWallet,
  listAgentPlayer,
  approveCashout,
  changeStatus,
  listAgentWallet,
  addCreditFundPlayer,
  addDebitFundPlayer,
  listPlayerCashout,
  addDebitFundAgent,
  addCreditFundAgent,
  updateAgent,
} from "./actions";

export const useAgentList = (params) => {
  const isLoading = useApiLoading(LIST, "get");
  const meta = useApiGet(PAGER, {});
  const list = useApiGet(LIST, []);
  useEffect(() => {
    listAgent(params);
  }, [params]);
  return [isLoading, list, meta];
};

export const useAgentSelected = () => {
  const isLoading = useApiLoading(ITEM, "get");
  const data = useApiGet(ITEM, {});
  return [isLoading, data];
};

export const useSelectAgent = (masterAgentId) => {
  useEffect(() => {
    if (!masterAgentId) {
      req.set(ITEM, null);
      return;
    }

    selectAgent(masterAgentId);
  }, [masterAgentId]);
};

export const usePlayerList = (agentId, params) => {
  const isLoading = useApiLoading(AGENT_PLAYER_LIST, "get");
  const meta = useApiGet(AGENT_PLAYER_PAGER, {});
  const list = useApiGet(AGENT_PLAYER_LIST, []);
  useEffect(() => {
    listAgentPlayer(agentId, params);
  }, [agentId, params]);
  return [isLoading, list, meta];
};

export const usePlayerCashoutList = (params, agentId, playerId) => {
  const isLoading = useApiLoading(AGENT_PLAYER_CASHOUT_LIST, "get");
  const meta = useApiGet(AGENT_PLAYER_CASHOUT_PAGER, {});
  const list = useApiGet(AGENT_PLAYER_CASHOUT_LIST, []);
  useEffect(() => {
    listPlayerCashout(params, agentId, playerId);
  }, [params, agentId, playerId]);
  return [isLoading, list, meta];
};

export const usePlayerWalletList = (params, agentId, playerId) => {
  const isLoading = useApiLoading(AGENT_PLAYER_WALLET_LIST, "get");
  const meta = useApiGet(AGENT_PLAYER_WALLET_PAGER, {});
  const list = useApiGet(AGENT_PLAYER_WALLET_LIST, []);
  useEffect(() => {
    listPlayerWallet(params, agentId, playerId);
  }, [params, agentId, playerId]);
  return [isLoading, list, meta];
};

export const useApproveCashout = () => {
  const isLoading = useApiLoading(APPROVE_CASHOUT, "put");
  const submit = useCallback((playerId, cashoutId, payload, onSuccess) => {
    approveCashout(playerId, cashoutId, payload, onSuccess);
  }, []);
  return [isLoading, submit];
};

export const useChangeStatus = () => {
  const isLoading = useApiLoading(CHANGE_STATUS_AGENT, "put");
  const submit = useCallback((id, payload, onSuccess) => {
    changeStatus(id, payload, onSuccess);
  }, []);
  return [isLoading, submit];
};

export const useAgentWalletList = (params, agentId) => {
  const isLoading = useApiLoading(AGENT_WALLET_LIST, "get");
  const meta = useApiGet(AGENT_WALLET_PAGER, {});
  const list = useApiGet(AGENT_WALLET_LIST, []);
  useEffect(() => {
    listAgentWallet(params, agentId);
  }, [params, agentId]);
  return [isLoading, list, meta];
};

export const usePlayerAddDebit = () => {
  const isLoading = useApiLoading(ADD_DEBIT_FUND_PLAYER, "post");
  const submit = useCallback((agentId, playerId, payload, onSuccess) => {
    addDebitFundPlayer(agentId, playerId, payload, onSuccess);
  }, []);
  return [isLoading, submit];
};

export const usePlayerAddCredit = () => {
  const isLoading = useApiLoading(ADD_CREDIT_FUND_PLAYER, "post");
  const submit = useCallback((agentId, playerId, payload, onSuccess) => {
    addCreditFundPlayer(agentId, playerId, payload, onSuccess);
  }, []);
  return [isLoading, submit];
};

export const useAgentAddDebit = () => {
  const isLoading = useApiLoading(ADD_DEBIT_FUND_AGENT, "post");
  const submit = useCallback((agentId, payload, onSuccess) => {
    addDebitFundAgent(agentId, payload, onSuccess);
  }, []);
  return [isLoading, submit];
};

export const useAgentAddCredit = () => {
  const isLoading = useApiLoading(ADD_CREDIT_FUND_AGENT, "post");
  const submit = useCallback((agentId, payload, onSuccess) => {
    addCreditFundAgent(agentId, payload, onSuccess);
  }, []);
  return [isLoading, submit];
};

export const useUpdateAgent = () => {
  const isLoading = useApiLoading(ITEM, "put");
  const submit = useCallback((agentId, payload, onSuccess) => {
    updateAgent(agentId, payload, onSuccess);
  }, []);
  return [isLoading, submit];
};
