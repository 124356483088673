import { req, useApiGet, useApiLoading } from "react-reqq-lite";
import { useCallback, useEffect } from "react";
import {
  ITEM,
  LIST,
  PAGER,
  MASTER_AGENT_WALLET_LIST,
  ADD_DEBIT_FUND_MASTER_AGENT,
  ADD_CREDIT_FUND_MASTER_AGENT,
  MASTER_AGENT_WALLET_PAGER,
} from "./constants";
import {
  listMasterAgent,
  selectMasterAgent,
  listMasterAgentWallet,
  addCreditFundMasterAgent,
  addDebitFundMasterAgent,
} from "./actions";

export const useMasterAgentList = (params) => {
  const isLoading = useApiLoading(LIST, "get");
  const meta = useApiGet(PAGER, {});
  const list = useApiGet(LIST, []);
  useEffect(() => {
    listMasterAgent(params);
  }, [params]);
  return [isLoading, list, meta];
};

export const useMasterAgentSelected = () => {
  const isLoading = useApiLoading(ITEM, "get");
  const data = useApiGet(ITEM, {});
  return [isLoading, data];
};

export const useSelectMasterAgent = (agentId) => {
  useEffect(() => {
    if (!agentId) {
      req.set(ITEM, null);
      return;
    }

    selectMasterAgent(agentId);
  }, [agentId]);
};

export const useMasterAgentWalletList = (masterAgentId) => {
  const isLoading = useApiLoading(MASTER_AGENT_WALLET_LIST, "get");
  const meta = useApiGet(MASTER_AGENT_WALLET_PAGER, {});
  const list = useApiGet(MASTER_AGENT_WALLET_LIST, []);
  useEffect(() => {
    listMasterAgentWallet(masterAgentId);
  }, [masterAgentId]);
  return [isLoading, list, meta];
};

export const useMasterAgentAddCredit = () => {
  const isLoading = useApiLoading(ADD_CREDIT_FUND_MASTER_AGENT, "post");
  const submit = useCallback((masterAgentId, payload, onSuccess) => {
    addCreditFundMasterAgent(masterAgentId, payload, onSuccess);
  }, []);
  return [isLoading, submit];
};

export const useMasterAgentAddDebit = () => {
  const isLoading = useApiLoading(ADD_DEBIT_FUND_MASTER_AGENT, "post");
  const submit = useCallback((masterAgentId, payload, onSuccess) => {
    addDebitFundMasterAgent(masterAgentId, payload, onSuccess);
  }, []);
  return [isLoading, submit];
};
