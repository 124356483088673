import cn from "classnames";
import PropTypes from "prop-types";
import { render } from "react-dom";
import React, { Fragment } from "react";
import { Transition } from "@headlessui/react";
import {
  HiCheckCircle,
  HiExclamationCircle,
  HiInformationCircle,
  HiX,
  HiXCircle,
} from "react-icons/hi";

const toastUniqueID = "my-toast-marker";

const ToastMarker = () => <div id={toastUniqueID} />;

const toast = async (content, options = {}) => {
  const opt = {
    icon: null,
    title: content,
    subTitle: "",
    closeButton: true,
    timeout: 4000,
    ...options,
  };
  const root = document.getElementById(toastUniqueID);
  const handleClose = () => {
    render(<ToastMarker />, root);
  };
  if (!root) {
    alert("Modal root not found!"); // eslint-disable-line
    return;
  }
  render(<Notification options={opt} onClose={handleClose} />, root);
};

const COLORS = {
  success: "text-green-500",
  warning: "text-yellow-500",
  info: "text-blue-500",
  danger: "text-red-500",
  default: "text-gray-500",
};

function Notification({ options, onClose }) {
  const [show, setShow] = React.useState(false);
  React.useEffect(() => {
    setShow(true);
  }, [setShow]);
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(false);
    }, options.timeout);
    return () => {
      clearTimeout(timeout);
    };
  }, [show, setShow, options]);
  const Icon = options.icon;
  return (
    <div
      aria-live="assertive"
      className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-50"
    >
      <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
        <Transition
          show={show}
          as={Fragment}
          enter="transform ease-out duration-300 transition"
          enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enterTo="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={onClose}
        >
          <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div className="p-4">
              <div className="flex items-start">
                {Icon && (
                  <div className="flex-shrink-0">
                    <Icon
                      className={cn(
                        "h-6 w-6",
                        COLORS[options.type] || COLORS.default
                      )}
                      aria-hidden="true"
                    />
                  </div>
                )}
                <div className="ml-3 w-0 flex-1 pt-0.5">
                  <p className="text-base font-medium text-gray-900">
                    {options.title}
                  </p>
                  {options.subTitle && (
                    <p className="mt-1 text-sm text-gray-500">
                      {options.subTitle}
                    </p>
                  )}
                </div>
                {options.closeButton && (
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                      onClick={() => {
                        setShow(false);
                      }}
                      type="button"
                    >
                      <span className="sr-only">Close</span>
                      <HiX className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  );
}

Notification.propTypes = {
  options: PropTypes.instanceOf(Object).isRequired,
  onClose: PropTypes.func.isRequired,
};

export const toastSuccess = (content, options) =>
  toast(content, { icon: HiCheckCircle, ...options, type: "success" });

export const toastWarning = (content, options) =>
  toast(content, { icon: HiExclamationCircle, ...options, type: "warning" });

export const toastInfo = (content, options) =>
  toast(content, { icon: HiInformationCircle, ...options, type: "info" });

export const toastError = (content, options) =>
  toast(content, { icon: HiXCircle, ...options, type: "danger" });

export default ToastMarker;
