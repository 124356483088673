import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "partial/components/Button";
import { HiOutlineMinus, HiOutlinePlus } from "react-icons/hi";
import { usePlayerWalletList } from "../hooks";
import { RiWalletFill } from "react-icons/ri";
import { showDrawer } from "partial/components/Modal";
import PlayerWalletModal from "../modals/PlayerWalletModal";
import Pagination from "partial/components/Pagination";
import _ from "lodash";

function PlayerWallet({ data }) {
  const [filter, setFilter] = React.useState({
    page: 1,
  });
  const [, list, pager] = usePlayerWalletList(
    _.get(data, "attributes.user.id"),
    filter
  );
  const [balance, setBalance] = useState(0);

  React.useEffect(() => {
    if (list.length > 0) {
      setBalance(list[0].attributes.balance);
    }
  }, [list]);

  const handleAddCredit = () => (e) => {
    showDrawer({
      size: "xs",
      title: "Add Credit",
      content: (onClose) => (
        <PlayerWalletModal
          onClose={onClose}
          type="CREDIT"
          playerId={_.get(data, "attributes.user.id")}
        />
      ),
    });
  };

  const handleAddDebit = () => (e) => {
    showDrawer({
      size: "xs",
      title: "Add Debit",
      content: (onClose) => (
        <PlayerWalletModal
          onClose={onClose}
          type="DEBIT"
          playerId={_.get(data, "attributes.user.id")}
        />
      ),
    });
  };

  return (
    <>
      <div>
        <div className="mt-4 flex flex-col">
          <div className="col-md-12">
            <div className="flex items-center pb-3 border-b mb-4">
              <div className="flex items-center space-x-2">
                <span className="bg-green-50 rounded-full p-3">
                  <RiWalletFill className="w-6 h-6 flex-shrink-0 text-green-500" />
                </span>
                <div className="sm:flex-auto">
                  <p className="text-sm text-gray-700">Total Balances</p>
                  <p className="text-lg text-green-500 font-semibold flex items-center space-x-1">
                    <span>{balance}</span>
                    <span className="text-xs text-gray-400">PHP</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="space-x-2 text-gray-600 flex items-center mb-4">
              <Button type="button" danger onClick={handleAddCredit()}>
                <HiOutlinePlus className="w-3 h-3" />
              </Button>
              <Button type="button" danger onClick={handleAddDebit()}>
                <HiOutlineMinus className="w-3 h-3" />
              </Button>
            </div>
          </div>
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col">
                        <span className="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider uppercase flex items-center space-x-2">
                          Transaction
                        </span>
                      </th>
                      <th scope="col">
                        <span className="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider uppercase flex items-center space-x-2">
                          Amount
                        </span>
                      </th>
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                      >
                        <span className="sr-only">Action</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {list.map((row) => (
                      <tr key={row.id}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {row.attributes.type}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {row.attributes.amount}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Pagination onChange={setFilter} value={pager} />
    </>
  );
}

PlayerWallet.defaultProps = {
  data: {},
};

PlayerWallet.propTypes = {
  data: PropTypes.instanceOf(Object),
};

export default PlayerWallet;
