import { req } from "react-reqq-lite";
import { toastSuccess } from "react-geek-toast";
import {
  FILTER,
  ITEM,
  LIST,
  PAGER,
  AGENT_WALLET_LIST,
  AGENT_WALLET_PAGER,
  AGENT_PLAYER_LIST,
  AGENT_PLAYER_PAGER,
  AGENT_PLAYER_WALLET_PAGER,
  AGENT_PLAYER_WALLET_LIST,
  ADD_CREDIT_FUND_AGENT,
  ADD_DEBIT_FUND_AGENT,
  CHANGE_STATUS_AGENT,
  ADD_CREDIT_FUND_PLAYER,
  ADD_DEBIT_FUND_PLAYER,
  AGENT_PLAYER_CASHOUT_LIST,
  APPROVE_CASHOUT,
  AGENT_PLAYER_CASHOUT_PAGER,
} from "./constants";
import { transformList, transformWalletList } from "./transformers";

export const listAgent = (params) => {
  req.get({
    key: LIST,
    url: `/api/masterAgent/agents`,
    params: params,
    transform: (res) => (res.data || []).map(transformList),
    onSuccess: (res) => {
      req.set(PAGER, res?.response?.meta?.pagination || {});
      req.set(FILTER, params);
    },
  });
};

export const selectAgent = (id) => {
  req.get({
    key: ITEM,
    url: `/api/masterAgent/agents/${id}`,
    onSuccess: (res) => {
      req.set(ITEM, res?.response?.data || {});
    },
  });
};

export const listAgentPlayer = (agentId, params) => {
  req.get({
    key: AGENT_PLAYER_LIST,
    url: `/api/masterAgent/agents/${agentId}/players`,
    params: params,
    // transform: (res) => (res?.data || []).map(transformList),
    onSuccess: (res) => {
      req.set(AGENT_PLAYER_LIST, res?.response?.data || []);
      req.set(AGENT_PLAYER_PAGER, res?.response?.meta?.pagination || {});
    },
  });
};

export const listPlayerWallet = (agentId, playerId, params) => {
  req.get({
    key: AGENT_PLAYER_WALLET_LIST,
    url: `/api/masterAgent/agents/${agentId}/players/${playerId}/wallet/transactions`,
    params: params,
    transform: (res) => (res?.data || []).map(transformWalletList),
    onSuccess: (res) => {
      req.set(AGENT_PLAYER_WALLET_PAGER, res?.response?.meta?.pagination || {});
      // req.set(PAGER, transormPager(res?.response?.meta));
    },
  });
};

export const listAgentWallet = (agentId) => {
  req.get({
    key: AGENT_WALLET_LIST,
    url: `/api/masterAgent/agents/${agentId}/transactions`,
    transform: (res) => (res?.data || []).map(transformWalletList),
    onSuccess: (res) => {
      req.set(AGENT_WALLET_PAGER, res?.response?.meta?.pagination || {});
    },
  });
};

export const listPlayerCashout = (agentId, playerId, params) => {
  req.get({
    key: AGENT_PLAYER_CASHOUT_LIST,
    url: `/api/masterAgent/agents/${agentId}/players/${playerId}/cashouts`,
    params: params,
    transform: (res) => (res?.data || []).map(transformWalletList),
    onSuccess: (res) => {
      req.set(
        AGENT_PLAYER_CASHOUT_PAGER,
        res?.response?.meta?.pagination || {}
      );
    },
  });
};

export const approveCashout = (playerId, cashoutId, payload, onSuccess) => {
  req.put({
    key: APPROVE_CASHOUT,
    url: `/api/agent/players/${playerId}/cashout/${cashoutId}}/approve`,
    payload,
    onSuccess: (res, state) => {
      toastSuccess("Updated!");
      setTimeout(() => {
        listPlayerCashout();
      }, 1000);
      if (onSuccess) onSuccess(transformList(res?.response?.data));
    },
  });
};

export const addCreditFundAgent = (agentId, payload, onSuccess) => {
  req.post({
    key: ADD_CREDIT_FUND_AGENT,
    url: `/api/masterAgent/agents/${agentId}/wallet/credit`,
    payload,
    onSuccess: (res, state) => {
      toastSuccess("Successfully Added!");
      setTimeout(() => {
        listAgentWallet(agentId);
      }, 1000);
      if (onSuccess) onSuccess(transformList(res?.response?.data));
    },
  });
};

export const addDebitFundAgent = (agentId, payload, onSuccess) => {
  req.post({
    key: ADD_DEBIT_FUND_AGENT,
    url: `/api/masterAgent/agents/${agentId}/wallet/debit`,
    payload,
    onSuccess: (res, state) => {
      toastSuccess("Successfully Deducted!");
      setTimeout(() => {
        listAgentWallet(agentId);
      }, 1000);
      if (onSuccess) onSuccess(transformList(res?.response?.data));
    },
  });
};

export const changeStatus = (agentId, payload, onSuccess) => {
  req.put({
    key: CHANGE_STATUS_AGENT,
    url: `/api/masterAgent/agents/${agentId}/change_status`,
    payload,
    onSuccess: (res, state) => {
      toastSuccess("Updated!");
      setTimeout(() => {
        listAgent();
      }, 1000);
      selectAgent(agentId);
      if (onSuccess) onSuccess(transformList(res?.response?.data));
    },
  });
};

export const addCreditFundPlayer = (agentId, playerId, payload, onSuccess) => {
  req.post({
    key: ADD_CREDIT_FUND_PLAYER,
    url: `/api/masterAgent/agents/${agentId}/players/${playerId}/wallet/credit`,
    payload,
    onSuccess: (res, state) => {
      toastSuccess("Successfully Added!");
      setTimeout(() => {
        listPlayerWallet(agentId, playerId);
      }, 1000);
      selectAgent(agentId);
      if (onSuccess) onSuccess(transformList(res?.response?.data));
    },
  });
};

export const addDebitFundPlayer = (agentId, playerId, payload, onSuccess) => {
  req.post({
    key: ADD_DEBIT_FUND_PLAYER,
    url: `/api/masterAgent/agents/${agentId}/players/${playerId}/wallet/debit`,
    payload,
    onSuccess: (res, state) => {
      toastSuccess("Successfully Added!");
      setTimeout(() => {
        listPlayerWallet(agentId, playerId);
      }, 1000);
      selectAgent(agentId);
      if (onSuccess) onSuccess(transformList(res?.response?.data));
    },
  });
};

export const updateAgent = (agentId, payload, onSuccess) => {
  req.put({
    key: ITEM,
    url: `/api/masterAgent/agents/${agentId}/change_percentage`,
    payload,
    onSuccess: (res, state) => {
      toastSuccess("Updated!");
      setTimeout(() => {
        listAgent();
      }, 1000);
      selectAgent(agentId);
      if (onSuccess) onSuccess(transformList(res?.response?.data));
    },
  });
};
