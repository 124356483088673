import React, { useEffect, useState } from "react";
import Table from "partial/components/Table";
import Pagination from "partial/components/Pagination";
import { useGetWallet, useTransactionList } from "../hooks";
import { get } from "lodash";
import { RiWalletFill } from "react-icons/ri";

function TransactionContainer() {
  const [isLoading, list, pager] = useTransactionList();

  const [, walletData] = useGetWallet();

  const [arrayData, setFormArrayData] = useState(walletData.data || {});

  useEffect(() => {
    setFormArrayData(walletData.data);
  }, [walletData.data]);

  const tableFormat = React.useMemo(() => {
    return [
      {
        label: "Transaction",
        key: "type",
        id: "type",
        sortKey: "type",
      },
      {
        label: "Amount",
        key: "amount",
        id: "amount",
        sortKey: "amount",
      },
      {
        label: "Date",
        key: "date",
        id: "date",
        sortKey: "date",
      },
    ];
  }, []);
  return (
    <>
      <div className="flex items-center border px-4 py-3 bg-white shadow-sm">
        <div className="flex items-center space-x-2">
          <span className="bg-green-50 rounded-full p-3">
            <RiWalletFill className="w-6 h-6 flex-shrink-0 text-green-500" />
          </span>
          <div className="sm:flex-auto">
            <p className="text-sm text-gray-700">Wallet:</p>
            <p className="text-lg text-green-500 font-semibold flex items-center space-x-1">
              <span>{get(arrayData, "attributes.balance")}</span>
              <span className="text-xs text-gray-400">PHP</span>
            </p>
          </div>
        </div>
      </div>
      <div className="flex-grow flex flex-col">
        <div className="flex flex-col flex-grow bg-white">
          <Table
            data={list}
            format={tableFormat}
            isLoading={isLoading}
            // onSort={setFilter}
            // sort={filter.sort}
          />
        </div>
        <Pagination value={pager} />
      </div>
    </>
  );
}

export default TransactionContainer;
