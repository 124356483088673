import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Form from "partial/form/Form";
import FormInput from "partial/form/FormInput";
import FormSelect from "partial/form/FormSelect";
import Button from "partial/components/Button";
import { showDrawer } from "partial/components/Modal";
import { useCreateHandler, useUpdateHandler } from "../hooks";
import { VIDEO_OPTIONS } from "helper";
import { transformList } from "../transformers";
import { Editor } from "react-draft-wysiwyg";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import convertHtmlToDraft from "html-to-draftjs";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import _ from "lodash";
import * as c from "../constants";

export const openHandlerModal = (title, data, dataRoom, onSuccess) => {
  showDrawer({
    size: "lg",
    title,
    content: (onClose) => (
      <HandlerModal
        data={data}
        onClose={onClose}
        onSuccess={onSuccess}
        draftToHtml={draftToHtml}
        convertHtmlToDraft={convertHtmlToDraft}
        convertToRaw={convertToRaw}
        dataRoom={dataRoom}
      />
    ),
  });
};

function HandlerModal({
  data,
  onClose,
  onSuccess,
  draftToHtml,
  convertToRaw,
  dataRoom,
}) {
  const [form, setForm] = useState(data);
  const htmlToDraft = (value) => {
    const { contentBlocks, entityMap } = convertHtmlToDraft(value || "");

    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    const editorState = EditorState.createWithContent(contentState);

    return editorState;
  };

  const [formData, setFormData] = useState({
    race_description: htmlToDraft(data.race_description),
  });

  const handleEditorStateChange = (key) => (editorState) => {
    setFormData({ ...formData, [key]: editorState });
  };

  const [isCreate, create] = useCreateHandler();
  const [isUpdate, update] = useUpdateHandler();
  const handleSubmit = () => {
    const args = {
      ...form,
      state: false,
      race_description: draftToHtml(
        convertToRaw(formData.race_description.getCurrentContent())
      ),
    };
    if (data.id === "undefined") {
      create(args, dataRoom.id, (newData) => {
        onClose();
        onSuccess(newData);
      });
      return;
    }
    update(data?.id, dataRoom.id, args, onClose);
  };

  const onSetFormData = () => {
    if (data.id !== "undefined") {
      const args = {
        ...data,
        race_description: htmlToDraft(data.race_description),
      };
      setFormData(_.pick(args, _.keys(c.FORM_DATA)));
    }
  };

  useEffect(() => {
    if (data.id === "undefined") {
      const args = {
        ...c.FORM_DATA,
      };
      setFormData(args);
    }
    if (data.id !== "undefined") {
      onSetFormData();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Form className="divide-y divide-gray-200" onSubmit={handleSubmit}>
      <div className="pb-2">
        <div className="flex justify-end">
          <Button
            type="submit"
            primary
            size="lg"
            disabled={isCreate || isUpdate}
          >
            {data.id === "undefined" ? "Add" : "Save"}
          </Button>
        </div>
      </div>
      <div className="grid sm:grid-cols-3 grid-cols-1 gap-3">
        <div>
          <FormInput
            name="name"
            onChange={setForm}
            value={_.get(form, "name") || ""}
            label="Session Name"
            required
          />
        </div>
        <div>
          <FormInput
            type="number"
            name="number_of_rounds"
            onChange={setForm}
            value={_.get(form, "number_of_rounds") || ""}
            label="Number of Rounds"
            required
          />
        </div>
        <div>
          <FormSelect
            name="type_video"
            label="Type of Video"
            id="type_video"
            onChange={setForm}
            options={VIDEO_OPTIONS}
            value={_.get(form, "type_video") || ""}
            required
          />
        </div>
      </div>
      <div className="col-12 mt-2 mb-2">
        <FormInput
          name="url"
          onChange={setForm}
          value={_.get(form, "url") || ""}
          label="URL Video"
          required
        />
      </div>
      <div>
        <React.Fragment>
          <div className="border p-3 rounded-lg bg-gray-100">
            <div className="text-sm mb-3 font-bold">Racing Details</div>
            <div className="border p-3 rounded-lg bg-white">
              <Editor
                toolbar={{
                  options: ["inline", "textAlign"],
                }}
                editorState={
                  _.get(formData, "race_description") ||
                  EditorState.createEmpty()
                }
                onEditorStateChange={handleEditorStateChange(
                  "race_description"
                )}
              />
            </div>
          </div>
        </React.Fragment>
      </div>
    </Form>
  );
}

HandlerModal.defaultProps = {
  data: transformList(),
  onSuccess: false,
};

HandlerModal.propTypes = {
  data: PropTypes.instanceOf(Object),
  onSuccess: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onClose: PropTypes.func.isRequired,
  convertToRaw: PropTypes.instanceOf(Function).isRequired,
};

export default HandlerModal;
